import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";
import SwitchView from "@/components/SwitchView";
import StatsContainer from "@/components/StatsContainer";
import VLFModal from "@/components/Modal";
import VLFSelectNew from "@/components/Input/SelectNew";

import {PlayerAPI} from "@/api/player";

import AddIcon from "@mui/icons-material/Add";

import {
	dateFormat,
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
	REQUIRED_FIELD,
	tqrOptions,
} from "@/utils/constants";
import {
	checkRequiredFields,
	getCurrentCategory,
	getCurrentPlayer,
} from "@/utils/functions";
import {useCategories, usePlayers} from "@/utils/hooks";

import "./index.scss";

const fieldDateFormat = "YYYY-MM-DD";

const requiredFields = ["tqrValue"];

function TQRPage(props) {
	const {handleNotification} = props;
	const navigate = useNavigate();

	const playerId = useSelector((state) => state?.user?.playerId);
	const userRole = useSelector((state) => state?.user?.roleName);
	const isPlayer = userRole === "Player";

	const defaultFiltersData = {
		fromDate: moment()
			.startOf(isPlayer ? "month" : "day")
			.format(fieldDateFormat),
		toDate: moment()
			.endOf(isPlayer ? "month" : "day")
			.format(fieldDateFormat),
	};

	const [loading, setLoading] = useState(false);
	const [currentSwitchView, setCurrentSwitchView] = useState(
		isPlayer ? "list" : "stats",
	);

	const {players} = usePlayers({setLoading: false, handleNotification});
	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		formatted: false,
	});

	const [tqr, setTqr] = useState([]);
	const [filtersData, setFiltersData] = useState(
		JSON.parse(
			sessionStorage.getItem("tqr_filters" + isPlayer ? "_player" : ""),
		) || defaultFiltersData,
	);

	useEffect(() => {
		if (filtersData) {
			sessionStorage.setItem(
				"tqr_filters" + isPlayer ? "_player" : "",
				JSON.stringify(filtersData),
			);
		}
	}, [filtersData]);

	const filters = [
		{
			key: "fromDate",
			type: "date",
			label: "Da",
			customClassName: "col-6 col-sm-4",
			clearable: false,
			hidden: true,
			value: moment(filtersData?.fromDate).format(fieldDateFormat),
			setValue: (e) => {
				if (e) {
					const filters = {
						...filtersData,
						fromDate: moment(e).format(fieldDateFormat),
					};
					setFiltersData(filters);
				}
			},
		},
		{
			key: "toDate",
			type: "date",
			label: "A",
			customClassName: "col-6 col-sm-4",
			clearable: false,
			hidden: true,
			value: moment(filtersData?.toDate).format(fieldDateFormat),
			setValue: (e) => {
				if (e) {
					const filters = {
						...filtersData,
						toDate: moment(e).format(fieldDateFormat),
					};
					setFiltersData(filters);
				}
			},
		},
		{
			key: "categoryId",
			type: "select",
			options: categories.map((c) => {
				return {
					value: c.id,
					label: c.name,
				};
			}),
			sortOrder: "descend",
			label: "Categoria",
			customClassName: "col-12 col-sm-4",
			clearable: true,
			value: filtersData?.categoryId,
			setValue: (value) => {
				setFiltersData({...filtersData, categoryId: value});
			},
		},
	];

	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [openHandleEdit, setOpenHandleEdit] = useState(false);

	const [value, setValue] = useState();
	const [currentTQR, setCurrentTQR] = useState({});
	const [errors, setErrors] = useState([]);

	const getTQR = async () => {
		setLoading(true);
		try {
			const apiFilters = {
				...filtersData,
				categoryId: filtersData?.categoryId?.value,
			};
			const response = isPlayer
				? await PlayerAPI.playerListTQR(playerId, apiFilters)
				: await PlayerAPI.listTQR(apiFilters);
			setTqr(
				response.map((e) => {
					e.playerCategoryName = getCurrentCategory(categories, e);
					return e;
				}),
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const deleteTQR = async () => {
		setLoading(true);
		try {
			await PlayerAPI.deleteTQR(playerId, currentTQR?.id);
			getTQR();
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const updateTQR = async () => {
		setLoading(true);
		try {
			await PlayerAPI.updateTQR(
				isPlayer ? playerId : currentTQR?.playerId,
				currentTQR?.id,
				{
					...currentTQR,
					tqrValue: value?.value,
				},
			);
			getTQR();
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleEdit(false);
		}
	};

	useEffect(() => {
		if (categories?.length && currentSwitchView === "list") {
			getTQR();
		}
	}, [filtersData, categories, currentSwitchView]);

	const columns = [
		{
			label: "Data",
			key: "date",
			customRender: (item) => {
				return moment(item.tqrDate).format(dateFormat);
			},
			medium: true,
		},
	];

	if (!isPlayer) {
		columns.push(
			{
				label: "Categoria",
				key: "playerCategoryId",
				customRender: (item) =>
					item.playerCategoryName || getCurrentCategory(categories, item),
				small: true,
				align: "start",
			},
			{
				label: "Portiere",
				key: "playerId",
				customRender: (item) => getCurrentPlayer(players, item),
				align: "start",
			},
		);
	}

	columns.push({
		label: "TQR",
		key: "tqr",
		customRender: (item) => {
			return !item.emptyRow
				? tqrOptions.filter((o) => o.value === item.tqrValue)?.[0]?.label
				: "Nessun dato inserito";
		},
		align: "start",
	});

	const handleEdit = (item) => {
		setValue({
			value: item.tqrValue,
			label: tqrOptions.filter((o) => o.value === item.tqrValue)?.[0]?.label,
		});
		setCurrentTQR(item);
		setOpenHandleEdit(true);
	};

	const mainActions = [
		{
			icon: <AddIcon />,
			onClick: () => navigate("/measurations/tqr/new"),
		},
	];

	const moveActions = [
		{
			variant: "outlined",
			label: isPlayer ? "<< mese" : "<< giorno",
			onClick: () => {
				setFiltersData({
					...filtersData,
					fromDate: moment(filtersData?.fromDate)
						.subtract(1, isPlayer ? "months" : "days")
						.format(fieldDateFormat),
					toDate: moment(filtersData?.toDate)
						.subtract(1, isPlayer ? "months" : "days")
						.format(fieldDateFormat),
				});
			},
		},
		{
			variant: "outlined",
			label: isPlayer ? "mese >>" : "giorno >>",
			onClick: () => {
				setFiltersData({
					...filtersData,
					fromDate: moment(filtersData?.fromDate)
						.add(1, isPlayer ? "months" : "days")
						.format(fieldDateFormat),
					toDate: moment(filtersData?.toDate)
						.add(1, isPlayer ? "months" : "days")
						.format(fieldDateFormat),
				});
			},
		},
	];

	const notInsertedTqr = [];
	players
		.filter((p) => p.status === "Interno")
		.filter((p) => parseInt(p.category.split(" ")?.[1]) > 13)
		.filter(
			(p) =>
				!filtersData.categoryId ||
				(filtersData.categoryId && filtersData.categoryId.name === p.category),
		)
		.forEach((p, k) => {
			let found = false;
			tqr.forEach((t) => {
				if (t.playerId === p.id) {
					found = true;
				}
			});

			if (!found) {
				notInsertedTqr.push({
					id: "empty-" + k,
					playerId: p.id,
					tqrDate: moment(filtersData.fromDate).format("YYYY-MM-DD"),
					playerCategoryName: p.category,
					emptyRow: true,
				});
			}
		});

	const tqrData = isPlayer ? [...tqr] : [...tqr, ...notInsertedTqr];

	const groupedTQR = {};
	categories
		.filter(
			(c) =>
				c.name !== "Prima Squadra" && parseInt(c.name.split(" ")?.[1]) > 13,
		)
		.sort((a, b) => a.name?.localeCompare(b.name))
		.forEach((c) => {
			const currentCategory = c.name;
			const tqrItems = tqrData?.filter((t) => {
				return t.playerCategoryName === currentCategory;
			});
			groupedTQR[currentCategory] = tqrItems;
		});

	const listConfiguration = {
		data: isPlayer ? tqrData : groupedTQR,
		customClassName: (item) => (item.emptyRow ? "warning" : ""),
		handleEdit,
		handleDelete: (item) => {
			setCurrentTQR(item);
			setOpenHandleConfirm(true);
		},
	};

	return (
		<Page>
			<Loading visible={loading} />
			{!isPlayer && (
				<SwitchView
					currentSwitchView={currentSwitchView}
					setCurrentSwitchView={setCurrentSwitchView}
					pageOptions={[
						{value: "list", label: "Lista"},
						{value: "stats", label: "Statistiche"},
					]}
				/>
			)}
			<Breadcrumbs
				actions={
					<MainActions
						actions={
							isPlayer
								? [...moveActions, ...mainActions]
								: currentSwitchView === "list"
									? moveActions
									: []
						}
					/>
				}
			/>
			{currentSwitchView === "list" ? (
				<CardsContainer
					tableComponent={
						<CardsTable
							filtersData={filters}
							configurationColumns={columns}
							data={tqrData}
							fullWidth
							rowActions
							rowClassName={(item) => (item.emptyRow ? "warning" : "")}
							handleDelete={(item) => {
								setCurrentTQR(item);
								setOpenHandleConfirm(true);
							}}
							handleEdit={handleEdit}
							defaultSortedField={isPlayer ? "tqrDate" : "playerCategoryName"}
							defaultSortedOrder="descend"
						/>
					}
					listComponent={
						<CardsList
							type="tqr"
							breadcrumbs
							grouped={!isPlayer}
							filtersData={filters}
							listConfiguration={listConfiguration}
							defaultSortedField="playerCategoryName"
							defaultSortedOrder="descend"
						/>
					}
				/>
			) : (
				<StatsContainer type="tqr" />
			)}
			{openHandleConfirm && (
				<VLFModal
					open={openHandleConfirm}
					title="Eliminazione"
					content={
						<div className="mt-2">
							Sei sicuro di voler eliminare questo elemento?
						</div>
					}
					confirm={deleteTQR}
					confirmLabel="Conferma"
					close={() => setOpenHandleConfirm(false)}
				/>
			)}
			{openHandleEdit && (
				<VLFModal
					open={openHandleEdit}
					title="Modifica valore"
					content={
						<div className="row">
							<div className={"col-12"}>
								<VLFSelectNew
									options={tqrOptions}
									value={value || ""}
									onChange={(value) => {
										setErrors([]);
										setValue(value);
									}}
									required
									label="TQR"
									sorted={false}
									helperText={
										errors.indexOf("tqrValue") !== -1 ? REQUIRED_FIELD : ""
									}
									error={errors.indexOf("tqrValue") !== -1}
								/>
							</div>
						</div>
					}
					confirm={() => {
						const errors = checkRequiredFields(requiredFields, {
							tqrValue: value,
						});
						setErrors(errors);

						if (!errors.length) {
							updateTQR();
						}
					}}
					confirmLabel="Conferma"
					close={() => setOpenHandleEdit(false)}
				/>
			)}
		</Page>
	);
}

export default TQRPage;
