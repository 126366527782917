import {createTheme} from "@mui/material/styles";

export const theme = createTheme({
	palette: {
		blue: {
			main: "#002160",
			dark: "#002160",
		},
		green: {
			main: "#05a553",
			dark: "#01a74f",
		},
		yellow: {
			main: "#c5cb00",
		},
	},
});
