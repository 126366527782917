import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import {MatchObservedAPI} from "@/api/match_observed";

import AddIcon from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";

import {ERROR_OPERATION, dateFormat} from "@/utils/constants";
import {fetchData} from "@/utils/functions";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

const dateTimeFormat = "YYYY-MM-DDTHH:mm:ss";

const defaultFiltersData = {
	// fromDate: moment().startOf("month").format(dateTimeFormat),
	toDate: moment().endOf("month").format(dateTimeFormat),
};

function ScoutMatchesPage(props) {
	const {handleNotification} = props;

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [matches, setMatches] = useState([]);
	const [filtersData, setFiltersData] = useState(
		// JSON.parse(sessionStorage.getItem("scout_matches_filters")) ||
		defaultFiltersData,
	);

	useEffect(() => {
		if (filtersData) {
			sessionStorage.setItem(
				"scout_matches_filters",
				JSON.stringify(filtersData),
			);
		}
	}, [filtersData]);

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		formatted: true,
	});

	const filters = [
		{
			key: "fromDate",
			type: "date",
			label: "Da",
			customClassName: "col-6 col-sm-3",
			clearable: true,
			value: filtersData?.fromDate
				? moment(filtersData?.fromDate).format("YYYY-MM-DD")
				: null,
			setValue: (e) => {
				if (e) {
					setFiltersData({
						...filtersData,
						fromDate: moment(e)
							.set({hour: 0, minute: 0, second: 0})
							.format(dateTimeFormat),
					});
				}
			},
		},
		{
			key: "toDate",
			type: "date",
			label: "A",
			customClassName: "col-6 col-sm-3",
			clearable: false,
			value: moment(filtersData?.toDate).format("YYYY-MM-DD"),
			setValue: (e) => {
				if (e) {
					setFiltersData({
						...filtersData,
						toDate: moment(e)
							.set({hour: 23, minute: 59, second: 59})
							.format(dateTimeFormat),
					});
				}
			},
		},
		{
			key: "categoryId",
			type: "select",
			options: categories,
			sortOrder: "descend",
			label: "Categoria",
			customClassName: "col-12 col-sm-3",
			clearable: true,
			value: filtersData?.categoryId,
			setValue: (value) => {
				setFiltersData({...filtersData, categoryId: value});
			},
		},
	];

	const getMatches = () => {
		fetchData(
			MatchObservedAPI.list,
			{
				...filtersData,
				categoryId: filtersData?.categoryId?.value,
			},
			() => setLoading(true),
			setMatches,
			() => handleNotification(ERROR_OPERATION, "error"),
			() => setLoading(false),
		);
	};

	useEffect(() => {
		if (categories?.length) {
			getMatches();
		}
	}, [filtersData, categories]);

	let fullFilteredMatches = Array.from(matches);

	const groupedMatches = {};
	categories
		.filter((c) => c.label !== "Prima Squadra")

		.sort((a, b) => a.label?.localeCompare(b.label))
		.forEach((c) => {
			const currentCategory = c.label;
			const matchesMembers = fullFilteredMatches?.filter(
				(t) => t.playersCategory === currentCategory,
			);
			groupedMatches[currentCategory] = matchesMembers;
		});

	const listConfiguration = {
		data: groupedMatches,
		onClick: (match) => navigate("/scout-matches/" + match.id),
	};

	const getGoalsValue = (value) => {
		if (value !== null && value !== undefined) {
			return value;
		}

		return "---";
	};

	const columns = [
		{
			label: "Data",
			key: "match.date",
			customRender: (item) => {
				return moment(item.startDate).format(dateFormat + " HH:mm");
			},
			medium: true,
		},
		{
			label: "Luogo",
			key: "fieldName",
			medium: true,
			align: "start",
		},
		{
			label: "Osservatori",
			key: "scoutNames",
			medium: true,
			align: "start",
		},
		{
			label: "Leva",
			key: "match.membershipCategory",
			customRender: (item) => {
				const categoryNumber = item.playersCategory?.split(" ")?.[1];
				return 2025 - categoryNumber;
			},
			small: true,
			align: "start",
		},
		{
			label: "Categoria",
			key: "match.membershipCategory",
			customRender: (item) => {
				return item.playersCategory;
			},
			small: true,
			align: "start",
		},
		{
			label: "Partita",
			key: "match.homeTeam",
			customRender: (item) => {
				return item.homeTeam + " - " + item.awayTeam;
			},
			align: "start",
		},
		{
			label: "Risultato",
			key: "match.homeTeamGoals",
			customRender: (item) => {
				return (item?.homeTeamGoals === null ||
					item?.homeTeamGoals === undefined) &&
					(item?.awayTeamGoals === null || item?.awayTeamGoals === undefined)
					? "---"
					: getGoalsValue(item?.homeTeamGoals) +
							" - " +
							getGoalsValue(item?.awayTeamGoals);
			},
			small: true,
			align: "start",
		},
	];

	const mainActions = [
		{
			icon: <ArrowBack color="primary" />,
			variant: "outlined",
			onClick: () => {
				setFiltersData({
					...filtersData,
					fromDate: moment(filtersData?.fromDate)
						.subtract(1, "month")
						.format(dateTimeFormat),
					toDate: moment(filtersData?.toDate)
						.subtract(1, "month")
						.format(dateTimeFormat),
				});
			},
		},
		{
			icon: <ArrowForward color="primary" />,
			variant: "outlined",
			onClick: () => {
				setFiltersData({
					...filtersData,
					fromDate: moment(filtersData?.fromDate)
						.add(1, "month")
						.format(dateTimeFormat),
					toDate: moment(filtersData?.toDate)
						.add(1, "month")
						.format(dateTimeFormat),
				});
			},
		},
	];

	mainActions.push({
		icon: <AddIcon />,
		onClick: () => navigate("/scout-matches/new"),
	});

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={mainActions} />} />
			<CardsContainer
				tableComponent={
					<CardsTable
						filtersData={filters}
						configurationColumns={columns}
						data={fullFilteredMatches}
						fullWidth
						handleClick={(item) => {
							navigate("/scout-matches/" + item.id);
						}}
						defaultSortedField="startDate"
						defaultSortedOrder="descend"
					/>
				}
				listComponent={
					<CardsList
						type="match_scout"
						breadcrumbs
						grouped
						filtersData={filters}
						listConfiguration={listConfiguration}
						defaultSortedField="startDate"
						defaultSortedOrder="descend"
					/>
				}
			/>
		</Page>
	);
}

export default ScoutMatchesPage;
