import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import {UserRoleAPI} from "@/api/user_role";

import AddIcon from "@mui/icons-material/Add";

import {ERROR_OPERATION} from "@/utils/constants";

import "./index.scss";

function UserRolesPage(props) {
	const {handleNotification} = props;
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [userRoles, setUserRoles] = useState([]);

	const getUserRoles = async () => {
		try {
			const response = await UserRoleAPI.list();
			setUserRoles(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getUserRoles();
	}, []);

	const listConfiguration = {
		data: userRoles,
		onClick: (item) => {
			navigate("/settings/user-roles/" + item.name);
		},
		title: (role) => role.name,
	};

	const columns = [{label: "Nome", key: "name", align: "start"}];

	const mainActions = [
		{
			icon: <AddIcon />,
			onClick: () => navigate("/settings/user-roles/new"),
			color: "info",
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={mainActions} />} />
			<CardsContainer
				tableComponent={
					<CardsTable
						breadcrumbs
						configurationColumns={columns}
						data={userRoles}
						fullWidth
						handleClick={(item) => {
							navigate("/settings/user-roles/" + item.name);
						}}
						defaultSortedField="name"
					/>
				}
				listComponent={
					<CardsList
						type="users"
						breadcrumbs
						listConfiguration={listConfiguration}
						defaultSortedField="name"
					/>
				}
			/>
		</Page>
	);
}

export default UserRolesPage;
