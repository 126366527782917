import api from "./configuration";
import apiUrl from "./url";

const USE_AUTH_INTERCEPTOR = true;

export const MatchAPI = {
	list: async (filtersData) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Matches",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params: filtersData,
		});

		return response.data;
	},
	listScout: async (filtersData) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Matches/scout",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params: filtersData,
		});

		return response.data;
	},
	create: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Matches",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	getById: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Matches/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	delete: async (id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Matches/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	update: async (id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Matches/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	upload: async (id, file) => {
		const formData = new FormData();
		formData.append("file", file);

		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Matches/" + id + "/upload",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data: formData,
			headers: {"Content-Type": "multipart/form-data"},
		});

		return response.data;
	},
	createEvaluation: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Matches/evaluations",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updateEvaluation: async (matchId, id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Matches/" + matchId + "/evaluations/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteEvaluation: async (id, evaluationId) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Matches/" + id + "/evaluations/" + evaluationId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createMeasurement: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Matches/rpe",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteMeasurement: async (id, evaluationId) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Matches/" + id + "/rpe/" + evaluationId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	updateMeasurement: async (matchId, id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Matches/" + matchId + "/rpe/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	createAttendance: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Matches/convocations",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteAttendance: async (id, attendanceId) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Matches/" + id + "/convocations/" + attendanceId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	updateAttendance: async (matchId, id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Matches/" + matchId + "/convocations/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	getNotes: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Matches/" + id + "/notes",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createNote: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Matches/notes",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updateNote: async (matchId, id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Matches/" + matchId + "/notes/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteNote: async (matchId, id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Matches/" + matchId + "/notes/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
};
