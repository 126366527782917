import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";

import {ProspectAPI} from "@/api/prospect";

import ArrowBack from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {
	checkRequiredFields,
	getTextField,
	getTextAreaField,
} from "@/utils/functions";
import {SUCCESSFUL_OPERATION, ERROR_OPERATION} from "@/utils/constants";

const HandleScoutClubPage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({notes: null});

	const getClub = async () => {
		setLoading(true);
		try {
			const response = await ProspectAPI.getByIdTeam(id);
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit) {
			getClub();
		}
	}, [edit]);

	const getRequiredFields = () => {
		return ["name"];
	};

	const saveClub = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);
		setErrors(errors);

		if (!errors.length) {
			let body = Object.assign({}, fields);

			body = {
				...fields,
				featured: false,
			};
			setLoading(true);
			try {
				edit
					? await ProspectAPI.updateTeam(id, body)
					: await ProspectAPI.createTeam(body);
				navigate("/scout-clubs");
				handleNotification(SUCCESSFUL_OPERATION, "success");
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveClub();
	};

	const goBack = () => {
		navigate("/scout-clubs");
	};

	const mainActions = [
		{icon: <ArrowBack />, title: "Indietro", onClick: goBack, color: "info"},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const defaultTextField = {
		values: fields,
		setValues: setFields,
		errors,
		setErrors,
	};

	const breadcrumbsElements = [{label: "Club", path: "/scout-clubs"}];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: fields ? fields.name : "",
				path: "/scout-clubs/" + id,
			},
		);
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<VLFCard
					title="Informazioni principali"
					content={
						<div className="row no-margin">
							<div className="col-12 col-md-6 padding-small">
								{getTextField({
									...defaultTextField,
									fieldKey: "name",
									label: "Nome",
									required: true,
								})}
							</div>
							<div className="col-12 col-md-6 padding-small">
								{getTextField({
									...defaultTextField,
									fieldKey: "region",
									label: "Regione",
								})}
							</div>
							<div className="col-12 col-md-6 padding-small">
								{getTextField({
									...defaultTextField,
									fieldKey: "district",
									label: "Provincia",
								})}
							</div>
							<div className="col-12 col-md-6 padding-small">
								{getTextField({
									...defaultTextField,
									fieldKey: "city",
									label: "Città",
								})}
							</div>
							<div className="col-12 col-md-6 padding-small">
								{getTextField({
									...defaultTextField,
									fieldKey: "address",
									label: "Indirizzo",
								})}
							</div>
							<div className="col-12 col-md-12 padding-small">
								{getTextAreaField({
									...defaultTextField,
									fieldKey: "notes",
									label: "Note",
									minRows: 3,
									maxRows: 3,
								})}
							</div>
						</div>
					}
				/>
			</div>
		</Page>
	);
};

export default HandleScoutClubPage;
