import React, {useEffect, useState} from "react";

import VLFModal from "@/components/Modal";
import VLFDatePicker from "@/components/Input/DatePicker";
import VLFSelectNew from "@/components/Input/SelectNew";

import {MovementTypeAPI} from "@/api/movement_type";
import {ProspectAPI} from "@/api/prospect";

import {REQUIRED_FIELD} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";

function HandleMovementModal(props) {
	const {open, initialData, confirm, close} = props;

	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState([]);

	const [clubs, setClubs] = useState([]);
	const getClubs = async () => {
		try {
			const response = await ProspectAPI.teamsList();
			setClubs(response);
		} catch (error) {
			console.log(error);
		}
	};

	const [movements, setMovements] = useState([]);
	const getMovements = async () => {
		try {
			const response = await MovementTypeAPI.list();
			setMovements(response);
		} catch (error) {
			console.log(error);
		}
	};

	const createClub = async (name) => {
		try {
			const response = await ProspectAPI.createTeam({name});
			setFormData({
				...formData,
				prospectTeam: {value: response.id, label: response.name},
			});
			getClubs();
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getMovements();
		getClubs();
	}, []);

	useEffect(() => {
		if (initialData) {
			initialData.movement = {
				value: initialData?.movementType?.code,
				label: initialData?.movementType?.description,
			};
			if (initialData.prospectTeam) {
				initialData.prospectMovementTeam = {
					value: initialData.prospectTeam.id,
					label: initialData.prospectTeam.name,
				};
			}
			setFormData(initialData);
		}
	}, [initialData]);

	useEffect(() => {
		if (!open) {
			setFormData({});
		}
	}, [open]);

	const currentMovement = movements.filter(
		(m) => m.code === formData?.movement?.value,
	)?.[0];
	const currentPropectTeam = clubs.filter(
		(c) => c.id === formData?.prospectMovementTeam?.value,
	)?.[0];

	const getRequiredFields = () => {
		const requiredFields = ["movementDate", "movement"];

		if (currentMovement?.updateMembershipDate) {
			requiredFields.push("membershipDate");
		}

		if (currentMovement?.defineAnotherTeam) {
			requiredFields.push("prospectMovementTeam");
		}

		return requiredFields;
	};

	return (
		<VLFModal
			maxWidth="md"
			open={open}
			title={initialData ? "Modifica informazione" : "Aggiungi informazione"}
			close={close}
			confirm={() => {
				const errors = checkRequiredFields(getRequiredFields(), formData);
				setErrors(errors);

				if (!errors.length) {
					confirm(
						{
							...formData,
							prospectTeam: currentPropectTeam,
							movement: currentMovement,
						},
						initialData?.id,
					);
				}
			}}
			content={
				<div className="row">
					<div className="col-12 col-sm-6">
						<VLFDatePicker
							label="Data"
							value={formData.movementDate}
							onChange={(value) => {
								setErrors([]);
								setFormData({...formData, movementDate: value});
							}}
							required
							format="DD-MM-YYYY"
							size="small"
							helperText={
								errors.indexOf("movementDate") !== -1 ? REQUIRED_FIELD : ""
							}
							error={errors.indexOf("movementDate") !== -1}
						/>
					</div>
					<div className="col-12 col-sm-6">
						<VLFSelectNew
							options={movements.map((m) => {
								return {
									value: m.code,
									label: m.description,
								};
							})}
							value={formData.movement}
							onChange={(newValue) => {
								setErrors([]);
								setFormData({...formData, movement: newValue});
							}}
							label="Tipo"
							required
							helperText={
								errors.indexOf("movement") !== -1 ? REQUIRED_FIELD : ""
							}
							error={errors.indexOf("movement") !== -1}
						/>
					</div>
					{currentMovement?.updateMembershipDate ? (
						<div className="col-12 col-sm-6">
							<VLFDatePicker
								label="Data tesseramento"
								value={formData.membershipDate}
								onChange={(value) => {
									setErrors([]);
									setFormData({...formData, membershipDate: value});
								}}
								required
								format="DD-MM-YYYY"
								size="small"
								helperText={
									errors.indexOf("membershipDate") !== -1 ? REQUIRED_FIELD : ""
								}
								error={errors.indexOf("membershipDate") !== -1}
							/>
						</div>
					) : null}
					{currentMovement?.defineAnotherTeam ? (
						<div className="col-12 col-sm-6">
							<VLFSelectNew
								options={clubs.map((c) => {
									return {
										value: c.id,
										label: c.name,
									};
								})}
								value={formData.prospectMovementTeam}
								onChange={(newValue) => {
									setErrors([]);
									setFormData({...formData, prospectMovementTeam: newValue});
								}}
								onCreateOption={createClub}
								label="Squadra"
								required
								helperText={
									errors.indexOf("prospectMovementTeam") !== -1
										? REQUIRED_FIELD
										: ""
								}
								error={errors.indexOf("prospectMovementTeam") !== -1}
							/>
						</div>
					) : null}
				</div>
			}
		/>
	);
}

export default HandleMovementModal;
