import React from "react";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";

import "./index.scss";

function VLFModal(props) {
	const {
		maxWidth = "sm",
		fullWidth = true,
		open,
		title,
		content,
		confirm,
		close,
		confirmLabel = "Salva",
		cancelLabel = "Annulla",
		disabled = false,
	} = props;

	return (
		<Dialog
			maxWidth={maxWidth}
			fullWidth={fullWidth}
			open={open}
			onClose={close}
		>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent className="dialog-container">{content}</DialogContent>
			<DialogActions>
				{close ? (
					<Button
						onClick={close}
						disabled={disabled}
						color="error"
						variant="outlined"
					>
						{cancelLabel}
					</Button>
				) : null}
				{confirm ? (
					<Button
						onClick={confirm}
						disabled={disabled}
						color="primary"
						variant="contained"
					>
						{confirmLabel}
					</Button>
				) : null}
			</DialogActions>
		</Dialog>
	);
}

export default VLFModal;
