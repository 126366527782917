import * as React from "react";
import moment from "moment";

import {dateFormat} from "@/utils/constants";

import "./index.scss";

export default function ScoutCoachCard(props) {
	const {element} = props;

	const name = element.firstName
		? element.firstName + " " + element.lastName
		: element.lastName;

	return (
		<div className="scout-coach-card-container">
			<div className="scout-coach-card-header">
				<div className="top-left">
					{moment(element.dateOfBirth)?.year() > 1900
						? moment(element.dateOfBirth).format(dateFormat)
						: "---"}
				</div>
				<div className="top-right">{element.playersCategory?.name}</div>
			</div>
			<div className="scout-coach-card-info">
				<div className="info-title">{name}</div>
				<div className="info-subtitle">{element.prospectTeam?.name}</div>
			</div>
		</div>
	);
}
