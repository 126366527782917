import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import {UserAPI} from "@/api/user";

import AddIcon from "@mui/icons-material/Add";

import {ERROR_OPERATION} from "@/utils/constants";

import "./index.scss";

function UsersPage(props) {
	const {handleNotification} = props;
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState([]);

	const getUsers = async () => {
		try {
			const response = await UserAPI.list();
			setUsers(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getUsers();
	}, []);

	const listConfiguration = {
		data: users,
		onClick: (item) => {
			navigate("/settings/users/" + item.userName);
		},
		title: (user) => user.userName,
		subtitle: (user) => user.roleName,
	};

	const columns = [
		{label: "Username", key: "userName", align: "start"},
		{label: "Ruolo", key: "roleName", medium: true, align: "start"},
		{
			label: "Approvato",
			key: "isApproved",
			customRender: (item) => {
				return (
					<div className={"cell" + (item.isApproved ? " on" : " off")}>
						{item.isApproved}
					</div>
				);
			},
			small: true,
		},
		{
			label: "Bloccato",
			key: "isLockedOut",
			customRender: (item) => {
				return item.isLockedOut && <div className="cell off" />;
			},
			small: true,
		},
	];

	const mainActions = [
		{
			icon: <AddIcon />,
			onClick: () => navigate("/settings/users/new"),
			color: "info",
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={mainActions} />} />
			<CardsContainer
				tableComponent={
					<CardsTable
						breadcrumbs
						configurationColumns={columns}
						data={users}
						fullWidth
						handleClick={(item) => {
							navigate("/settings/users/" + item.userName);
						}}
						defaultSortedField="userName"
					/>
				}
				listComponent={
					<CardsList
						type="users"
						breadcrumbs
						listConfiguration={listConfiguration}
						defaultSortedField="userName"
					/>
				}
			/>
		</Page>
	);
}

export default UsersPage;
