import React, {useEffect, useState} from "react";

import VLFModal from "@/components/Modal";
import VLFSelectNew from "@/components/Input/SelectNew";

import {ProspectAPI} from "@/api/prospect";
import {SeasonAPI} from "@/api/season";

import {ERROR_OPERATION, REQUIRED_FIELD} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";
import {useCategories} from "@/utils/hooks";

const requiredFields = ["careerSeason", "careerTeam", "careerCategory"];

function HandleCareerModal(props) {
	const {open, initialData, confirm, close, handleNotification} = props;

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		formatted: true,
	});

	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState([]);

	const [seasons, setSeasons] = useState([]);
	const getSeasons = async () => {
		try {
			const response = await SeasonAPI.allSeasons();
			setSeasons(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const [clubs, setClubs] = useState([]);
	const getClubs = async () => {
		try {
			const response = await ProspectAPI.teamsList();
			setClubs(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const createClub = async (name) => {
		try {
			const response = await ProspectAPI.createTeam({name});
			setFormData({
				...formData,
				careerTeam: {value: response.id, label: response.name},
			});
			getClubs();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	useEffect(() => {
		getSeasons();
		getClubs();
	}, []);

	useEffect(() => {
		if (initialData) {
			initialData.careerSeason = {
				value: initialData?.season?.id,
				label: initialData?.season?.name,
			};
			initialData.careerTeam = {
				value: initialData?.prospectTeam?.id,
				label: initialData?.prospectTeam?.name,
			};
			initialData.careerCategory = {
				value: initialData?.playerCategory?.id,
				label: initialData?.playerCategory?.name,
			};
			setFormData(initialData);
		}
	}, [initialData]);

	useEffect(() => {
		if (!open) {
			setFormData({});
		}
	}, [open]);

	return (
		<VLFModal
			maxWidth="md"
			open={open}
			title={initialData ? "Modifica informazione" : "Aggiungi informazione"}
			close={close}
			confirm={() => {
				const errors = checkRequiredFields(requiredFields, formData);
				setErrors(errors);

				if (!errors.length) {
					formData.dateEvent = seasons.filter(
						(s) => s.id === formData.careerSeason?.value,
					)?.[0]?.startDate;
					confirm(formData, initialData?.id);
				}
			}}
			content={
				<div className="row">
					<div className="col-12 col-sm-4">
						<VLFSelectNew
							options={seasons.map((s) => {
								return {
									value: s.id,
									label: s.name,
								};
							})}
							value={formData.careerSeason}
							sortOrder="descend"
							onChange={(newValue) => {
								setErrors([]);
								setFormData({...formData, careerSeason: newValue});
							}}
							label="Stagione"
							required
							helperText={
								errors.indexOf("careerSeason") !== -1 ? REQUIRED_FIELD : ""
							}
							error={errors.indexOf("careerSeason") !== -1}
						/>
					</div>
					<div className="col-12 col-sm-4">
						<VLFSelectNew
							options={clubs.map((c) => {
								return {
									value: c.id,
									label: c.name,
								};
							})}
							value={formData.careerTeam}
							onChange={(newValue) => {
								setErrors([]);
								setFormData({...formData, careerTeam: newValue});
							}}
							onCreateOption={createClub}
							label="Squadra"
							required
							helperText={
								errors.indexOf("careerTeam") !== -1 ? REQUIRED_FIELD : ""
							}
							error={errors.indexOf("careerTeam") !== -1}
						/>
					</div>
					<div className="col-12 col-sm-4">
						<VLFSelectNew
							options={categories}
							sortOrder="descend"
							value={formData.careerCategory}
							onChange={(newValue) => {
								setErrors([]);
								setFormData({...formData, careerCategory: newValue});
							}}
							label="Categoria"
							required
							helperText={
								errors.indexOf("careerCategory") !== -1 ? REQUIRED_FIELD : ""
							}
							error={errors.indexOf("careerCategory") !== -1}
						/>
					</div>
				</div>
			}
		/>
	);
}

export default HandleCareerModal;
