import React from "react";

import VLFModal from "@/components/Modal";

import "./index.scss";

function VLFConfirmModal(props) {
	const {
		open,
		title = "Eliminazione",
		content = "Sei sicuro di voler eliminare questo elemento?",
		confirm,
		close,
	} = props;

	return (
		<VLFModal
			open={open}
			title={title}
			content={<div className="mt-2">{content}</div>}
			confirm={confirm}
			close={close}
			confirmLabel="Conferma"
		/>
	);
}

export default VLFConfirmModal;
