import React, {useEffect, useState} from "react";
import moment from "moment";

import VLFModal from "@/components/Modal";
import CreateSelectEntity from "@/components/CreateSelectEntity";
import VLFDatePicker from "@/components/Input/DatePicker";
import VLFSelectNew from "@/components/Input/SelectNew";

import {ProspectAPI} from "@/api/prospect";

import {dateFormat} from "@/utils/constants";
import {checkRequiredFields, getTextField} from "@/utils/functions";

function HandleProspectModal(props) {
	const {open, initialData, confirm, close} = props;

	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState([]);

	const [saveOption, setSaveOption] = useState(initialData ? "select" : "new");

	const [prospectPlayers, setProspectPlayers] = useState([]);
	const getProspectPlayers = async () => {
		try {
			const response = await ProspectAPI.playersList();
			setProspectPlayers(response);
		} catch (error) {
			console.log(error);
		}
	};

	const createProspectPlayer = async () => {
		try {
			const body = {
				firstName: formData?.firstName ? formData.firstName : "",
				lastName: formData?.lastName,
				dateOfBirth: formData?.dateOfBirth
					? moment(formData?.dateOfBirth).format("YYYY-MM-DD")
					: undefined,
			};

			const response = await ProspectAPI.createPlayer(body);
			return response;
		} catch (error) {
			console.log(error);
		}
	};

	const getRequiredFields = () => {
		if (saveOption === "select") {
			return ["prospectPlayer"];
		} else {
			return ["lastName"];
		}
	};

	useEffect(() => {
		getProspectPlayers();
	}, []);

	useEffect(() => {
		if (!open) {
			setFormData({});
		}
	}, [open]);

	const defaultTextField = {
		values: formData,
		setValues: setFormData,
		errors,
		setErrors,
	};

	return (
		<VLFModal
			maxWidth="md"
			open={open}
			title={initialData ? "Modifica informazione" : "Aggiungi informazione"}
			close={close}
			confirm={async () => {
				const errors = checkRequiredFields(getRequiredFields(), formData);
				setErrors(errors);

				if (!errors.length) {
					let playerData = {};
					if (saveOption === "new") {
						const playerResponse = await createProspectPlayer();
						playerData = {prospectPlayerId: playerResponse?.id};
					} else {
						playerData = {prospectPlayerId: formData?.prospectPlayer?.value};
					}
					confirm({...playerData}, initialData?.id);
				}
			}}
			content={
				<div className="row">
					<div className="col-12">
						<CreateSelectEntity
							currentOption={saveOption}
							setCurrentOption={setSaveOption}
						/>
					</div>
					{saveOption === "new" ? (
						<>
							<div className="col-12 col-sm-6 col-xl-4">
								{getTextField({
									...defaultTextField,
									fieldKey: "firstName",
									label: "Nome",
								})}
							</div>
							<div className="col-12 col-sm-6 col-xl-4">
								{getTextField({
									...defaultTextField,
									fieldKey: "lastName",
									label: "Cognome",
									required: true,
								})}
							</div>
							<div className="col-12 col-sm-6 col-xl-4">
								<VLFDatePicker
									label="Data di nascita"
									value={formData.dateOfBirth}
									onChange={(value) => {
										setFormData({...formData, dateOfBirth: value});
									}}
									format="DD-MM-YYYY"
								/>
							</div>
						</>
					) : (
						<div className="col-12 col-sm-6">
							<VLFSelectNew
								options={prospectPlayers.map((pp) => {
									return {
										value: pp.id,
										label:
											pp.firstName +
											" " +
											pp.lastName +
											" (" +
											(pp.dateOfBirth
												? moment(pp.dateOfBirth).format(dateFormat)
												: "---") +
											")",
									};
								})}
								value={formData?.prospectPlayer}
								onChange={(newValue) => {
									setFormData({...formData, prospectPlayer: newValue});
								}}
								label="Portiere"
								required
							/>
						</div>
					)}
				</div>
			}
		/>
	);
}

export default HandleProspectModal;
