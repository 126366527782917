import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import CreateSelectEntity from "@/components/CreateSelectEntity";
import VLFDatePicker from "@/components/Input/DatePicker";
import Rating from "@/components/Rating";
import VLFSelectNew from "@/components/Input/SelectNew";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {ProspectAPI} from "@/api/prospect";
import {ValuationAPI} from "@/api/valuation";

import {
	REQUIRED_FIELD,
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
	dateFormat,
	initialValuationParameters,
	initialBaseValuationParameters,
	proExternMatchParameters,
	baseExternMatchParameters,
	proExternTrainingParameters,
	baseExternTrainingParameters,
	valuationsTypes,
	externalValuationOptions,
} from "@/utils/constants";
import {
	checkRequiredFields,
	getTextField,
	getTextAreaField,
} from "@/utils/functions";
import {useCategories, usePlayers} from "@/utils/hooks";

import "./index.scss";

const HandleValuationPage = (props) => {
	const {edit, external = false, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const playerId = new URLSearchParams(useLocation().search).get("playerId");

	// Portieri interni
	const {players} = usePlayers({
		setLoading: false,
		handleNotification: undefined,
	});
	const {categories} = useCategories({
		setLoading: false,
		handleNotification: null,
		formatted: true,
	});

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({});
	const [saveOption, setSaveOption] = useState(edit ? "select" : "new");

	const [valuationParameters, setValuationParameters] = useState([]);

	const [prospectPlayers, setProspectPlayers] = useState([]);
	const getProspectPlayers = async () => {
		try {
			const response = await ProspectAPI.playersList();
			setProspectPlayers(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const createProspectPlayer = async () => {
		try {
			const body = {
				firstName: fields?.firstName ? fields.firstName : "",
				lastName: fields?.lastName,
				dateOfBirth: fields?.dateOfBirth
					? moment(fields.dateOfBirth).format("YYYY-MM-DD")
					: undefined,
				favoriteHand: fields?.favoriteHand,
				favoriteFoot: fields?.favoriteFoot,
			};

			const response = await ProspectAPI.createPlayer(body);
			return response;
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const [prospectTeams, setProspectTeams] = useState([]);
	const getProspectTeams = async () => {
		try {
			const response = await ProspectAPI.teamsList();
			setProspectTeams(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const createProspectTeam = async (name) => {
		try {
			const response = await ProspectAPI.createTeam({name});
			setFields({
				...fields,
				prospectTeam: {value: response.id, label: response.name},
			});
			getProspectTeams();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	useEffect(() => {
		if (external) {
			getProspectPlayers();
			getProspectTeams();
		}
	}, [external]);

	const getValuation = async () => {
		setLoading(true);
		try {
			const response = await ValuationAPI.getById(id);

			if (external) {
				response.valuationType =
					response.valuationType === 2 || response.valuationType === 4
						? valuationsTypes.filter((t) => t.value === "match")?.[0]
						: valuationsTypes.filter((t) => t.value === "training")?.[0];

				response.prospectTeam = {
					value: response.prospectTeam?.id,
					label: response.prospectTeam?.name,
				};

				response.prospectCategory = {
					value: response.playerCategory?.id,
					label: response.playerCategory?.name,
				};

				response.formProspectPlayer = {
					value: response.prospectPlayer?.id,
					label:
						(response.prospectPlayer?.firstName || "") +
						" " +
						response.prospectPlayer?.lastName +
						(response.prospectPlayer?.dateOfBirth
							? " (" +
								moment(response.prospectPlayer?.dateOfBirth).format(
									dateFormat,
								) +
								")"
							: " (---)"),
				};

				response.rating =
					response.finalOvarallRating !== null &&
					response.finalOvarallRating !== undefined
						? externalValuationOptions.filter(
								(v) => v.value === response.finalOvarallRating.toString(),
							)
						: undefined;

				setFields(response);
			} else {
				setFields({
					...response,
					playerName: response?.player
						? response?.player?.firstName +
							" " +
							response?.player?.lastName +
							" (" +
							response?.player?.category +
							")"
						: "---",
					playerCategoryName: response?.player?.category,
				});
			}
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!external && playerId) {
			if (edit && players?.length) {
				getValuation();
			} else {
				if (playerId) {
					const currentPlayer = players.filter((p) => p.id === playerId)?.[0];
					setFields({
						...fields,
						playerName: currentPlayer
							? currentPlayer?.firstName +
								" " +
								currentPlayer?.lastName +
								" (" +
								currentPlayer?.category +
								")"
							: "---",
						playerCategoryName: currentPlayer?.category,
					});
				}
			}
		}
	}, [external, edit, players, playerId]);

	useEffect(() => {
		if (edit && external) {
			if (
				prospectPlayers?.length &&
				prospectTeams?.length &&
				categories?.length
			) {
				getValuation();
			}
		}
	}, [external, edit, prospectPlayers, prospectTeams, categories]);

	const getRequiredFields = () => {
		const reqFields = ["valuationDate", "valuationAuthor", "valuationText"];
		if (external) {
			if (saveOption === "new") {
				reqFields.push(
					"valuationType",
					"prospectTeam",
					"prospectCategory",
					"lastName",
				);
			} else {
				reqFields.push(
					"valuationType",
					"prospectTeam",
					"prospectCategory",
					"formProspectPlayer",
				);
			}
		}
		return reqFields;
	};

	const saveValuation = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);
		setErrors(errors);

		if (!errors.length) {
			setLoading(true);

			const body = Object.assign({}, fields);

			if (external) {
				if (saveOption === "new") {
					const playerResponse = await createProspectPlayer();
					body.prospectPlayerId = playerResponse?.id;
				} else {
					body.prospectPlayerId = body.formProspectPlayer?.value;
				}

				body.prospectTeamId = body.prospectTeam?.value;
				body.prospectTeam = {
					id: body.prospectTeam?.value,
					name: body.prospectTeam?.label,
				};
				body.playerCategoryId = body.prospectCategory?.value;
				body.finalOvarallRating = body.rating?.value;

				const isCategoryPro =
					getCategoryNumber(fields?.prospectCategory?.label) > 12;
				body.valuationType =
					body.valuationType?.value === "match"
						? isCategoryPro
							? 2
							: 4
						: isCategoryPro
							? 3
							: 5;
			} else {
				body.playerId = playerId;
				body.valuationType = 1;
			}

			body.valuationDate = moment(body.valuationDate).format("YYYY-MM-DD");

			try {
				const valuationData = edit
					? await ValuationAPI.update(id, body)
					: await ValuationAPI.create(body);
				setTimeout(() => {
					const currentId = id || valuationData?.id;
					navigate(
						external
							? "/scout/valuations/" + currentId
							: "/players-valuations/" + currentId + "?playerId=" + playerId,
					);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		saveValuation();
	};

	const goBack = () => {
		navigate(-1);
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [
		{
			label: "Valutazioni",
			path: external ? "/scout/valuations" : "/players/" + playerId,
		},
	];

	const playerLabel = external
		? fields?.prospectPlayer?.firstName
			? fields?.prospectPlayer?.firstName +
				" " +
				fields?.prospectPlayer?.lastName
			: fields?.prospectPlayer?.lastName
		: fields?.playerName;

	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label:
					moment(fields?.valuationDate).format(dateFormat) + " " + playerLabel,
				path: external
					? "/scout/valuations/" + id
					: "/players-valuations/" + id + "?playerId=" + playerId,
			},
		);
	}

	const defaultTextField = {
		values: fields,
		setValues: setFields,
		errors,
		setErrors,
	};

	const getFinalAvg = () => {
		let totalRatings = 0,
			totalRatingsInserted = 0;

		valuationParameters.forEach((i) => {
			const currentValue = fields?.[i.fieldKeyRating];
			if (currentValue && currentValue > 0) {
				totalRatings += currentValue;
				totalRatingsInserted++;
			}
		});

		return !totalRatingsInserted
			? 0
			: +parseFloat(totalRatings / totalRatingsInserted).toFixed(2);
	};

	const getCategoryNumber = (category) => {
		return parseInt(category?.split(" ")?.[1]);
	};

	const getParameters = () => {
		if (external) {
			setValuationParameters(
				!fields?.prospectCategory?.label || !fields?.valuationType
					? []
					: getCategoryNumber(fields?.prospectCategory?.label) > 12
						? fields?.valuationType?.value === "match"
							? proExternMatchParameters
							: proExternTrainingParameters
						: fields?.valuationType?.value === "match"
							? baseExternMatchParameters
							: baseExternTrainingParameters,
			);
		} else {
			setValuationParameters(
				!fields?.playerCategoryName
					? []
					: getCategoryNumber(fields?.playerCategoryName) > 12
						? initialValuationParameters
						: initialBaseValuationParameters,
			);
		}
	};

	useEffect(() => {
		getParameters();
	}, [
		fields?.playerCategoryName,
		fields?.valuationType,
		fields?.prospectCategory,
	]);

	const selectPlayers = external
		? prospectPlayers.map((pp) => {
				return {
					value: pp.id,
					label:
						pp.firstName +
						" " +
						pp.lastName +
						" (" +
						(pp.dateOfBirth
							? moment(pp.dateOfBirth).format(dateFormat)
							: "---") +
						")",
				};
			})
		: players.map((p) => {
				return {
					value: p.id,
					label: p.firstName + " " + p.lastName + " (" + p.category + ")",
				};
			});

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni valutazione"
							content={
								<>
									<div className="row no-margin">
										<div className="col-12 col-lg-10">
											<div className="row">
												<div className="col-12 col-md-6 col-xl-4">
													<VLFDatePicker
														label="Data"
														value={fields.valuationDate}
														onChange={(value) => {
															setErrors([]);
															setFields({...fields, valuationDate: value});
														}}
														required
														format="DD-MM-YYYY"
														size="small"
														helperText={
															errors.indexOf("valuationDate") !== -1
																? REQUIRED_FIELD
																: ""
														}
														error={errors.indexOf("valuationDate") !== -1}
													/>
												</div>
												<div className="col-12 col-md-6 col-xl-4">
													{getTextField({
														...defaultTextField,
														fieldKey: "valuationAuthor",
														label: "Allenatore",
														required: true,
													})}
												</div>
												{!external ? (
													<div className="col-12 col-md-6 col-xl-4">
														<VLFSelectNew
															options={selectPlayers}
															value={fields?.playerName}
															onChange={(value) => {
																setErrors([]);
																setFields({...fields, playerName: value});
															}}
															required
															helperText={
																errors.indexOf("playerName") !== -1
																	? REQUIRED_FIELD
																	: ""
															}
															error={errors.indexOf("playerName") !== -1}
															editable={false}
															label={"Portiere"}
														/>
													</div>
												) : null}
												{external ? (
													<>
														<div className="col-12 col-md-6 col-xl-4">
															<VLFSelectNew
																options={valuationsTypes}
																value={fields.valuationType}
																onChange={(newValue) => {
																	setErrors([]);
																	setFields({
																		...fields,
																		valuationType: newValue,
																	});
																}}
																label="Tipologia"
																required
																helperText={
																	errors.indexOf("valuationType") !== -1
																		? REQUIRED_FIELD
																		: ""
																}
																error={errors.indexOf("valuationType") !== -1}
															/>
														</div>
														<div className="col-12 col-md-6 col-xl-4">
															<VLFSelectNew
																options={prospectTeams.map((c) => {
																	return {
																		value: c.id,
																		label: c.name,
																	};
																})}
																value={fields.prospectTeam}
																onChange={(newValue) => {
																	setErrors([]);
																	setFields({
																		...fields,
																		prospectTeam: newValue,
																	});
																}}
																onCreateOption={createProspectTeam}
																label="Squadra"
																required
																helperText={
																	errors.indexOf("prospectTeam") !== -1
																		? REQUIRED_FIELD
																		: ""
																}
																error={errors.indexOf("prospectTeam") !== -1}
															/>
														</div>
														<div className="col-12 col-md-6 col-xl-4">
															<VLFSelectNew
																options={categories}
																sortOrder="descend"
																value={fields.prospectCategory}
																onChange={(newValue) => {
																	setErrors([]);
																	setFields({
																		...fields,
																		prospectCategory: newValue,
																	});
																}}
																label="Categoria"
																required
																helperText={
																	errors.indexOf("prospectCategory") !== -1
																		? REQUIRED_FIELD
																		: ""
																}
																error={
																	errors.indexOf("prospectCategory") !== -1
																}
															/>
														</div>
													</>
												) : null}
												{!external ? (
													<>
														<div className="col-12 col-sm-6 col-xl-4">
															{getTextField({
																...defaultTextField,
																type: "number",
																fieldKey: "height",
																label: "Altezza",
															})}
														</div>
														<div className="col-12 col-sm-6 col-xl-4">
															{getTextField({
																...defaultTextField,
																type: "number",
																fieldKey: "weight",
																label: "Peso",
															})}
														</div>
														<div className="col-12 col-md-6 col-xl-4">
															{getTextField({
																...defaultTextField,
																fieldKey: "timeInsideClub",
																label: "Nel club dal",
															})}
														</div>
													</>
												) : (
													<div className="col-sm-12 col-md-6 col-xl-4">
														{getTextField({
															...defaultTextField,
															fieldKey: "trialPeriod",
															label: "Periodo di prova",
														})}
													</div>
												)}
											</div>
										</div>
										<div className="col-12 col-lg-2">
											<div
												className="d-flex justify-content-center h-100 flex-column"
												style={{borderLeft: "1px solid #ebf1fd"}}
											>
												<div className="field-label-container justify-content-center">
													<div className="field-label">Media finale</div>
												</div>
												<div className="stat-circle-container">
													<div className="stat-container">
														<div className="value">{getFinalAvg()}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
							}
						/>
					</div>
				</div>
				{external ? (
					<div className="row no-margin mt-2">
						<div className="col-12 padding-small">
							<VLFCard
								title="Informazioni portiere"
								content={
									<div className="row no-margin single-area-container">
										<div className="col-12 padding-small">
											<CreateSelectEntity
												currentOption={saveOption}
												setCurrentOption={setSaveOption}
											/>
										</div>
										{saveOption === "new" ? (
											<>
												<div className="col-12 col-sm-6 col-xl-3">
													{getTextField({
														...defaultTextField,
														fieldKey: "firstName",
														label: "Nome",
													})}
												</div>
												<div className="col-12 col-sm-6 col-xl-3">
													{getTextField({
														...defaultTextField,
														fieldKey: "lastName",
														label: "Cognome",
														required: true,
													})}
												</div>
												<div className="col-12 col-sm-6 col-xl-3">
													<VLFDatePicker
														label="Data di nascita"
														value={fields.dateOfBirth}
														onChange={(value) => {
															setFields({...fields, dateOfBirth: value});
														}}
														format="DD-MM-YYYY"
													/>
												</div>
												<div className="col-12 col-sm-6 col-xl-3">
													{getTextField({
														...defaultTextField,
														fieldKey: "favoriteHand",
														label: "Mano principale",
													})}
												</div>
												<div className="col-12 col-sm-6 col-xl-3">
													{getTextField({
														...defaultTextField,
														fieldKey: "favoriteFoot",
														label: "Piede principale",
													})}
												</div>
											</>
										) : (
											<div className="col-12 col-sm-6 col-xl-3">
												<VLFSelectNew
													options={selectPlayers}
													value={fields?.formProspectPlayer}
													onChange={(value) => {
														setErrors([]);
														setFields({...fields, formProspectPlayer: value});
													}}
													required
													helperText={
														errors.indexOf("formProspectPlayer") !== -1
															? REQUIRED_FIELD
															: ""
													}
													error={errors.indexOf("formProspectPlayer") !== -1}
													label={"Portiere"}
												/>
											</div>
										)}
										<div className="col-12 col-sm-6 col-xl-3">
											{getTextField({
												...defaultTextField,
												type: "number",
												fieldKey: "height",
												label: "Altezza",
											})}
										</div>
										<div className="col-12 col-sm-6 col-xl-3">
											{getTextField({
												...defaultTextField,
												type: "number",
												fieldKey: "weight",
												label: "Peso",
											})}
										</div>
										<div className="col-12 col-sm-6 col-xl-3">
											<VLFSelectNew
												options={externalValuationOptions}
												value={fields.rating}
												sorted={false}
												onChange={(newValue) => {
													setFields({
														...fields,
														rating: newValue,
													});
												}}
												label="Esito"
											/>
										</div>
									</div>
								}
							/>
						</div>
					</div>
				) : null}
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Dettagli"
							content={
								<>
									<div className="row no-margin">
										{valuationParameters.map((el, k) => {
											return (
												<div key={k} className="col-sm-12 col-md-6 my-2">
													<div className="field-label-container">
														<div className="field-label">{el.label}</div>
													</div>
													<Rating
														selectedRating={fields[el.fieldKeyRating]}
														setSelectedRating={(value) => {
															setFields({
																...fields,
																[el.fieldKeyRating]: value,
															});
														}}
													/>
													{getTextAreaField({
														...defaultTextField,
														fieldKey: el.fieldKey,
														minRows: 3,
														maxRows: 3,
													})}
												</div>
											);
										})}
										{!external ? (
											<div className="col-sm-12 col-md-6 my-2">
												{getTextAreaField({
													...defaultTextField,
													fieldKey: "strengthAreas",
													label: "Punti di forza, aree migliorabili",
												})}
											</div>
										) : null}
										<div className="col-sm-12 col-md-6 my-2">
											{getTextAreaField({
												...defaultTextField,
												fieldKey: "finalOvarallValuation",
												label: "Valutazione complessiva finale",
											})}
										</div>
										{/* Esito */}
									</div>
									<div className="row no-margin">
										<div className="col-12">
											{getTextAreaField({
												...defaultTextField,
												fieldKey: "valuationText",
												label: external
													? "Relazione generale"
													: "Valutazione iniziale",
												required: true,
												minRows: 10,
												maxRows: 10,
											})}
										</div>
									</div>
								</>
							}
						/>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default HandleValuationPage;
