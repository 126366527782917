import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import AddIcon from "@mui/icons-material/Add";

import {ExerciseAPI} from "@/api/exercise";

import {ERROR_OPERATION} from "@/utils/constants";

function ExercisesPage(props) {
	const {handleNotification} = props;

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [exercises, setExercises] = useState([]);

	const getExercises = async () => {
		setLoading(true);
		try {
			const response = await ExerciseAPI.list();
			setExercises(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getExercises();
	}, []);

	const listConfiguration = {
		data: exercises,
		onClick: (item) => navigate("/exercises/" + item.id),
	};

	const columns = [
		{label: "Nome", key: "name", align: "start"},
		{
			label: "Tipologia",
			key: "exerciseType",
			align: "start",
		},
		{
			label: "Durata",
			key: "duration",
			align: "end",
			small: true,
			customRender: (item) => item.duration + "'",
		},
	];

	const headerActions = [
		{
			icon: <AddIcon />,
			title: "Aggiungi",
			onClick: () => navigate("/exercises/new"),
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={headerActions} />} />
			<CardsContainer
				tableComponent={
					<CardsTable
						breadcrumbs
						// fullWidth
						configurationColumns={columns}
						data={exercises}
						handleClick={(item) => navigate("/exercises/" + item.id)}
						defaultSortedField="name"
						defaultSortedOrder="ascend"
					/>
				}
				listComponent={
					<CardsList
						type="exercise"
						breadcrumbs
						listConfiguration={listConfiguration}
						defaultSortedField="name"
						defaultSortedOrder="ascend"
					/>
				}
			/>
		</Page>
	);
}

export default ExercisesPage;
