import * as React from "react";
import {Button, ButtonGroup, FormControl} from "@mui/material";

export default function VLFToggleButtonGroup(props) {
	const {
		name,
		value,
		onChange,
		options,
		label,
		required,
		size,
		rowDirection = true,
	} = props;

	const handleButtonClick = (selectedValue) => {
		const newValue = value === selectedValue ? -1 : selectedValue;
		onChange({target: {name, value: newValue}});
	};

	return (
		<FormControl>
			{label && (
				<div className="field-label-container">
					<div className="field-label">{label}</div>
					{required && <div className="field-required">*</div>}
				</div>
			)}
			<ButtonGroup
				orientation={rowDirection ? "horizontal" : "vertical"}
				size={size}
			>
				{options.map((option, key) => (
					<Button
						key={key}
						variant={value === option.value ? "contained" : "outlined"}
						sx={{
							backgroundColor:
								value === option.value
									? "#1976d2" // Colore blu se selezionato
									: "transparent", // Colore trasparente se non selezionato
							color: value === option.value ? "#fff" : "inherit", // Testo bianco se selezionato
							borderColor: value === option.value ? "#1976d2" : "inherit", // Bordo blu se selezionato
						}}
						onClick={() => handleButtonClick(option.value)}
					>
						{option.label}
					</Button>
				))}
			</ButtonGroup>
		</FormControl>
	);
}
