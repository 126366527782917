import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";

import {UserRoleAPI} from "@/api/user_role";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {SUCCESSFUL_OPERATION, ERROR_OPERATION} from "@/utils/constants";
import {checkRequiredFields, getTextField} from "@/utils/functions";

const HandleUserRole = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState({});

	const getUserRole = async () => {
		setLoading(true);
		try {
			const response = await UserRoleAPI.getById(id);
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit) {
			getUserRole();
		}
	}, [edit]);

	const getRequiredFields = () => {
		return ["name"];
	};

	const saveUserRole = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);

		if (!errors.length) {
			setLoading(true);

			let body = Object.assign({}, fields);

			try {
				const response = edit
					? await UserRoleAPI.update(id, body)
					: await UserRoleAPI.create(body);
				setTimeout(() => {
					navigate(`/settings/user-roles/${response?.name || id}`);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveUserRole();
	};

	const goBack = () => {
		if (edit) {
			navigate("/settings/user-roles/" + id);
		} else {
			navigate("/settings/user-roles");
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [
		{label: "Ruoli utente", path: "/settings/user-roles"},
	];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: fields ? fields.name : "",
				path: "/settings/user-roles/" + id,
			},
		);
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="row no-margin">
				<div className="col-sm-12 padding-small">
					<VLFCard
						title="Informazioni principali"
						content={
							<div className="row">
								<div className="col-sm-12 col-md-6">
									{getTextField({
										fieldKey: "name",
										label: "Nome",
										required: true,
										values: fields,
										setValues: setFields,
									})}
								</div>
							</div>
						}
						openable={false}
					/>
				</div>
			</div>
		</Page>
	);
};

export default HandleUserRole;
