import * as React from "react";

import "./index.scss";

export default function ExerciseCard(props) {
	const {element} = props;

	return (
		<div className="exercise-card-container">
			<div className="exercise-card-header">
				<div className="top-left"></div>
				<div className="top-right">{element.exerciseType || "---"}</div>
			</div>
			<div className="d-flex align-items-center">
				<img src={require("@/assets/images/manager/exercise.png")} alt="" />
				<div className="exercise-card-info">
					<div className="info-title">{element.name}</div>
					<div className="info-subtitle">
						{element.duration ? element.duration + "'" : "---"}
					</div>
				</div>
			</div>
		</div>
	);
}
