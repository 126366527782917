import api from "./configuration";
import apiUrl from "./url";

const USE_AUTH_INTERCEPTOR = true;

export const AttachmentAPI = {
	list: async (type, id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Attachments/" + type + "/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getById: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Attachments/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			responseType: "blob",
		});

		return response.data;
	},
	getByTypeId: async (type, id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Attachments/" + type + "/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	delete: async (id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Attachments/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	upload: async (type, id, file, fileName) => {
		const formData = new FormData();
		formData.append("file", file);

		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Attachments/" + type + "/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data: formData,
			headers: {"Content-Type": "multipart/form-data"},
			fileName,
		});

		return response.data;
	},
};
