import * as React from "react";
import moment from "moment";

import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CheckIcon from "@mui/icons-material/Check";

import {dateFormat} from "@/utils/constants";
import {getCurrentCategory, getCurrentPlayer} from "@/utils/functions";
import {useCategories, usePlayers} from "@/utils/hooks";

import "./index.scss";

export default function TargetCard(props) {
	const {element} = props;

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
	});
	const {players} = usePlayers({
		setLoading: false,
		handleNotification: undefined,
	});

	return (
		<div className="target-card-container">
			<div className="target-card-header">
				<div className="top-left">
					{moment(element.targetDate).format(dateFormat)}
				</div>
				<div className="top-right">
					{getCurrentCategory(categories, element)}
				</div>
			</div>
			<div className="target-card-info">
				<div className="info-title">
					<div className="target-info">
						{getCurrentPlayer(players, element)}
					</div>
				</div>
				<div className="info-subtitle">
					<div className={"cell" + (element.reached ? " on" : " off")}></div>
				</div>
			</div>
			<div className="separator"></div>
			<div className="target-card-preview">
				<div className="target-type"></div>
				{!element.reached && moment(element.targetDeadline).year() > 2000 && (
					<div className="target-stats">
						<div className="single-stat">
							<QueryBuilderIcon />
							<div>{moment(element.targetDeadline).format(dateFormat)}</div>
						</div>
					</div>
				)}
				{element.reached && (
					<div className="target-stats">
						<div className="single-stat">
							<CheckIcon />
							<div>{moment(element.dateReached).format(dateFormat)}</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
