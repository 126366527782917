import * as React from "react";
import moment from "moment";

import {dateFormat} from "@/utils/constants";

import "./index.scss";

export default function RPEStatCard(props) {
	const {element} = props;

	const isTotalElement = element.avgRpe;

	let tlStatusClassName = "";
	if (element.trainingLoad <= 300) {
		tlStatusClassName = "light";
	} else if (element.trainingLoad > 300 && element.trainingLoad <= 700) {
		tlStatusClassName = "medium";
	} else if (element.trainingLoad > 700) {
		tlStatusClassName = "strong";
	}

	return (
		<div
			className={"rpe-stat-card-container" + (isTotalElement ? " total" : "")}
		>
			<div className="rpe-stat-card-header">
				{!isTotalElement && (
					<div className="top-left">
						{moment(element.trainingDate).format(dateFormat)}
					</div>
				)}
				<div className="top-right">
					{element.playerRpe || element.avgRpe
						? "Durata " + element.trainingsDuration + "'"
						: ""}
				</div>
			</div>
			<div className="separator"></div>
			<div className={"rpe-stat-card-info"}>
				<div className="info-title">
					<div className="rpe-stat-info">
						{isTotalElement
							? +parseFloat(element.avgRpe).toFixed(2)
							: element.playerRpe}
					</div>
					<div className="rpe-stat-sub-info">
						{isTotalElement ? "Media RPE" : "RPE"}
					</div>
				</div>
				{isTotalElement ? <div className="separator vertical"></div> : null}
				{isTotalElement ? (
					<div className="info-title">
						<div className="rpe-stat-info">{element.totalLoad}</div>
						<div className="rpe-stat-sub-info">{"Training load"}</div>
					</div>
				) : null}
				{!isTotalElement && (
					<div className={"info-subtitle " + tlStatusClassName}>
						{"Training load: " + element.trainingLoad}
					</div>
				)}
			</div>
		</div>
	);
}
