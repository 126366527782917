import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";
import SwitchView from "@/components/SwitchView";
import StatsContainer from "@/components/StatsContainer";
// import VLFModal from "@/components/Modal";
// import VLFSelectNew from "@/components/Input/SelectNew";

import {PlayerAPI} from "@/api/player";

// import AddIcon from "@mui/icons-material/Add";

import {
	dateFormat,
	// ERROR_OPERATION,
	// SUCCESSFUL_OPERATION,
	// REQUIRED_FIELD,
	rpeOptions,
} from "@/utils/constants";
import {
	// checkRequiredFields,
	getCurrentCategory,
	getCurrentPlayer,
} from "@/utils/functions";
import {useCategories, usePlayers} from "@/utils/hooks";

import "./index.scss";

const fieldDateFormat = "YYYY-MM-DD";

// const requiredFields = ["rpe"];

function RPEPage(props) {
	const {handleNotification} = props;

	const playerId = useSelector((state) => state?.user?.playerId);
	const userRole = useSelector((state) => state?.user?.roleName);
	const isPlayer = userRole === "Player";

	const [currentSwitchView, setCurrentSwitchView] = useState(
		isPlayer ? "list" : "stats",
	);

	const defaultFiltersData = {
		fromDate: moment()
			.startOf(isPlayer ? "month" : "day")
			.format(fieldDateFormat),
		toDate: moment()
			.endOf(isPlayer ? "month" : "day")
			.format(fieldDateFormat),
	};

	const [loading, setLoading] = useState(false);

	const {players} = usePlayers({setLoading, handleNotification});
	const {categories} = useCategories({setLoading, handleNotification});
	const formattedCategories = categories.map((c) => {
		return {
			value: c.id,
			label: c.name,
		};
	});

	const [rpe, setRpe] = useState([]);
	const [filtersData, setFiltersData] = useState(
		JSON.parse(
			sessionStorage.getItem("rpe_filters" + isPlayer ? "_player" : ""),
		) || defaultFiltersData,
	);

	useEffect(() => {
		if (filtersData) {
			sessionStorage.setItem(
				"rpe_filters" + isPlayer ? "_player" : "",
				JSON.stringify(filtersData),
			);
		}
	}, [filtersData]);

	const filters = [
		{
			key: "fromDate",
			type: "date",
			label: "Da",
			customClassName: "col-6 col-sm-4",
			clearable: false,
			hidden: true,
			value: moment(filtersData?.fromDate).format(fieldDateFormat),
			setValue: (e) => {
				if (e) {
					const filters = {
						...filtersData,
						fromDate: moment(e).format(fieldDateFormat),
					};
					setFiltersData(filters);
				}
			},
		},
		{
			key: "toDate",
			type: "date",
			label: "A",
			customClassName: "col-6 col-sm-4",
			clearable: false,
			hidden: true,
			value: moment(filtersData?.toDate).format(fieldDateFormat),
			setValue: (e) => {
				if (e) {
					const filters = {
						...filtersData,
						toDate: moment(e).format(fieldDateFormat),
					};
					setFiltersData(filters);
				}
			},
		},
		{
			key: "categoryId",
			type: "select",
			options: formattedCategories,
			label: "Categoria",
			sortOrder: "descend",
			customClassName: "col-12 col-sm-4",
			clearable: true,
			value: filtersData?.categoryId,
			setValue: (value) => {
				setFiltersData({...filtersData, categoryId: value});
			},
		},
	];

	// const [errors, setErrors] = useState([]);

	const getRPE = async () => {
		setLoading(true);
		try {
			const apiFilters = {
				...filtersData,
				categoryId: filtersData?.categoryId?.value,
				playerId,
			};
			const response = isPlayer
				? await PlayerAPI.listRPE(apiFilters)
				: await PlayerAPI.listRPE(apiFilters);
			setRpe(
				response.map((e) => {
					e.playerCategoryName = getCurrentCategory(categories, e);
					return e;
				}),
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (categories?.length && currentSwitchView === "list") {
			getRPE();
		}
	}, [filtersData, categories, currentSwitchView]);

	const columns = [
		{
			label: "Data",
			key: "date",
			customRender: (item) => {
				return moment(item.rpeDate).format(dateFormat);
			},
			medium: true,
		},
	];

	if (!isPlayer) {
		columns.push(
			{
				label: "Categoria",
				key: "playerCategoryId",
				customRender: (item) =>
					item.playerCategoryName || getCurrentCategory(categories, item),
				small: true,
				align: "start",
			},
			{
				label: "Portiere",
				key: "playerId",
				customRender: (item) => getCurrentPlayer(players, item),
				align: "start",
			},
		);
	}

	columns.push({
		label: "RPE",
		key: "rpe",
		customRender: (item) => {
			return !item.emptyRow
				? rpeOptions.filter((o) => o.value === item.rpe)?.[0]?.label
				: "Nessun dato inserito";
		},
		align: "start",
	});

	// const handleEdit = (item) => {
	// 	setValue({
	// 		value: item.tqrValue,
	// 		label: tqrOptions.filter((o) => o.value === item.tqrValue)?.[0]?.label,
	// 	});
	// 	setCurrentTQR(item);
	// 	setOpenHandleEdit(true);
	// };

	const mainActions = [
		// {
		// 	icon: <AddIcon />,
		// 	onClick: () => navigate("/measurations/tqr/new"),
		// },
	];

	const moveActions = [
		{
			variant: "outlined",
			label: isPlayer ? "<< mese" : "<< giorno",
			onClick: () => {
				setFiltersData({
					...filtersData,
					fromDate: moment(filtersData?.fromDate)
						.subtract(1, isPlayer ? "months" : "days")
						.format(fieldDateFormat),
					toDate: moment(filtersData?.toDate)
						.subtract(1, isPlayer ? "months" : "days")
						.format(fieldDateFormat),
				});
			},
		},
		{
			variant: "outlined",
			label: isPlayer ? "mese >>" : "giorno >>",
			onClick: () => {
				setFiltersData({
					...filtersData,
					fromDate: moment(filtersData?.fromDate)
						.add(1, isPlayer ? "months" : "days")
						.format(fieldDateFormat),
					toDate: moment(filtersData?.toDate)
						.add(1, isPlayer ? "months" : "days")
						.format(fieldDateFormat),
				});
			},
		},
	];

	const rpeData = Array.from(rpe);

	const groupedRPE = {};
	categories
		.filter(
			(c) =>
				c.name !== "Prima Squadra" && parseInt(c.name.split(" ")?.[1]) > 13,
		)
		.sort((a, b) => a.name?.localeCompare(b.name))
		.forEach((c) => {
			const currentCategory = c.name;
			const rpeItems = rpeData?.filter((t) => {
				return t.playerCategoryName === currentCategory;
			});
			groupedRPE[currentCategory] = rpeItems;
		});

	const listConfiguration = {
		data: isPlayer ? rpeData : groupedRPE,
	};

	return (
		<Page className="rpe-container">
			<Loading visible={loading} />
			{!isPlayer && (
				<SwitchView
					currentSwitchView={currentSwitchView}
					setCurrentSwitchView={setCurrentSwitchView}
					pageOptions={[
						{value: "list", label: "Lista"},
						{value: "stats", label: "Statistiche"},
					]}
				/>
			)}
			<Breadcrumbs
				actions={
					<MainActions
						actions={
							isPlayer
								? [...moveActions, ...mainActions]
								: currentSwitchView === "list"
									? moveActions
									: []
						}
					/>
				}
			/>
			{currentSwitchView === "list" ? (
				<CardsContainer
					tableComponent={
						<CardsTable
							filtersData={filters}
							configurationColumns={columns}
							data={rpeData}
							fullWidth
							// rowActions
							// rowClassName={(item) => (item.emptyRow ? "warning" : "")}
							// handleDelete={(item) => {
							// 	setCurrentTQR(item);
							// 	setOpenHandleConfirm(true);
							// }}
							// handleEdit={handleEdit}
							defaultSortedField="rpeDate"
							defaultSortedOrder="descend"
						/>
					}
					listComponent={
						<CardsList
							type="rpe"
							breadcrumbs
							grouped={!isPlayer}
							filtersData={filters}
							listConfiguration={listConfiguration}
							defaultSortedField="rpeDate"
							defaultSortedOrder="descend"
						/>
					}
				/>
			) : (
				<StatsContainer type="rpe" />
			)}
			{/* {openHandleConfirm && (
				<VLFModal
					open={openHandleConfirm}
					title="Eliminazione"
					content={
						<div className="mt-2">
							Sei sicuro di voler eliminare questo elemento?
						</div>
					}
					confirm={deleteRPE}
					confirmLabel="Conferma"
					close={() => setOpenHandleConfirm(false)}
				/>
			)} */}
			{/* {openHandleEdit && (
				<VLFModal
					open={openHandleEdit}
					title="Modifica valore"
					content={
						<div className="row">
							<div className={"col-12"}>
								<VLFSelectNew
									options={tqrOptions}
									value={value || ""}
									onChange={(value) => {
										setErrors([]);
										setValue(value);
									}}
									required
									label="TQR"
									sorted={false}
									helperText={
										errors.indexOf("tqrValue") !== -1 ? REQUIRED_FIELD : ""
									}
									error={errors.indexOf("tqrValue") !== -1}
								/>
							</div>
						</div>
					}
					confirm={() => {
						const errors = checkRequiredFields(requiredFields, {
							tqrValue: value,
						});
						setErrors(errors);

						if (!errors.length) {
							updateTQR();
						}
					}}
					confirmLabel="Conferma"
					close={() => setOpenHandleEdit(false)}
				/>
			)} */}
		</Page>
	);
}

export default RPEPage;
