import React, {useState} from "react";
import {Button} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";

import VLFTextField from "../TextField/index";

import "./index.scss";

function FileUpload(props) {
	const {selectedFile, setSelectedFile} = props;
	const [fileName, setFileName] = useState("");

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
		setFileName(file.name);
		resetFileInput(event);
	};

	const handleRemoveFile = () => {
		setSelectedFile(null);
		setFileName("");
	};

	const resetFileInput = (event) => {
		event.target.value = null;
	};

	const isImageFile = (file) => file && file.type.startsWith("image/");
	const isVideoFile = (file) =>
		file && (file.type === "video/mp4" || file.type === "video/quicktime");

	return (
		<>
			<div className="upload-container gap-2">
				{selectedFile ? (
					<div className="d-flex align-items-center my-1">
						{isImageFile(selectedFile) ? (
							<img
								src={URL.createObjectURL(selectedFile)}
								alt="Preview"
								style={{
									maxHeight: "150px",
									maxWidth: "150px",
									objectFit: "cover",
									marginRight: "10px",
								}}
							/>
						) : isVideoFile(selectedFile) ? (
							<video
								width="150"
								height="150"
								controls
								style={{marginRight: "10px"}}
							>
								<source
									src={URL.createObjectURL(selectedFile)}
									type={selectedFile.type}
								/>
								Il tuo browser non supporta la visualizzazione dei video.
							</video>
						) : (
							<span>{fileName}</span>
						)}
						<div className="clickable" onClick={handleRemoveFile}>
							<DeleteIcon />
						</div>
					</div>
				) : (
					<div className="d-flex align-items-center my-1">
						Nessun file selezionato
					</div>
				)}

				<div>
					<input
						type="file"
						accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.mov,.MOV,.mp4"
						onChange={handleFileChange}
						style={{display: "none"}}
						id="file-upload"
					/>
					<label htmlFor="file-upload">
						<Button
							variant="outlined"
							component="span"
							startIcon={<CloudUploadIcon />}
						>
							Seleziona file
						</Button>
					</label>
				</div>
			</div>

			{selectedFile && (
				<VLFTextField
					label="Nome file"
					value={fileName}
					onChange={(e) => {
						const newFileName = e.target.value;
						setFileName(newFileName);
						const updatedFile = new File([selectedFile], newFileName, {
							type: selectedFile.type,
						});

						setSelectedFile(updatedFile);
					}}
					size="small"
					required
				/>
			)}
		</>
	);
}

export default FileUpload;
