import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsList from "@/components/CardsList";

import {MedicalAPI} from "@/api/medical";

import AddIcon from "@mui/icons-material/Add";

import {ERROR_OPERATION} from "@/utils/constants";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

function MedicalRecordsPage(props) {
	const {handleNotification} = props;
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [records, setRecords] = useState([]);

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
	});

	const getRecords = async () => {
		setLoading(true);
		try {
			let response = await MedicalAPI.recordsList();
			response = response.map((r) => {
				r.category = r.playerData?.category;
				return r;
			});
			setRecords(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getRecords();
	}, []);

	const groupedRecords = {};
	categories
		.filter((c) => c.name !== "Prima Squadra")
		.sort((a, b) => a.name?.localeCompare(b.name))
		.forEach((c) => {
			const currentCategory = c.name;
			const members = records?.filter(
				(r) => r.playerData.category === currentCategory,
			);
			groupedRecords[currentCategory] = members;
		});

	const getListConfiguration = () => {
		return {
			data: groupedRecords,
			onClick: (record) => navigate("/medical/records/" + record.id),
			imageUrl: (item) => require("@/assets/images/medical/record.png"),
			title: (record) =>
				record?.playerData
					? record?.playerData?.firstName + " " + record?.playerData?.lastName
					: "---",
			subtitle: (record) =>
				record?.playerData
					? moment(record?.playerData.dateOfBirth).format("YYYY")
					: "---",
		};
	};

	const mainActions = [
		// {
		// 	icon: <AddIcon />,
		// 	title: "Aggiungi cartella medica",
		// 	onClick: () => navigate("/medical/records/new"),
		// },
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={mainActions} />} />
			<CardsList
				// breadcrumbs
				grouped
				type="players"
				listConfiguration={getListConfiguration()}
				defaultSortedField="category"
				defaultSortedOrder="descend"
			/>
		</Page>
	);
}

export default MedicalRecordsPage;
