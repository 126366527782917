import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFTextField from "@/components/Input/TextField";
import VLFDatePicker from "@/components/Input/DatePicker";
import VLFSelectNew from "@/components/Input/SelectNew";

import {AthleticTestAPI} from "@/api/athletic_test";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {
	dateFormat,
	REQUIRED_FIELD,
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
	testTypes,
} from "@/utils/constants";
import {checkRequiredFields, getCurrentPlayer} from "@/utils/functions";
import {useCategories, usePlayers} from "@/utils/hooks";

import "./index.scss";

const HandleAthleticTestPage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const playerId = new URLSearchParams(useLocation().search).get("playerId");

	const userId = useSelector((state) => state?.user?.userId);
	const clubId = useSelector((state) => state?.user?.clubId);
	const userName = useSelector((state) => state?.user?.userName);

	const {players} = usePlayers({
		setLoading: false,
		handleNotification: undefined,
	});
	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
	});

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({
		playerId: null,
		testType: null,
	});
	const [originalDetails, setOriginalDetails] = useState([]);

	const getTest = async () => {
		setLoading(true);
		try {
			const response = await AthleticTestAPI.getById(id);
			response.playerName = getCurrentPlayer(players, response, true);

			let newDetails = {};
			response.details
				?.sort((a, b) => {
					let numA = parseInt(a.valueType);
					let numB = parseInt(b.valueType);

					return numA - numB;
				})
				.forEach((d) => {
					newDetails[d.valueType] = d.value;
				});
			setFields({...response, details: newDetails});
			setOriginalDetails(response?.details);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (players?.length && playerId) {
			const currentPlayer = players.filter((p) => p.id === playerId)?.[0];
			setFields({
				...fields,
				playerName:
					currentPlayer?.firstName +
					" " +
					currentPlayer?.lastName +
					" (" +
					currentPlayer?.category +
					")",
			});
		}
	}, [players, playerId]);

	useEffect(() => {
		if (edit && players?.length) {
			getTest();
		}
	}, [edit, players]);

	const getRequiredFields = () => {
		return playerId
			? ["testDate", "testType"]
			: edit
				? ["testDate"]
				: ["testDate", "playerId", "testType"];
	};

	const saveTest = async () => {
		try {
			const errors = checkRequiredFields(getRequiredFields(), fields);
			setErrors(errors);

			if (!errors.length) {
				setLoading(true);

				let body = Object.assign(
					{},
					{
						...fields,
						playerId: playerId
							? playerId
							: edit
								? fields?.playerId
								: fields?.playerId.value,
						testDate: moment(fields.testDate).format("YYYY-MM-DDTHH:mm:ss"),
						testType: edit ? fields?.testType : fields?.testType.value,
						details: Object.keys(fields?.details)?.map((d) => {
							const id = originalDetails?.filter(
								(od) => od.valueType === d,
							)?.[0]?.id;
							return {
								id,
								valueType: d,
								value: fields.details[d],
							};
						}),
						clubId,
						userId,
						user: {userId, userName},
					},
				);

				const response = edit
					? await AthleticTestAPI.update(id, body)
					: await AthleticTestAPI.create(body);

				setTimeout(() => {
					navigate(`/athletic-tests/${response?.id || id}`);
					setLoading(false);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			}
		} catch (error) {
			setLoading(false);
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveTest();
	};

	const goBack = () => {
		navigate(-1);
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [
		{
			label: "Test atletici",
			path: "/athletic-tests",
		},
	];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: fields
					? moment(fields?.testDate).format(dateFormat) +
						" " +
						getCurrentPlayer(players, fields)
					: "",
				path: "/athletic-tests/" + id,
			},
		);
	}

	return (
		<Page className="handle-athletic-test-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div
										className={
											edit ? "col-sm-12 col-md-4" : "col-sm-12 col-md-6"
										}
									>
										<VLFDatePicker
											label="Data"
											value={fields.testDate}
											onChange={(value) => {
												setErrors([]);
												setFields({...fields, testDate: value});
											}}
											required
											helperText={
												errors.indexOf("testDate") !== -1 ? REQUIRED_FIELD : ""
											}
											error={errors.indexOf("testDate") !== -1}
											size="small"
										/>
									</div>
									<div
										className={
											edit ? "col-sm-12 col-md-4" : "col-sm-12 col-md-6"
										}
									>
										<VLFSelectNew
											options={players.map((p) => {
												return {
													value: p.id,
													label:
														p.firstName +
														" " +
														p.lastName +
														" (" +
														p.category +
														")",
												};
											})}
											value={
												edit || playerId ? fields.playerName : fields.playerId
											}
											onChange={(newValue) => {
												setErrors([]);
												setFields({...fields, playerId: newValue});
											}}
											label="Portiere"
											required
											helperText={
												errors.indexOf("playerId") !== -1 ? REQUIRED_FIELD : ""
											}
											error={errors.indexOf("playerId") !== -1}
											editable={!edit && !playerId}
										/>
									</div>
									<div
										className={
											edit ? "col-sm-12 col-md-4" : "col-sm-12 col-md-6"
										}
									>
										<VLFSelectNew
											options={testTypes}
											value={fields.testType}
											onChange={(newValue) => {
												setErrors([]);
												setFields({...fields, testType: newValue});
											}}
											label="Tipologia"
											required
											helperText={
												errors.indexOf("testType") !== -1 ? REQUIRED_FIELD : ""
											}
											error={errors.indexOf("testType") !== -1}
											editable={!edit}
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFTextField
											value={fields["description"] || ""}
											onChange={(e) => {
												setFields({...fields, description: e.target.value});
											}}
											label="Descrizione"
											size="small"
											multiline
											minRows={3}
											maxRows={3}
										/>
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Parametri"
							content={
								<>
									{!edit && (
										<div className="row">
											{fields?.testType?.params?.map((tp, k) => {
												return (
													<div key={k} className="col-sm-12 col-md-4">
														<VLFTextField
															value={fields["details"]?.[tp.value] || ""}
															onChange={(e) => {
																setFields({
																	...fields,
																	details: {
																		...fields?.details,
																		[tp.value]: e.target.value,
																	},
																});
															}}
															label={tp.label}
															size="small"
															type="number"
															end={tp.um}
														/>
													</div>
												);
											})}
											{!fields.testType && (
												<div className="d-flex justify-content-center mt-2">
													Seleziona una tipologia di test
												</div>
											)}
										</div>
									)}
									{edit && (
										<div className="row">
											{fields?.details &&
												Object.keys(fields?.details)?.map((d, k) => {
													return (
														<div key={k} className="col-sm-12 col-md-4">
															<VLFTextField
																value={fields["details"]?.[d] || ""}
																onChange={(e) => {
																	setFields({
																		...fields,
																		details: {
																			...fields?.details,
																			[d]: e.target.value,
																		},
																	});
																}}
																label={d}
																size="small"
																type="number"
																end={
																	testTypes
																		?.filter(
																			(t) => t.value === fields.testType,
																		)?.[0]
																		?.params?.filter((p) => p.label === d)?.[0]
																		?.um
																}
															/>
														</div>
													);
												})}
										</div>
									)}
								</>
							}
							openable={false}
						/>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default HandleAthleticTestPage;
