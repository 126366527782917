import * as React from "react";

import VLFToggleButtonGroup from "@/components/Input/TogleButtonGroup";

import "./index.scss";

export default function SwitchView(props) {
	const {
		currentSwitchView,
		setCurrentSwitchView,
		callback,
		pageOptions,
		fixed = false,
	} = props;

	return (
		<div className={"switch-view-container" + (fixed ? " fixed" : "")}>
			<VLFToggleButtonGroup
				name="switch-page-view"
				value={currentSwitchView}
				onChange={(event) => {
					if (event.target.value !== -1) {
						setCurrentSwitchView(event.target.value);
						if (callback) {
							callback();
						}
					}
				}}
				options={pageOptions}
				size="small"
				rowDirection
			/>
		</div>
	);
}
