import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFDatePicker from "@/components/Input/DatePicker";
import VLFSelectNew from "@/components/Input/SelectNew";

import {MedicalAPI} from "@/api/medical";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {
	medicalRecordTypes,
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
	REQUIRED_FIELD,
} from "@/utils/constants";
import {
	checkRequiredFields,
	getTextField,
	getTextAreaField,
} from "@/utils/functions";

const HandleMedicalInjuryPage = (props) => {
	const {handleNotification, edit} = props;

	const navigate = useNavigate();
	const {id, injuryId} = useParams();
	const location = useLocation();

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({});

	const getRequiredFields = () => {
		return ["startDate", "recordType", "diagnosis"];
	};

	const [recordData, setRecordData] = useState({});
	const getRecordById = async () => {
		try {
			const response = await MedicalAPI.recordGetById(id);
			setRecordData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	useEffect(() => {
		if (id) {
			getRecordById();
		}
	}, [id]);

	useEffect(() => {
		if (edit && location) {
			const newRecordType = {
				value: location.state.recordType === false ? "injury" : "illness",
				label: location.state.recordType === false ? "Infortunio" : "Malattia",
			};
			setFields({...location.state, recordType: newRecordType});
		}
	}, [edit, location]);

	const saveInjury = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);
		setErrors(errors);

		if (!errors.length) {
			setLoading(true);

			let body = Object.assign({}, fields);
			body.recordType = fields?.recordType?.value === "injury" ? 0 : 1;
			body.medicalRecordId = id;
			body.staffId = "88638819-8a5a-44ce-8a2e-0c293875a4fa";

			try {
				edit
					? await MedicalAPI.injuryUpdate(id, injuryId, body)
					: await MedicalAPI.injuryCreate(body);
				setTimeout(() => {
					navigate(`/medical/records/${id}`);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveInjury();
	};

	const goBack = () => {
		navigate(-1);
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [
		{label: "Passaporti medici", path: "/medical/records"},
		{label: "/"},
		{
			label: recordData
				? (recordData?.playerData?.firstName || "") +
					" " +
					(recordData?.playerData?.lastName || "")
				: "",
			path: "/medical/records/" + id,
		},
	];

	const defaultTextField = {
		values: fields,
		setValues: setFields,
		errors,
		setErrors,
	};

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-6">
										<VLFDatePicker
											type="date"
											label="Inizio"
											value={fields.startDate}
											onChange={(value) => {
												setFields({...fields, startDate: value});
											}}
											required
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFDatePicker
											type="date"
											label="Fine"
											value={fields.endDate}
											onChange={(value) => {
												setFields({...fields, endDate: value});
											}}
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={medicalRecordTypes}
											value={fields.recordType}
											onChange={(newValue) => {
												setFields({
													...fields,
													recordType: newValue,
												});
											}}
											required
											label="Tipologia"
										/>
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
				<div className="row no-margin mt-3">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Dettagli"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-6">
										{getTextField({
											...defaultTextField,
											fieldKey: "diagnosis",
											label: "Diagnosi",
											required: true,
										})}
									</div>
									{fields?.recordType?.value === "injury" ? (
										<div className="col-sm-12 col-md-6">
											{getTextField({
												...defaultTextField,
												fieldKey: "cause",
												label: "Causa",
											})}
										</div>
									) : null}
									{fields?.recordType?.value === "injury" ? (
										<div className="col-sm-12 col-md-6">
											{getTextField({
												...defaultTextField,
												fieldKey: "injuryType",
												label: "Tipologia infortunio",
											})}
										</div>
									) : null}
									{fields?.recordType?.value === "injury" ? (
										<div className="col-sm-12 col-md-6">
											{getTextField({
												...defaultTextField,
												fieldKey: "bodyPart",
												label: "Parte del corpo",
											})}
										</div>
									) : null}
									<div className="col-sm-12 col-md-6">
										<VLFDatePicker
											type="date"
											label="Dimissione medica"
											value={fields.medicalDischargeDate}
											onChange={(value) => {
												setFields({...fields, medicalDischargeDate: value});
											}}
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFDatePicker
											type="date"
											label="Dimissione sportiva"
											value={fields.sportsDischargeDate}
											onChange={(value) => {
												setFields({...fields, sportsDischargeDate: value});
											}}
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										{getTextField({
											...defaultTextField,
											fieldKey: "exam",
											label: "Esame",
										})}
									</div>
									<div className="col-sm-12 col-md-6">
										{getTextField({
											...defaultTextField,
											fieldKey: "therapy",
											label: "Terapia",
										})}
									</div>
									<div className="col-sm-12 col-md-6">
										{getTextAreaField({
											...defaultTextField,
											fieldKey: "notes",
											label: "Note",
											minRows: 5,
											maxRows: 10,
										})}
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default HandleMedicalInjuryPage;
