import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsList from "@/components/CardsList";

import {TrainingAPI} from "@/api/training";

import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";

// import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";

import {ERROR_OPERATION} from "@/utils/constants";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

const dateTimeFormat = "YYYY-MM-DDTHH:mm:ss";

const defaultFiltersData = {
	fromDate: moment().startOf("isoWeek").format(dateTimeFormat),
	toDate: moment().endOf("isoWeek").format(dateTimeFormat),
};

function TrainingsPage(props) {
	const {handleNotification} = props;
	const navigate = useNavigate();

	// const user = useSelector((state) => state?.user);
	const userRole = useSelector((state) => state?.user?.roleName);

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		formatted: true,
	});

	const [loading, setLoading] = useState(false);
	const [trainings, setTrainings] = useState([]);
	const [filtersData, setFiltersData] = useState(
		JSON.parse(sessionStorage.getItem("trainings_filters")) ||
			defaultFiltersData,
	);

	useEffect(() => {
		if (filtersData) {
			sessionStorage.setItem("trainings_filters", JSON.stringify(filtersData));
		}
	}, [filtersData]);

	const filters = [
		{
			key: "fromDate",
			type: "date",
			label: "Da",
			customClassName: "col-6 col-sm-4",
			clearable: false,
			value: moment(filtersData?.fromDate).format("YYYY-MM-DD"),
			setValue: (e) => {
				if (e) {
					setFiltersData({
						...filtersData,
						fromDate: moment(e)
							.set({hour: 0, minute: 0, second: 0})
							.format(dateTimeFormat),
					});
				}
			},
		},
		{
			key: "toDate",
			type: "date",
			label: "A",
			customClassName: "col-6 col-sm-4",
			clearable: false,
			value: moment(filtersData?.toDate).format("YYYY-MM-DD"),
			setValue: (e) => {
				if (e) {
					setFiltersData({
						...filtersData,
						toDate: moment(e)
							.set({hour: 23, minute: 59, second: 0})
							.format(dateTimeFormat),
					});
				}
			},
		},
		{
			key: "categoryId",
			type: "select",
			customClassName: "col-12 col-sm-4",
			options: categories,
			sorted: false,
			label: "Categoria",
			clearable: true,
			value: filtersData?.categoryId,
			setValue: (value) => {
				setFiltersData({...filtersData, categoryId: value});
			},
		},
	];

	const getTrainings = async () => {
		setLoading(true);
		try {
			const response = await TrainingAPI.list({
				...filtersData,
				categoryId: filtersData?.categoryId?.value,
			});
			setTrainings(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (categories?.length) {
			getTrainings();
		}
	}, [filtersData, categories]);

	const groupedTrainings = {};
	categories.forEach((c) => {
		const currentCategory = c.label;
		const playersMembers = trainings
			?.filter((t) => t.playersCategory === currentCategory)
			.map((t) => {
				if (moment().isSame(moment(t.startDate), "day")) {
					t.today = true;
				}
				return t;
			});
		groupedTrainings[currentCategory] = playersMembers;
	});

	const listConfiguration = {
		data: groupedTrainings,
		onClick: (training) => navigate("/trainings/" + training.id),
	};

	const mainActions = [
		{
			icon: <ArrowBack color="primary" />,
			variant: "outlined",
			onClick: () => {
				const filters = {
					...filtersData,
					fromDate: moment(filtersData?.fromDate)
						.subtract(1, "isoWeeks")
						.format(dateTimeFormat),
					toDate: moment(filtersData?.toDate)
						.subtract(1, "isoWeeks")
						.format(dateTimeFormat),
				};
				setFiltersData(filters);
			},
		},
		{
			icon: <ArrowForward color="primary" />,
			variant: "outlined",
			onClick: () => {
				const filters = {
					...filtersData,
					fromDate: moment(filtersData?.fromDate)
						.add(1, "isoWeeks")
						.format(dateTimeFormat),
					toDate: moment(filtersData?.toDate)
						.add(1, "isoWeeks")
						.format(dateTimeFormat),
				};
				setFiltersData(filters);
			},
		},
	];

	if (userRole !== "Player") {
		mainActions.push({
			icon: <CalendarMonthIcon />,
			onClick: () => navigate("/trainings/admin/new"),
		});
	}

	mainActions.push({
		icon: <AddIcon />,
		onClick: () => navigate("/trainings/new"),
	});

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={mainActions} />} />
			<CardsList
				type="training"
				breadcrumbs
				grouped
				filtersData={filters}
				forceOpened
				listConfiguration={listConfiguration}
				defaultSortedField="playersCategory"
				defaultSortedOrder="descend"
			/>
		</Page>
	);
}

export default TrainingsPage;
