import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFDatePicker from "@/components/Input/DatePicker";
import Rating from "@/components/Rating";
import VLFSelectNew from "@/components/Input/SelectNew";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import {MatchReportAPI} from "@/api/match_report";

import {
	REQUIRED_FIELD,
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
	dateFormat,
} from "@/utils/constants";
import {
	checkRequiredFields,
	getTextField,
	getTextAreaField,
} from "@/utils/functions";
import {useMatches, usePlayers} from "@/utils/hooks";

import "./index.scss";

const HandleMatchReportPage = (props) => {
	const {edit, external = false, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	// const playerId = new URLSearchParams(useLocation().search).get("playerId");

	const {players} = usePlayers({
		setLoading: false,
		handleNotification: undefined,
	});
	const {matches} = useMatches({
		setLoading: false,
		handleNotification: undefined,
	});

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({});

	const [descriptionsCounter, setDescriptionsCounter] = useState(1);

	const getReport = async () => {
		setLoading(true);
		try {
			const response = await MatchReportAPI.getById(id);

			response.fullMatch = Object.assign({}, response.match);
			if (response.player) {
				response.player = {
					value: response.player.id,
					label:
						response.player.firstName +
						" " +
						response.player.lastName +
						" (" +
						response.player.category +
						")",
				};
			}
			if (response.match) {
				response.match = {
					value: response.match.id,
					label:
						moment(response.match.startDate).format(dateFormat + " HH:mm") +
						" - " +
						response.match.homeTeam +
						" vs " +
						response.match.awayTeam,
				};
			}
			response.expelled = response.expelled
				? {value: "yes", label: "Sì"}
				: {value: "no", label: "No"};
			response.warned = response.warned
				? {value: "yes", label: "Sì"}
				: {value: "no", label: "No"};
			response.replaced = response.replaced
				? {value: "yes", label: "Sì"}
				: {value: "no", label: "No"};

			const descriptionsLength = [1, 2, 3, 4, 5, 6].filter(
				(n) => response["descriptionGoalConceded" + n],
			)?.length;
			setDescriptionsCounter(descriptionsLength);
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit) {
			getReport();
		}
	}, [edit]);

	const getReportDescriptions = () => {
		const descriptions = [];
		for (let i = 1; i <= descriptionsCounter; i++) {
			descriptions.push(
				<div key={i} className="d-flex align-items-center">
					{getTextAreaField({
						...defaultTextField,
						fieldKey: "descriptionGoalConceded" + i,
						minRows: 1,
						maxRows: 1,
					})}
				</div>,
			);
		}

		return descriptions;
	};

	const getRequiredFields = () => {
		const reqFields = [
			"reportDate",
			"reportAuthor",
			"player",
			"match",
			"reportText",
		];
		return reqFields;
	};

	const saveMatchReport = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);
		setErrors(errors);

		if (!errors.length) {
			setLoading(true);

			const body = Object.assign({}, fields);

			body.reportType = 7;
			body.valuationDate = moment(body.reportDate).format("YYYY-MM-DD");

			body.playerId = body?.player?.value;
			body.matchId = body?.match?.value;

			body.replaced = body.replaced?.value === "yes";
			body.warned = body.warned?.value === "yes";
			body.expelled = body.expelled?.value === "yes";

			try {
				const response = edit
					? await MatchReportAPI.update(id, body)
					: await MatchReportAPI.create(body);
				setTimeout(() => {
					const currentId = id || response?.id;
					navigate("/matches-reports/" + currentId);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		saveMatchReport();
	};

	const goBack = () => {
		navigate(-1);
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [
		{
			label: "Report gara",
			path: "/matches-reports",
		},
	];

	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: fields?.fullMatch
					? moment(fields?.fullMatch?.startDate).format(dateFormat + " HH:mm") +
						" - " +
						fields?.fullMatch?.homeTeam +
						" vs " +
						fields?.fullMatch?.awayTeam
					: "",
				path: "/matches-reports/" + id,
			},
		);
	}

	const defaultTextField = {
		values: fields,
		setValues: setFields,
		errors,
		setErrors,
	};

	const offensivePositiveTotal =
		parseInt(fields.shortPassOk || 0) +
		parseInt(fields.longMidThrowOk || 0) +
		parseInt(fields.restartWithHandsOk || 0) +
		parseInt(fields.remittalOnTheFlyOk || 0) +
		parseInt(fields.positiveTransitionOutsideOk || 0);
	const offensiveNegativeTotal =
		parseInt(fields.shortPassFailed || 0) +
		parseInt(fields.longMidThrowFailed || 0) +
		parseInt(fields.restartWithHandsFailed || 0) +
		parseInt(fields.remittalOnTheFlyFailed || 0) +
		parseInt(fields.positiveTransitionOutsideFailed || 0);
	const offensiveTotal = offensivePositiveTotal + offensiveNegativeTotal;

	const defensivePositiveTotal =
		parseInt(fields.saveGripOk || 0) +
		parseInt(fields.saveReboundOk || 0) +
		parseInt(fields.highClaimOk || 0) +
		parseInt(fields.highPunchOk || 0) +
		parseInt(fields.lowDiveOk || 0) +
		parseInt(fields.depthCoverageInsideOk || 0) +
		parseInt(fields.depthCoverageOutsideOk || 0);
	const defensiveNegativeTotal =
		parseInt(fields.saveGripFailed || 0) +
		parseInt(fields.saveReboundFailed || 0) +
		parseInt(fields.highClaimFailed || 0) +
		parseInt(fields.highPunchFailed || 0) +
		parseInt(fields.lowDiveFailed || 0) +
		parseInt(fields.depthCoverageInsideFailed || 0) +
		parseInt(fields.depthCoverageOutsideFailed || 0);
	const defensiveTotal = defensivePositiveTotal + defensiveNegativeTotal;

	const totalCorners =
		parseInt(fields.cornerOutIntervention || 0) +
		parseInt(fields.cornerOutSave || 0) +
		parseInt(fields.cornerOutDefendedTeammate || 0) +
		parseInt(fields.cornerOutOpponents || 0) +
		parseInt(fields.cornerOutGoal || 0) +
		parseInt(fields.cornerRetIntervention || 0) +
		parseInt(fields.cornerRetSave || 0) +
		parseInt(fields.cornerRetDefendedTeammate || 0) +
		parseInt(fields.cornerRetOpponents || 0) +
		parseInt(fields.cornerRetGoal || 0) +
		parseInt(fields.blockedCorners || 0) +
		parseInt(fields.shortCorners || 0);

	const getParameterRow = (paramLabel, okLabel, failedLabel) => {
		return (
			<div className="row">
				<div className="col-12 col-lg-6 parameter-container">
					<div className="field-label-container">
						<div className="field-label">{paramLabel}</div>
					</div>
					<div className="parameter-total">
						{parseInt(fields[okLabel] || 0) +
							parseInt(fields[failedLabel] || 0)}
					</div>
				</div>
				<div className="col-12 col-md-6 col-lg-3">
					{getTextField({
						...defaultTextField,
						type: "number",
						fieldKey: okLabel,
						label: "Sì",
					})}
				</div>
				<div className="col-12 col-md-6 col-lg-3">
					{getTextField({
						...defaultTextField,
						type: "number",
						fieldKey: failedLabel,
						label: "No",
					})}
				</div>
			</div>
		);
	};

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni report"
							content={
								<div className="row no-margin">
									<div className="col-12">
										<div className="row">
											<div className="col-12 col-md-6 col-xl-4">
												<VLFDatePicker
													label="Data"
													value={fields.reportDate}
													onChange={(value) => {
														setErrors([]);
														setFields({...fields, reportDate: value});
													}}
													required
													format="DD-MM-YYYY"
													size="small"
													helperText={
														errors.indexOf("reportDate") !== -1
															? REQUIRED_FIELD
															: ""
													}
													error={errors.indexOf("reportDate") !== -1}
												/>
											</div>
											<div className="col-12 col-md-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													fieldKey: "reportAuthor",
													label: "Allenatore",
													required: true,
												})}
											</div>
										</div>
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-12 padding-small">
						<VLFCard
							title="Informazioni partita"
							content={
								<div className="row no-margin single-area-container">
									<div className="col-12 col-sm-6 col-xl-4">
										<VLFSelectNew
											options={players.map((p) => {
												return {
													value: p.id,
													label:
														p.firstName +
														" " +
														p.lastName +
														" (" +
														p.category +
														")",
												};
											})}
											value={fields?.player}
											onChange={(value) => {
												setErrors([]);
												setFields({...fields, player: value});
											}}
											required
											helperText={
												errors.indexOf("player") !== -1 ? REQUIRED_FIELD : ""
											}
											error={errors.indexOf("player") !== -1}
											label={"Portiere"}
										/>
									</div>
									<div className="col-12 col-sm-6 col-xl-4">
										<VLFSelectNew
											options={matches?.map((m) => {
												return {
													value: m.id,
													label:
														moment(m.startDate).format(dateFormat + " HH:mm") +
														" - " +
														m.homeTeam +
														" vs " +
														m.awayTeam,
												};
											})}
											sortKey="startDate"
											value={fields?.match}
											onChange={(value) => {
												setErrors([]);
												setFields({...fields, match: value});
											}}
											required
											helperText={
												errors.indexOf("match") !== -1 ? REQUIRED_FIELD : ""
											}
											error={errors.indexOf("match") !== -1}
											label={"Partita"}
										/>
									</div>
									<div className="col-12 col-sm-6 col-xl-4">
										{getTextField({
											...defaultTextField,
											fieldKey: "heatingExecution",
											label: "Esecuzione riscaldamento",
										})}
									</div>
									<div className="col-12 col-sm-6 col-xl-4">
										<div className="field-label-container">
											<div className="field-label">Sostituito</div>
										</div>
										<div>
											<VLFSelectNew
												options={[
													{value: "yes", label: "Sì"},
													{value: "no", label: "No"},
												]}
												sorted={false}
												value={fields?.replaced}
												onChange={(value) => {
													setFields({...fields, replaced: value});
												}}
											/>
											{getTextField({
												...defaultTextField,
												fieldKey: "reasonReplaced",
												placeholder: "Motivazione",
												disabled: fields.replaced?.value !== "yes",
											})}
										</div>
									</div>
									<div className="col-12 col-sm-6 col-xl-4">
										<div className="field-label-container">
											<div className="field-label">Ammonito</div>
										</div>
										<div>
											<VLFSelectNew
												options={[
													{value: "yes", label: "Sì"},
													{value: "no", label: "No"},
												]}
												sorted={false}
												value={fields?.warned}
												onChange={(value) => {
													setFields({...fields, warned: value});
												}}
											/>
											{getTextField({
												...defaultTextField,
												fieldKey: "reasonWarned",
												placeholder: "Motivazione",
												disabled: fields.warned?.value !== "yes",
											})}
										</div>
									</div>
									<div className="col-12 col-sm-6 col-xl-4">
										<div className="field-label-container">
											<div className="field-label">Espulso</div>
										</div>
										<div>
											<VLFSelectNew
												options={[
													{value: "yes", label: "Sì"},
													{value: "no", label: "No"},
												]}
												sorted={false}
												value={fields?.expelled}
												onChange={(value) => {
													setFields({...fields, expelled: value});
												}}
											/>
											{getTextField({
												...defaultTextField,
												placeholder: "Motivazione",
												fieldKey: "reasonExpelled",
												disabled: fields.expelled?.value !== "yes",
											})}
										</div>
									</div>
									<div className="col-12 col-sm-6 col-xl-4">
										{getTextField({
											...defaultTextField,
											type: "number",
											fieldKey: "minutesPlayed",
											label: "Minuti giocati",
										})}
									</div>
									<div className="col-12 col-sm-6 col-xl-4">
										{getTextField({
											...defaultTextField,
											type: "number",
											fieldKey: "totalGoalsConceded",
											label: "Goal concessi",
										})}
									</div>
									<div className="col-12 col-sm-6 col-xl-4">
										{getTextAreaField({
											...defaultTextField,
											label: "Note",
											fieldKey: "notes",
											minRows: 2,
											maxRows: 2,
										})}
									</div>
									<div className="col-12">
										<div className="d-flex align-items-center">
											<div className="field-label-container">
												<div className="field-label">
													Descrizione goal subiti
												</div>
											</div>
										</div>
										{getReportDescriptions()}
										<div className="d-flex align-items-center justify-content-end gap-2">
											<Button
												variant="outlined"
												onClick={() => {
													setDescriptionsCounter(descriptionsCounter - 1);
													setFields({
														...fields,
														["descriptionGoalConceded" + descriptionsCounter]:
															"",
													});
												}}
												size="small"
												disabled={descriptionsCounter < 2}
												color="error"
											>
												<div className="d-flex align-items-center">
													<div>Rimuovi ultima</div>
												</div>
											</Button>
											<Button
												variant="outlined"
												onClick={() => {
													setDescriptionsCounter(descriptionsCounter + 1);
												}}
												size="small"
												disabled={descriptionsCounter > 5}
											>
												<div className="d-flex align-items-center">
													<div>Aggiungi</div>
												</div>
											</Button>
										</div>
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni valutazione"
							content={
								<div className="row no-margin parameters-area">
									<div className="col-12">
										<div className="row">
											<div className="col-12 col-md-6">
												{getTextField({
													...defaultTextField,
													fieldKey: "generalOpinion",
													label: "Opinione generale",
												})}
											</div>
											<div className="col-12 col-md-6">
												<div className="field-label-container">
													<div className="field-label">Valutazione</div>
												</div>
												<Rating
													selectedRating={fields.generalRating}
													setSelectedRating={(value) => {
														setFields({...fields, generalRating: value});
													}}
													max={10}
													min={1}
												/>
											</div>
											<div className="col-12 col-lg-6 col-xl-3 parameter-container">
												<div className="field-label-container">
													<div className="field-label">
														{"Totale offensivo"}
													</div>
												</div>
												<div className="parameter-total">{offensiveTotal}</div>
											</div>
											<div className="col-12 col-lg-6 col-xl-3 parameter-container">
												<div className="field-label-container">
													<div className="field-label">
														{"Totale difensivo"}
													</div>
												</div>
												<div className="parameter-total">{defensiveTotal}</div>
											</div>
											<div className="col-12 col-lg-6 col-xl-3 parameter-container">
												<div className="field-label-container">
													<div className="field-label">{"Volume totale"}</div>
												</div>
												<div className="parameter-total">
													{offensiveTotal + defensiveTotal}
												</div>
											</div>
											<div className="col-12 col-lg-6 col-xl-3 parameter-container">
												<div className="field-label-container">
													<div className="field-label">
														{"Totale angoli subiti"}
													</div>
												</div>
												<div className="parameter-total">{totalCorners}</div>
											</div>
										</div>
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Gioco offensivo"
							content={
								<div className="row parameters-area">
									<div className="col-12 col-xl-3">
										<div className="parameters-image">
											<img
												src={require("@/assets/images/manager/pass-soccer-ball.png")}
												alt=""
											/>
										</div>
									</div>
									<div className="col-12 col-xl-9">
										{getParameterRow(
											"Passaggio corto",
											"shortPassOk",
											"shortPassFailed",
										)}
										{getParameterRow(
											"Lancio medio e lungo",
											"longMidThrowOk",
											"longMidThrowFailed",
										)}
										{getParameterRow(
											"Ripartenza con le mani",
											"restartWithHandsOk",
											"restartWithHandsFailed",
										)}
										{getParameterRow(
											"Ripartenza con rinvio al volo",
											"remittalOnTheFlyOk",
											"remittalOnTheFlyFailed",
										)}
										{getParameterRow(
											"Transizione positiva fuori area",
											"positiveTransitionOutsideOk",
											"positiveTransitionOutsideFailed",
										)}
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Gioco difensivo"
							content={
								<div className="row parameters-area">
									<div className="col-12 col-md-3">
										<div className="parameters-image">
											<img
												src={require("@/assets/images/manager/hockey-goalpost.png")}
												alt=""
											/>
										</div>
									</div>
									<div className="col-12 col-md-9">
										{getParameterRow(
											"Parata in presa",
											"saveGripOk",
											"saveGripFailed",
										)}
										{getParameterRow(
											"Parata in respinta/deviazione",
											"saveReboundOk",
											"saveReboundFailed",
										)}
										{getParameterRow(
											"Uscita alta presa",
											"highClaimOk",
											"highClaimFailed",
										)}
										{getParameterRow(
											"Uscita alta deviazione/respinta",
											"highPunchOk",
											"highPunchFailed",
										)}
										{getParameterRow(
											"Uscita bassa/attacco palla",
											"lowDiveOk",
											"lowDiveFailed",
										)}
										{getParameterRow(
											"Copertura profondità area",
											"depthCoverageInsideOk",
											"depthCoverageInsideFailed",
										)}
										{getParameterRow(
											"Copertura profondità fuori area",
											"depthCoverageOutsideOk",
											"depthCoverageOutsideFailed",
										)}
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Calci d'angolo ad uscire"
							content={
								<div className="row no-margin">
									<div className="col-12">
										<div className="row">
											<div className="col-12 col-md-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													type: "number",
													fieldKey: "cornerOutIntervention",
													label: "Uscita del portiere",
												})}
											</div>
											<div className="col-12 col-md-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													type: "number",
													fieldKey: "cornerOutSave",
													label: "Parata del portiere (sviluppo diretto)",
												})}
											</div>
											<div className="col-12 col-md-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													type: "number",
													fieldKey: "cornerOutDefendedTeammate",
													label: "Palla difesa dai compagni",
												})}
											</div>
											<div className="col-12 col-md-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													type: "number",
													fieldKey: "cornerOutOpponents",
													label: "Palla colpita da avversari (no goal)",
												})}
											</div>
											<div className="col-12 col-md-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													type: "number",
													fieldKey: "cornerOutGoal",
													label: "Goal (sviluppo diretto)",
												})}
											</div>
										</div>
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Calci d'angolo a rientrare"
							content={
								<div className="row no-margin">
									<div className="col-12">
										<div className="row">
											<div className="col-12 col-md-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													type: "number",
													fieldKey: "cornerRetIntervention",
													label: "Uscita del portiere",
												})}
											</div>
											<div className="col-12 col-md-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													type: "number",
													fieldKey: "cornerRetSave",
													label: "Parata del portiere (sviluppo diretto)",
												})}
											</div>
											<div className="col-12 col-md-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													type: "number",
													fieldKey: "cornerRetDefendedTeammate",
													label: "Palla difesa dai compagni",
												})}
											</div>
											<div className="col-12 col-md-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													type: "number",
													fieldKey: "cornerRetOpponents",
													label: "Palla colpita da avversari (no goal)",
												})}
											</div>
											<div className="col-12 col-md-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													type: "number",
													fieldKey: "cornerRetGoal",
													label: "Goal (sviluppo diretto)",
												})}
											</div>
										</div>
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Statistiche varie"
							content={
								<div className="row no-margin parameters-area">
									<div className="col-12">
										<div className="row">
											<div className="col-12 col-md-4">
												{getTextField({
													...defaultTextField,
													type: "number",
													fieldKey: "blockedCorners",
													label: "Angoli bloccati (uscire e rientrare)",
												})}
											</div>
											<div className="col-12 col-md-4">
												{getTextField({
													...defaultTextField,
													type: "number",
													fieldKey: "shortCorners",
													label: "Angoli battuti corti",
												})}
											</div>
											<div className="col-12 col-md-4">
												{getTextField({
													...defaultTextField,
													type: "number",
													fieldKey: "totalAttacksArea",
													label: "Totale attacchi dell'area",
												})}
											</div>
										</div>
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Generali"
							content={
								<div className="row no-margin">
									<div className="col-12">
										{getTextAreaField({
											...defaultTextField,
											fieldKey: "reportText",
											label: "Relazione generale",
											required: true,
											minRows: 10,
											maxRows: 10,
										})}
									</div>
								</div>
							}
						/>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default HandleMatchReportPage;
