import React, {useEffect, useState} from "react";

import VLFModal from "@/components/Modal";
import VLFSelectNew from "@/components/Input/SelectNew";
import CreateSelectEntity from "@/components/CreateSelectEntity";

import {ExerciseAPI} from "@/api/exercise";

import {ERROR_OPERATION, REQUIRED_FIELD} from "@/utils/constants";
import {
	checkRequiredFields,
	getTextField,
	getTextAreaField,
} from "@/utils/functions";

function HandleExerciseModal(props) {
	const {open, confirm, close, initialData, handleNotification} = props;

	const [exercises, setExercises] = useState([]);
	const [saveOption, setSaveOption] = useState("select");
	const [formData, setFormData] = useState({
		exercise: null,
	});
	const [errors, setErrors] = useState([]);

	const getExercises = async () => {
		try {
			const response = await ExerciseAPI.list();
			setExercises(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	useEffect(() => {
		getExercises();
	}, []);

	useEffect(() => {
		if (initialData) {
			setFormData(initialData);
		}
	}, [initialData]);

	useEffect(() => {
		if (!open) {
			setFormData({});
		}
	}, [open]);

	const defaultTextField = {
		values: formData,
		setValues: setFormData,
		errors,
		setErrors,
	};

	const requiredFields =
		saveOption === "select" ? ["exercise"] : ["name", "duration"];

	return (
		<VLFModal
			maxWidth="sm"
			open={open}
			title={initialData ? "Modifica informazione" : "Aggiungi informazione"}
			close={close}
			confirm={() => {
				const errors = checkRequiredFields(requiredFields, formData);
				setErrors(errors);

				if (!errors.length) {
					const data =
						saveOption === "new"
							? {...formData, exercise: undefined}
							: formData;
					confirm(data, initialData?.id);
				}
			}}
			content={
				<div className="row">
					<div className="col-12">
						<CreateSelectEntity
							currentOption={saveOption}
							setCurrentOption={setSaveOption}
						/>
						{saveOption === "select" ? (
							<VLFSelectNew
								options={exercises.map((r) => {
									return {
										value: r.id,
										label: r.name,
									};
								})}
								value={formData.exercise || ""}
								onChange={(value) => {
									const current = exercises?.filter(
										(ex) => ex.id === value?.value,
									)?.[0];

									setErrors([]);
									setFormData({
										...formData,
										exercise: value,
										duration: current?.duration,
										description: current?.description,
									});
								}}
								required
								helperText={
									errors.indexOf("exercise") !== -1 ? REQUIRED_FIELD : ""
								}
								error={errors.indexOf("exercise") !== -1}
								label="Esercizio"
							/>
						) : (
							<>
								<div className="col-12">
									{getTextField({
										...defaultTextField,
										fieldKey: "name",
										label: "Nome",
										required: true,
									})}
								</div>
								<div className="col-12">
									{getTextField({
										...defaultTextField,
										fieldKey: "exerciseType",
										label: "Tipologia",
									})}
								</div>
							</>
						)}
					</div>
					<div className="col-12">
						{getTextField({
							...defaultTextField,
							fieldKey: "duration",
							label: "Durata (minuti)",
							type: "number",
							required: true,
						})}
					</div>
					<div className="col-12">
						{getTextAreaField({
							...defaultTextField,
							fieldKey: "description",
							label: "Descrizione",
							minRows: 5,
							maxRows: 10,
						})}
					</div>
				</div>
			}
		/>
	);
}

export default HandleExerciseModal;
