import * as React from "react";
import {useNavigate} from "react-router-dom";

import "./index.scss";

export default function Breadcrumbs(props) {
	const {
		listElements,
		currentListElement,
		elements,
		actions,
		current,
		noPadding = false,
	} = props;

	const navigate = useNavigate();

	let render = (
		<div
			className={
				"breadcrumbs-container" +
				(elements || current ? " not-empty" : "") +
				(listElements ? " tabbed" : "")
			}
		>
			{elements && (
				<div key="back" className="bc-element">
					{"<"}
				</div>
			)}
			{elements?.map((bE, k) => {
				return (
					<div
						key={k}
						className={"bc-element" + (bE.path ? " clickable" : "")}
						onClick={() => {
							if (bE.path) {
								navigate(bE.path);
							}
						}}
					>
						{bE.label}
					</div>
				);
			})}
			{listElements?.map((lE, k) => {
				return (
					<div
						key={k}
						className={
							"tab-element clickable" +
							(currentListElement === lE.value ? " selected" : "")
						}
						onClick={lE.onClick}
					>
						{lE.label}
					</div>
				);
			})}
			{current && (
				<div key="current" className="bc-element current">
					{current}
				</div>
			)}
		</div>
	);

	return (
		<div className="row no-margin">
			<div className={"col-sm-12" + (!noPadding ? " padding-small" : "")}>
				<div
					className={
						"d-flex justify-content-between breadcrumbs-block-container" +
						(!elements ? " empty" : "")
					}
				>
					{render}
					{actions}
				</div>
			</div>
		</div>
	);
}
