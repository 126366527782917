import api from "./configuration";
import apiUrl from "./url";

const USE_AUTH_INTERCEPTOR = true;

export const SeasonAPI = {
	current: async () => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/CurrentSeason",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	allSeasons: async () => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/AllSeasons",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getById: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Season/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
};
