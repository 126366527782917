import * as React from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";

import "./index.scss";

export default function NavigationBox(props) {
	const {
		label,
		score,
		value,
		imagePath,
		imageLabel,
		info,
		dateInfo,
		onClick,
		dashboard,
	} = props;

	let matchTypeIcon = "";
	switch (imagePath) {
		case "Torneo":
		case "Coppa":
			matchTypeIcon = require("@/assets/images/coach/trophy.png");
			break;
		case "Amichevole":
			matchTypeIcon = require("@/assets/images/coach/match.png");
			break;
		case "Campionato":
			matchTypeIcon = require("@/assets/images/coach/league.png");
			break;
		case "Scout":
			matchTypeIcon = require("@/assets/images/manager/evaluation.png");
			break;
		default:
			break;
	}

	return !dashboard ? (
		<div className="section-box" onClick={onClick}>
			{imagePath ? (
				<div className="section-item small">
					<img src={imagePath} alt="" />
				</div>
			) : null}
			<div className="section-item">
				<div className="label">{label}</div>
				<div className="date">{value}</div>
			</div>
		</div>
	) : (
		<div className="section-box-dashboard" onClick={onClick}>
			{dateInfo && (
				<div className="section-item-date-info d-flex align-items-center">
					<AccessTimeIcon fontSize="small" />
					{/* <div className="light mx-1">{dateInfo}</div> */}
				</div>
			)}
			<div className="section-item-info">
				<div>{info || "---"}</div>
			</div>
			{imagePath ? (
				<div className="section-item-dashboard small">
					<img src={matchTypeIcon} alt="" />
					{imageLabel && <div className="item-image-label">{imageLabel}</div>}
				</div>
			) : null}
			<div className="section-item-dashboard flex-row justify-content-around">
				<div className="d-flex align-items-center flex-column">
					<div className="teams-dashboard">{label}</div>
					<div className="value-dashboard">{value || "---"}</div>
				</div>
				{score && <div className="score-dashboard">{score}</div>}
			</div>
		</div>
	);
}
