import React, {useEffect, useState} from "react";

import EmptyData from "@/components/EmptyData";
import Filters from "@/components/Filters";
import Button from "@mui/material/Button";
import AthleticTestCard from "@/components/CardsList/AthleticTestCard";
import ExerciseCard from "@/components/CardsList/ExerciseCard";
import GeneralCard from "@/components/CardsList/GeneralCard";
import MatchCard from "@/components/CardsList/MatchCard";
import MedicalCard from "@/components/CardsList/MedicalCard";
import PlayerMovementCard from "@/components/CardsList/PlayerMovementCard";
import RPECard from "@/components/CardsList/RPECard";
import RPEStatCard from "@/components/CardsList/RPEStatCard";
import ScoutClubCard from "@/components/CardsList/ScoutClubCard";
import ScoutCoachCard from "@/components/CardsList/ScoutCoachCard";
import ScoutPlayerCard from "@/components/CardsList/ScoutPlayerCard";
import ScoutReportCard from "@/components/CardsList/ScoutReportCard";
import StaffCard from "@/components/CardsList/StaffCard";
import TargetCard from "@/components/CardsList/TargetCard";
import TQRCard from "@/components/CardsList/TQRCard";
import TQRStatCard from "@/components/CardsList/TQRStatCard";
import TrainingCard from "@/components/CardsList/TrainingCard";
import VASCard from "@/components/CardsList/VASCard";
import VideoCard from "@/components/CardsList/VideoCard";
import ValuationCard from "@/components/CardsList/ValuationCard";

import "./index.scss";
import MatchReportCard from "./MatchReportCard";

export default function CardsList(props) {
	const {
		breadcrumbs = false,
		grouped = false,
		noEmptyGroups = false,
		tabbed = false,
		middleHeight = false,
		fitHeight = false,
		listConfiguration,
		handleDelete,
		handleUpdate,
		// handleNewEntity,
		defaultSortedField,
		defaultSortedOrder = "ascend",
		filtersData,
		forceOpened = false,
		type,
	} = props;

	const [opened, setOpened] = useState(false);

	useEffect(() => {
		if (forceOpened) {
			setOpened(true);
		}
	}, [forceOpened]);

	const renderElement = (element, key) => {
		let cardElement = (
			<>
				{listConfiguration.date && (
					<div className="entity-date">{listConfiguration.date(element)}</div>
				)}
				{listConfiguration.mainTitle && (
					<div className="entity-title">
						{listConfiguration.mainTitle(element)}
					</div>
				)}
				{listConfiguration.status && (
					<div
						className={"entity-status " + listConfiguration.status(element)}
					></div>
				)}
				{listConfiguration.icon && (
					<div className="entity-icon">{listConfiguration.icon(element)}</div>
				)}
				<div className="entity-data-container">
					<div className={"main-info-container" + (type ? " " + type : "")}>
						{listConfiguration.imageUrl ? (
							<div className="image">
								<img src={listConfiguration.imageUrl(element)} alt="" />
							</div>
						) : null}
						{(listConfiguration.title || listConfiguration.subtitle) && (
							<div className="info">
								{listConfiguration.title && (
									<span className="title">
										{listConfiguration.title(element)}
									</span>
								)}
								{listConfiguration.subtitle && (
									<span className="subtitle">
										{listConfiguration.subtitle(element)}
									</span>
								)}
							</div>
						)}
					</div>
					{listConfiguration.logo && listConfiguration.logo(element) && (
						<div className="image logo">
							<img src={listConfiguration.logo(element)} alt="" />
						</div>
					)}
					{(listConfiguration.text?.(element) ||
						listConfiguration.subtext?.(element) ||
						listConfiguration.actions) && <div className="separator" />}
					{(listConfiguration.text?.(element) ||
						listConfiguration.subtext?.(element)) && (
						<div className="info">
							{listConfiguration.text && listConfiguration.text(element) && (
								<span className="text">{listConfiguration.text(element)}</span>
							)}
							{listConfiguration.subtext &&
								listConfiguration.subtext(element) && (
									<span className="subtitle">
										{listConfiguration.subtext(element)}
									</span>
								)}
						</div>
					)}
					{listConfiguration.actions ? (
						<div className="actions justify-content-center my-1">
							{listConfiguration.actions.map((action, key) => {
								return (
									<div key={key} className="action">
										<Button
											variant={action.variant || "contained"}
											onClick={() => action.onClick(element)}
											color={action.color || "blue"}
											size="small"
										>
											<div className="action-content d-flex align-items-center">
												<div>{action.icon && action.icon}</div>
												{/* <div>{action.title && action.title}</div> */}
											</div>
										</Button>
									</div>
								);
							})}
						</div>
					) : null}
				</div>
			</>
		);

		const actionsProps = {};
		if (handleDelete) {
			actionsProps.handleDelete = handleDelete;
		}
		if (handleUpdate) {
			actionsProps.handleUpdate = handleUpdate;
		}

		switch (type) {
			case "exercise":
				cardElement = <ExerciseCard element={element} />;
				break;
			case "general":
				cardElement = <GeneralCard element={element} />;
				break;
			case "match_calendar":
			case "match_player":
			case "match_scout":
			case "match":
				cardElement = (
					<MatchCard element={element} type={type} matchKey={key} />
				);
				break;
			case "medical":
				cardElement = (
					<MedicalCard element={element} actions={listConfiguration?.actions} />
				);
				break;
			case "player_movement":
				cardElement = (
					<PlayerMovementCard
						element={element}
						actions={listConfiguration?.actions}
					/>
				);
				break;
			case "report":
				cardElement = <ScoutReportCard element={element} />;
				break;
			case "rpe":
				cardElement = <RPECard element={element} />;
				break;
			case "rpe_stat":
				cardElement = <RPEStatCard element={element} />;
				break;
			case "scout_club":
				cardElement = <ScoutClubCard element={element} />;
				break;
			case "scout_coach":
				cardElement = <ScoutCoachCard element={element} />;
				break;
			case "scout_player":
				cardElement = <ScoutPlayerCard element={element} {...actionsProps} />;
				break;
			case "staff":
				cardElement = <StaffCard element={element} />;
				break;
			case "target":
				cardElement = <TargetCard element={element} />;
				break;
			case "test":
				cardElement = <AthleticTestCard element={element} />;
				break;
			case "test_base":
				cardElement = <AthleticTestCard element={element} base />;
				break;
			case "tqr":
				cardElement = (
					<TQRCard
						element={element}
						handleEdit={listConfiguration.handleEdit}
						handleDelete={listConfiguration.handleDelete}
					/>
				);
				break;
			case "tqr_stat":
				cardElement = <TQRStatCard element={element} />;
				break;
			case "training":
				cardElement = <TrainingCard element={element} />;
				break;
			case "video":
				cardElement = <VideoCard element={element} />;
				break;
			case "valuation":
				cardElement = <ValuationCard element={element} />;
				break;
			case "match_report":
				cardElement = <MatchReportCard element={element} />;
				break;
			case "vas":
				cardElement = (
					<VASCard
						element={element}
						handleEdit={listConfiguration.handleEdit}
						handleDelete={listConfiguration.handleDelete}
					/>
				);
				break;
			default:
				break;
		}

		return cardElement;
	};

	const renderList = (data) => {
		return data?.map((element, key) => {
			let defaultGridClassName = "col-12 col-sm-6 col-xl-3";

			if (type === "scout_club") {
				defaultGridClassName = "col-12 col-sm-6";
			}
			if (type === "staff") {
				defaultGridClassName = "col-12 col-sm-6 col-lg-4 col-xl-3";
			}
			if (type === "test_base") {
				defaultGridClassName = "col-6 col-sm-4 col-xl-3";
			}
			if (type === "tqr_stat") {
				defaultGridClassName = "col-12 col-sm-6 col-lg-3";
			}
			if (type === "users") {
				defaultGridClassName = "col-6 col-sm-4 col-xl-3";
			}
			if (type === "general") {
				defaultGridClassName = "col-6 col-sm-4";
			}
			return (
				<div
					key={key}
					className={"entities-columns no-padding " + defaultGridClassName}
				>
					<div
						className={
							"single-entity-container" +
							(!listConfiguration.onClick ? " disabled" : "") +
							(listConfiguration.customClassName?.(element)
								? " " + listConfiguration.customClassName(element)
								: "") +
							(type ? " " + type : "") +
							(element.today ? " today" : "")
						}
						onClick={() => {
							listConfiguration.onClick && listConfiguration.onClick(element);
						}}
					>
						{renderElement(element, key)}
					</div>
				</div>
			);
		});
	};

	// const renderNewElement = () => {
	// 	return (
	// 		handleNewEntity && (
	// 			<div
	// 				key="new"
	// 				className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 entities-columns no-padding"
	// 			>
	// 				<div
	// 					className="single-entity-container new"
	// 					onClick={handleNewEntity}
	// 				>
	// 					<div className="new-content">+</div>
	// 					<div className="new-content-label">Aggiungi</div>
	// 				</div>
	// 			</div>
	// 		)
	// 	);
	// };

	const sortedData =
		!grouped && defaultSortedField
			? listConfiguration?.data?.sort((a, b) =>
					defaultSortedOrder === "descend"
						? b[defaultSortedField] > a[defaultSortedField]
							? 1
							: a[defaultSortedField] > b[defaultSortedField]
								? -1
								: 0
						: a[defaultSortedField] > b[defaultSortedField]
							? 1
							: b[defaultSortedField] > a[defaultSortedField]
								? -1
								: 0,
				)
			: listConfiguration?.data;

	return (
		<>
			{filtersData && (
				<Filters
					data={filtersData}
					opened={opened}
					setOpened={setOpened}
					forceOpened={forceOpened}
				/>
			)}
			{
				<>
					{/* {handleNewEntity && (
						<div className="d-flex align-items-center justify-content-end mt-2 mx-1">
							<Button
								variant="contained"
								onClick={handleNewEntity}
								size="small"
							>
								<div className="d-flex align-items-center">
									<div>Aggiungi</div>
								</div>
							</Button>
						</div>
					)} */}
					<div
						className={
							"groups-container" +
							(fitHeight ? " fit-height" : "") +
							(breadcrumbs ? " breadcrumbs" : "") +
							(filtersData ? (opened ? " fixed-filters" : " filters") : "") +
							(tabbed ? " tabbed" : "") +
							(middleHeight ? " middle-height" : "")
						}
					>
						{grouped ? (
							Object.keys(sortedData)
								.sort((a, b) =>
									defaultSortedOrder === "descend"
										? b > a
											? 1
											: a > b
												? -1
												: 0
										: a > b
											? 1
											: b > a
												? -1
												: 0,
								)
								.filter((element) =>
									filtersData?.filter((f) => f.key === "categoryId" && f.value)
										?.length || noEmptyGroups
										? sortedData[element]?.length
										: true,
								)
								.map((element, key) => {
									return (
										<div
											key={key}
											className={
												"row no-margin entities-container grouped compact"
											}
										>
											<div className="group-title padding-small">{element}</div>
											{sortedData[element]?.length ? (
												renderList(sortedData[element])
											) : (
												<EmptyData />
											)}
										</div>
									);
								})
						) : (
							<div className={"row no-margin entities-container compact"}>
								{/* {renderNewElement()} */}
								{sortedData?.length ? renderList(sortedData) : <EmptyData />}
							</div>
						)}
					</div>
				</>
			}
		</>
	);
}
