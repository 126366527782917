import * as React from "react";
import moment from "moment";

import {dateFormat, externalValuationOptions} from "@/utils/constants";

import "./index.scss";

export default function MatchReportCard(props) {
	const {element} = props;

	return (
		<div className="valuation-card-container">
			<div className="valuation-card-header">
				<div className="top-left">
					{moment(element.reportDate).format(dateFormat)}
				</div>
				<div className="top-right">{element?.player?.category}</div>
			</div>
			<div className="valuation-card-info">
				<div className="d-flex flex-column">
					<div className="info-title">
						{element?.player?.firstName + " " + element?.player?.lastName}
					</div>
					{element?.match ? (
						<div className="info-second-title">
							{moment(element.match.startDate).format(dateFormat) +
								" " +
								element?.match?.homeTeam +
								" vs " +
								element?.match?.awayTeam}
						</div>
					) : null}
				</div>
			</div>
			<div className="separator"></div>
			<div className="valuation-card-preview justify-content-between">
				<div className="valuation-type">
					<div className="type-info">{element.reportAuthor || "---"}</div>
				</div>
				<div className="valuation-type">
					<div className="rating-info">
						{+parseFloat(element?.generalRating)?.toFixed(2) || "---"}
					</div>
				</div>
			</div>
		</div>
	);
}
