import React, {useEffect, useState} from "react";
// import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import AddIcon from "@mui/icons-material/Add";

import {ProspectAPI} from "@/api/prospect";
// import {SeasonAPI} from "@/api/season";

import {ERROR_OPERATION} from "@/utils/constants";

function ScoutClubsPage(props) {
	const {handleNotification} = props;

	const navigate = useNavigate();

	// const seasonId = useSelector((state) => state?.season?.id);
	// const [seasons, setSeasons] = useState([]);

	const [loading, setLoading] = useState(false);
	const [clubs, setClubs] = useState([]);

	// const getSeasons = async () => {
	// 	try {
	// 		const response = await SeasonAPI.allSeasons();
	// 		setSeasons(
	// 			response.map((s) => {
	// 				return {
	// 					value: s.id,
	// 					label: s.name,
	// 				};
	// 			}),
	// 		);
	// 	} catch (error) {
	// 		handleNotification(ERROR_OPERATION, "error");
	// 	}
	// };
	// useEffect(() => {
	// 	getSeasons();
	// }, []);

	// const [filtersData, setFiltersData] = useState({});
	// const filters = [
	// 	{
	// 		key: "seasonId",
	// 		type: "select",
	// 		options: seasons,
	// 		sorted: true,
	// 		sortOrder: "descend",
	// 		label: "Stagione",
	// 		customClassName: "col-12 col-sm-4",
	// 		clearable: false,
	// 		value: filtersData?.seasonId,
	// 		setValue: (value) => {
	// 			setFiltersData({...filtersData, seasonId: value});
	// 		},
	// 	},
	// ];

	const getClubs = async () => {
		setLoading(true);
		try {
			const response = await ProspectAPI.teamsList();
			setClubs(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getClubs();
	}, []);

	// useEffect(() => {
	// 	if (seasons?.length) {
	// 		setFiltersData(
	// 			JSON.parse(sessionStorage.getItem("scout_clubs_filters")) || {
	// 				seasonId: seasons?.filter((s) => s.value === seasonId)?.[0],
	// 			},
	// 		);
	// 	}
	// }, [seasons]);

	const listConfiguration = {
		data: clubs,
		onClick: (club) => navigate("/scout-clubs/" + club.id),
	};

	const columns = [
		{label: "Nome", key: "name", align: "start"},
		{label: "Regione", key: "region", align: "start", medium: true},
		{label: "Città", key: "city", align: "start", medium: true},
		{label: "Indirizzo", key: "address", align: "start", medium: true},
	];

	const headerActions = [
		{
			icon: <AddIcon />,
			title: "Aggiungi",
			onClick: () => navigate("/scout-clubs/new"),
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={headerActions} />} />
			<CardsContainer
				tableComponent={
					<CardsTable
						breadcrumbs
						fullWidth
						configurationColumns={columns}
						data={clubs}
						handleClick={(team) => navigate("/scout-clubs/" + team.id)}
						defaultSortedField="name"
						defaultSortedOrder="ascend"
					/>
				}
				listComponent={
					<CardsList
						type="scout_club"
						breadcrumbs
						listConfiguration={listConfiguration}
						defaultSortedField="name"
						defaultSortedOrder="ascend"
					/>
				}
			/>
		</Page>
	);
}

export default ScoutClubsPage;
