import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import Button from "@mui/material/Button";
import CardsTable from "@/components/CardsTable";
import AttachmentsContainer from "@/components/AttachmentsContainer";
import VLFModal from "@/components/Modal";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
// import ControlPointIcon from "@mui/icons-material/ControlPoint";

import {MedicalAPI} from "@/api/medical";
import {AttachmentAPI} from "@/api/attachment";

import {
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
	dateFormat,
} from "@/utils/constants";

import "./index.scss";

function MedicalRecordPage(props) {
	const {handleNotification, onlyDetails = false} = props;

	const {id} = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [recordData, setRecordData] = useState({});
	const [attachments, setAttachments] = useState([]);

	const [entityToDelete, setEntityToDelete] = useState({});

	const getRecord = async () => {
		setLoading(true);
		try {
			const response = onlyDetails
				? await MedicalAPI.recordsList({playerId: id})
				: await MedicalAPI.recordGetById(id);

			let detailsResponse = {};

			if (onlyDetails) {
				detailsResponse = await MedicalAPI.recordGetById(response?.[0].id);
			}
			setRecordData(onlyDetails ? detailsResponse : response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const getRecordAttachments = async () => {
		try {
			const response = await AttachmentAPI.list("medical_record", id);
			setAttachments(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const getAttachment = async (id) => {
		setLoading(true);
		try {
			const currentFile = attachments.filter((a) => a.id === id)?.[0];
			const response = await AttachmentAPI.getById(id, {responseType: "blob"});

			// Ottieni l'estensione del file
			const extension = currentFile.filePath
				.split("\\")
				.pop()
				.split(".")
				.pop()
				.toLowerCase();
			let mimeType = "application/octet-stream"; // Default per file sconosciuti

			// eslint-disable-next-line default-case
			switch (extension) {
				case "pdf":
					mimeType = "application/pdf";
					break;
				case "png":
					mimeType = "image/png";
					break;
				case "jpg":
				case "jpeg":
					mimeType = "image/jpeg";
					break;
				case "doc":
					mimeType = "doc";
					break;
			}

			const blob = new Blob([response], {type: mimeType});

			const url = window.URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", currentFile.filePath.split("\\").pop());

			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const deleteAttachment = async () => {
		setLoading(true);
		try {
			await AttachmentAPI.delete(entityToDelete?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			setOpenHandleConfirm(false);
			getRecordAttachments();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getRecord();
		getRecordAttachments();
	}, []);

	const deleteRecord = async () => {
		setLoading(true);
		try {
			await MedicalAPI.deleteRecord(recordData?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/medical/records");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const handleInjuryDelete = async (item) => {
		setLoading(true);
		try {
			await MedicalAPI.injuryDelete(recordData?.id, item.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const mainConfiguration = [
		{
			value: recordData?.playerData?.firstName,
			label: "Nome",
			size: "col-6 col-lg-4",
		},
		{
			value: recordData?.playerData?.lastName,
			label: "Cognome",
			size: "col-6 col-lg-4",
		},
		{
			value: recordData?.injuriesIllness?.length,
			label: "N° infortuni/malattie",
			size: "col-6 col-lg-4",
		},
	];

	const columns = [
		{
			label: "Inizio",
			key: "startDate",
			customRender: (item) => {
				return moment(item.startDate).format(dateFormat);
			},
			align: "center",
			medium: true,
		},
		{
			label: "Fine",
			key: "endDate",
			customRender: (item) => {
				return item.endDate && moment(item.endDate).year() > 1900
					? moment(item.endDate).format(dateFormat)
					: "---";
			},
			align: "center",
			medium: true,
		},
		{
			label: "Diagnosi",
			key: "diagnosis",
		},
		{
			label: "Causa",
			key: "cause",
		},
		{
			label: "Tipologia",
			key: "injuryType",
		},
		{
			label: "Dimissione medica",
			key: "medicalDischargeDate",
			customRender: (item) => {
				return item.medicalDischargeDate
					? moment(item.medicalDischargeDate).format(dateFormat)
					: "---";
			},
			align: "center",
			medium: true,
		},
	];

	const illnessColumns = [
		{
			label: "Inizio",
			key: "startDate",
			customRender: (item) => {
				return moment(item.startDate).format(dateFormat);
			},
			align: "center",
			medium: true,
		},
		{
			label: "Fine",
			key: "endDate",
			customRender: (item) => {
				return item.endDate && moment(item.endDate).year() > 1900
					? moment(item.endDate).format(dateFormat)
					: "---";
			},
			align: "center",
			medium: true,
		},
		{
			label: "Diagnosi",
			key: "diagnosis",
		},
		{
			label: "Dimissione medica",
			key: "medicalDischargeDate",
			customRender: (item) => {
				return item.medicalDischargeDate
					? moment(item.medicalDischargeDate).format(dateFormat)
					: "---";
			},
			align: "center",
			medium: true,
		},
	];

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate("/medical/records"),
			color: "info",
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => {
				setEntityToDelete({type: "record", id});
				setOpenHandleConfirm(true);
			},
			color: "error",
		},
	];

	const listConfiguration = {
		data: recordData?.injuriesIllness?.filter((r) => !r.recordType),
		actions: [
			{
				icon: <DeleteIcon />,
				variant: "outlined",
				title: "Elimina",
				onClick: handleInjuryDelete,
				color: "error",
			},
		],
	};

	const illnessListConfiguration = {
		data: recordData?.injuriesIllness?.filter((r) => r.recordType),
		actions: [
			{
				icon: <DeleteIcon />,
				variant: "outlined",
				title: "Elimina",
				onClick: handleInjuryDelete,
				color: "error",
			},
		],
	};

	const breadcrumbsElements = [
		{label: "Passaporti medici", path: "/medical/records"},
		{label: "/"},
	];

	const recordDetails = (
		<>
			<div className="d-flex align-items-center justify-content-end">
				<Button
					variant="contained"
					onClick={() => {
						navigate("/medical/records/" + recordData?.id + "/injuries/new");
					}}
					size="small"
				>
					<div className="d-flex align-items-center">
						<div>Aggiungi</div>
					</div>
				</Button>
			</div>
			<div className="row no-margin">
				<div className="col-sm-12 padding-small">
					<CardsContainer
						tableComponent={
							<CardsTable
								title="Infortuni"
								fullWidth
								fullHeight={false}
								configurationColumns={columns}
								data={recordData?.injuriesIllness?.filter((r) => !r.recordType)}
								handleDelete={handleInjuryDelete}
								handleEdit={(item) => {
									navigate(
										"/medical/records/" +
											recordData?.id +
											"/injuries/" +
											item.id +
											"/edit",
										{state: item},
									);
								}}
							/>
						}
						listComponent={
							<div className="d-flex flex-column">
								<div style={{fontWeight: "bold", fontSize: "16px"}}>
									Infortuni
								</div>
								<CardsList
									title="Infortuni"
									type="medical"
									listConfiguration={listConfiguration}
								/>
							</div>
						}
					/>
				</div>
			</div>
			<div className="row no-margin mt-3">
				<div className="col-sm-12 padding-small">
					<CardsContainer
						tableComponent={
							<CardsTable
								title="Malattie"
								fullWidth
								fullHeight={false}
								configurationColumns={illnessColumns}
								data={recordData?.injuriesIllness?.filter((r) => r.recordType)}
								handleDelete={handleInjuryDelete}
								handleEdit={(item) => {
									navigate(
										"/medical/records/" +
											recordData?.id +
											"/injuries/" +
											item.id +
											"/edit",
										{state: item},
									);
								}}
							/>
						}
						listComponent={
							<div className="d-flex flex-column">
								<div style={{fontWeight: "bold", fontSize: "16px"}}>
									Malattie
								</div>
								<CardsList
									type="medical"
									listConfiguration={illnessListConfiguration}
								/>
							</div>
						}
					/>
				</div>
			</div>
			<div className="col-sm-12 padding-small mt-2">
				<VLFCard
					title="Allegati"
					content={
						<AttachmentsContainer
							entityId={id}
							entityType="medical_record"
							attachments={attachments}
							handleAdd={() => getRecordAttachments()}
							handleDownload={getAttachment}
							handleDelete={(id) => {
								setEntityToDelete({type: "attachment", id});
								setOpenHandleConfirm(true);
							}}
							handleNotification={handleNotification}
						/>
					}
				/>
			</div>
		</>
	);

	if (onlyDetails) {
		return recordDetails;
	}

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={
					recordData
						? (recordData?.playerData?.firstName || "") +
							" " +
							(recordData?.playerData?.lastName || "")
						: ""
				}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-2">
										<div className="image-container generic">
											<img
												src={require("@/assets/images/manager/player.png")}
												alt=""
											/>
										</div>
									</div>
									<div className="col-sm-12 col-md-10">
										<ViewField configuration={mainConfiguration} />
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
				<div className="mt-3">{recordDetails}</div>
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => {
					switch (entityToDelete?.type) {
						case "record":
							return deleteRecord();
						case "attachment":
							return deleteAttachment();
						default:
							return;
					}
				}}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default MedicalRecordPage;
