import * as React from "react";
import moment from "moment";

import {dateFormat, valuationAnalysisOption} from "@/utils/constants";

import "./index.scss";

export default function ScoutReportCard(props) {
	const {element} = props;

	const getAlertSeverity = (valuation) => {
		switch (valuation) {
			case 0:
				return {severity: "success", message: "Idoneo", value: "Idoneo"};
			case 1:
				return {severity: "error", message: "Non Idoneo", value: "Non Idoneo"};
			case 2:
				return {
					severity: "warning",
					message: "Da seguire",
					value: "Da seguire",
				};
			case -1:
				return {severity: null, message: null, value: null};
			default:
				return {severity: "info", message: "Nessuna valutazione", value: "N/A"};
		}
	};

	return (
		<div className="report-card-container">
			<div className="report-card-header">
				<div className="top-left">
					{moment(element.reportDate).format(dateFormat)}
				</div>
				<div className="top-right">{element.signalerName}</div>
			</div>
			<div className="report-card-info">
				<div className="info-title">
					{element.prospectPlayer?.firstName +
						" " +
						element.prospectPlayer?.lastName}
				</div>
				<div className="info-subtitle">
					{moment(element.prospectPlayer?.dateOfBirth).format(dateFormat)}
				</div>
			</div>
			<div className="separator"></div>
			<div className="report-card-preview">
				<div className="report-type">
					<img src={require("@/assets/images/coach/match.png")} alt="" />
					<div className="type-info">{element.matchObserved}</div>
				</div>
				<div
					className={
						"severity " + getAlertSeverity(element?.valuation).severity
					}
				>
					{getAlertSeverity(element?.valuation).message || "---"}
				</div>
			</div>
			<div className="d-flex align-items-center justify-content-center gap-1 flex-wrap">
				{element.deepening?.split(",")?.map((d, k) => {
					const current = valuationAnalysisOption.filter(
						(o) => o.value === d,
					)?.[0];
					return (
						<div key={k} className={"severity" + (current ? " deepening" : "")}>
							{current?.label || "---"}
						</div>
					);
				})}
				{!element.deepening?.split(",")?.length && "---"}
			</div>
		</div>
	);
}
