import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFSelectNew from "@/components/Input/SelectNew";

import {UserAPI} from "@/api/user";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {SUCCESSFUL_OPERATION, ERROR_OPERATION} from "@/utils/constants";
import {checkRequiredFields, getTextField} from "@/utils/functions";
import {usePlayers, useStaffs, useUserRoles} from "@/utils/hooks";

const HandleUserPage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const {players} = usePlayers({
		setLoading: false,
		handleNotification,
		filtered: false,
	});
	const {staffs} = useStaffs({setLoading: false, handleNotification});
	const {userRoles} = useUserRoles({setLoading: false, handleNotification});

	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState({});

	const getUser = async () => {
		setLoading(true);
		try {
			const response = await UserAPI.getById(id);
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit) {
			getUser();
		}
	}, [edit]);

	const getRequiredFields = () => {
		return ["userName", "password", "email", "role"];
	};

	const saveUser = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);

		if (!errors.length) {
			setLoading(true);

			let body = Object.assign({}, fields);
			body.roleId = body?.role?.value || undefined;
			body.staffId = body?.staff?.value || undefined;
			body.playerId = body?.player?.value || undefined;

			try {
				const response = edit
					? await UserAPI.update(id, body)
					: await UserAPI.create(body);
				setTimeout(() => {
					navigate("/settings/users/" + response.userName);
					// navigate(`/settings/users/${response?.userName || id}`);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveUser();
	};

	const goBack = () => {
		if (edit) {
			navigate("/settings/users/" + id);
		} else {
			navigate("/settings/users");
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [{label: "Utenti", path: "/settings/users"}];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: fields ? fields.name : "",
				path: "/settings/users/" + id,
			},
		);
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="row no-margin">
				<div className="col-sm-12 padding-small">
					<VLFCard
						title="Informazioni principali"
						content={
							<div className="row">
								<div className="col-sm-12 col-md-6">
									{getTextField({
										fieldKey: "userName",
										label: "Username",
										required: true,
										values: fields,
										setValues: setFields,
									})}
								</div>
								<div className="col-sm-12 col-md-6">
									{getTextField({
										fieldKey: "password",
										label: "Password",
										type: "password",
										required: true,
										values: fields,
										setValues: setFields,
									})}
								</div>
								<div className="col-sm-12 col-md-6">
									{getTextField({
										fieldKey: "email",
										label: "Email",
										required: true,
										values: fields,
										setValues: setFields,
									})}
								</div>
								<div className="col-sm-12 col-md-6">
									{getTextField({
										fieldKey: "telephone",
										label: "Telefono",
										type: "phone",
										values: fields,
										setValues: setFields,
									})}
								</div>
								<div className="col-sm-12 col-md-6">
									<VLFSelectNew
										options={userRoles.map((ur) => {
											return {
												value: ur.id,
												label: ur.name,
											};
										})}
										value={fields.role}
										onChange={(newValue) => {
											setFields({...fields, role: newValue});
										}}
										label="Ruolo"
										required
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<VLFSelectNew
										options={staffs.map((s) => {
											return {
												value: s.id,
												label:
													s.firstName + " " + s.lastName + " (" + s.role + ")",
											};
										})}
										value={fields.staff}
										onChange={(newValue) => {
											setFields({...fields, player: null, staff: newValue});
										}}
										label="Staff"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<VLFSelectNew
										options={players.map((p) => {
											return {
												value: p.id,
												label:
													p.firstName +
													" " +
													p.lastName +
													" (" +
													p.category +
													")",
											};
										})}
										value={fields.player}
										onChange={(newValue) => {
											setFields({...fields, staff: null, player: newValue});
										}}
										label="Portiere"
									/>
								</div>
							</div>
						}
						openable={false}
					/>
				</div>
			</div>
		</Page>
	);
};

export default HandleUserPage;
