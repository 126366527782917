import api from "./configuration";
import apiUrl from "./url";

const USE_AUTH_INTERCEPTOR = true;

export const TrainingAPI = {
	list: async (filtersData) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Trainings",
			params: filtersData,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getById: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Trainings/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	create: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Trainings",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	update: async (id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Trainings/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	delete: async (id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Trainings/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getDetails: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Trainings/" + id + "/details",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createDetail: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Trainings/details",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updateDetail: async (id, idDetail, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Trainings/" + id + id + "/details/" + idDetail,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteDetail: async (id, idDetail) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Trainings/" + id + id + "/details/" + idDetail,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createMeasurement: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Trainings/rpe",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updateMeasurement: async (trainingId, id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Trainings/" + trainingId + "/rpe/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteMeasurement: async (id, measurementId) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Trainings/" + id + "/rpe/" + measurementId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createAttendance: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Trainings/convocations",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteAttendance: async (id, attendanceId) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Trainings/" + id + "/convocations/" + attendanceId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	updateAttendance: async (trainingId, id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Trainings/" + trainingId + "/convocations/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
};
