import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import MainActions from "@/components/MainActions";
import Breadcrumbs from "@/components/Breadcrumbs";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import CardsTable from "@/components/CardsTable";
import HandlePhysicHeightInfo from "@/pages/Player/Modals/handlePhysicHeightInfo";
import HandlePhysicWeightInfo from "@/pages/Player/Modals/handlePhysicWeightInfo";
import HandleContactModal from "@/pages/Player/Modals/handleContact";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {PlayerAPI} from "@/api/player";

import {
	dateFormat,
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
} from "@/utils/constants";

import "../Player/index.scss";

function PlayerProfilePage(props) {
	const {handleNotification} = props;
	const {id} = useParams();
	const navigate = useNavigate();

	const clubId = useSelector((state) => state.user?.clubId);
	const userId = useSelector((state) => state.user?.userId);

	const [loading, setLoading] = useState(false);
	const [playerData, setPlayerData] = useState({});

	const [openPhysicInfoWeight, setOpenPhysicInfoWeight] = useState(false);
	const [openPhysicHeightInfo, setOpenPhysicHeightInfo] = useState(false);
	const [openContact, setOpenContact] = useState(false);
	const [initialData, setInitialData] = useState();

	const getPlayer = async () => {
		setLoading(true);
		try {
			const response = await PlayerAPI.getById(id);
			setPlayerData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getPlayer();
	}, []);

	const mainConfiguration = [
		{
			value: playerData?.firstName,
			label: "Nome",
			size: "col-6 col-lg-3",
		},
		{
			value: playerData?.lastName,
			label: "Cognome",
			size: "col-6 col-lg-3",
		},
		{
			value: playerData?.dateOfBirth
				? moment(playerData?.dateOfBirth).format(dateFormat)
				: null,
			label: "Data di nascita",
			size: "col-6 col-lg-3",
		},
		{
			value: playerData?.placeOfBirth,
			label: "Luogo di nascita",
			size: "col-6 col-lg-3",
		},
		// {
		// 	value: playerData?.gender,
		// 	label: "Genere",
		// 	size: "col-6 col-lg-3",
		// },
		{
			value: playerData?.address,
			label: "Indirizzo",
			size: "col-6 col-lg-3",
		},
		{
			value: playerData?.nationality,
			label: "Nazionalità",
			size: "col-6 col-lg-3",
		},
		{
			value: playerData?.email,
			label: "Email",
			size: "col-6 col-lg-3",
		},
		{
			value: playerData?.telephone,
			label: "Telefono",
			size: "col-6 col-lg-3",
		},
	];

	const teamConfiguration = [
		{
			value: "U.C. Sampdoria",
			label: "Società",
			size: "col-6 col-lg-3",
		},
		{
			value: playerData?.playerCategoryName,
			label: "Categoria",
			size: "col-6 col-lg-3",
		},
		{
			value: playerData?.favoriteHand,
			label: "Mano",
			size: "col-6 col-lg-3",
		},
		{
			value: playerData?.favoriteFoot,
			label: "Piede",
			size: "col-6 col-lg-3",
		},
	];

	const physicalHeightColumns = [
		{
			label: "Data",
			key: "date",
			customRender: (item) => {
				return moment(item.date).format(dateFormat);
			},
		},
		{
			label: "Altezza",
			key: "height",
			customRender: (item) => {
				return item.height + " cm";
			},
		},
	];
	const physicalWeightColumns = [
		{
			label: "Data",
			key: "date",
			customRender: (item) => {
				return moment(item.date).format(dateFormat);
			},
		},
		{
			label: "Peso",
			key: "weight",
			customRender: (item) => {
				return item.weight + " Kg";
			},
		},
	];

	const contactsColumns = [
		{label: "Nome", key: "name"},
		{label: "Email", key: "email"},
		{label: "Telefono", key: "telephone"},
		{label: "Parentela", key: "relation"},
	];

	const handleClose = () => {
		setOpenPhysicInfoWeight(false);
		setOpenPhysicHeightInfo(false);
		setOpenContact(false);
		setInitialData();
	};

	const handleContact = async (data, id) => {
		setLoading(true);
		try {
			const body = {
				playerId: playerData?.id,
				clubId,
				userId,
				contactRole: data?.contactRole || "",
				...data,
			};
			id
				? await PlayerAPI.updateContact(playerData?.id, id, body)
				: await PlayerAPI.createContact(body);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayer();
			handleClose();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const deleteContact = async (id) => {
		setLoading(true);
		try {
			await PlayerAPI.deleteContact(playerData?.id, id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayer();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const handlePhysicInfo = async (data, id) => {
		setLoading(true);
		try {
			const body = {
				playerId: playerData?.id,
				clubId,
				userId,
				...data,
				height: !data.height ? "0" : data.height,
				date: moment(data.date).format("YYYY-MM-DD"),
			};
			id
				? await PlayerAPI.updateGrowth(playerData?.id, id, body)
				: await PlayerAPI.createGrowth(body);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayer();
			handleClose();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const deletePhysicInfo = async (id) => {
		setLoading(true);
		try {
			await PlayerAPI.deleteGrowth(playerData?.id, id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayer();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate("/"),
			color: "info",
		},
	];

	const breadcrumbsElements = [{label: "Profilo", path: "/"}, {label: "/"}];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={
					playerData
						? (playerData.firstName || "") + " " + (playerData.lastName || "")
						: ""
				}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin col-container">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-2">
										<div className="image-container generic">
											<img
												src={require("@/assets/images/manager/player.png")}
												alt=""
											/>
										</div>
									</div>
									<div className="col-sm-12 col-md-10">
										<ViewField configuration={mainConfiguration} />
									</div>
								</div>
							}
						/>
					</div>
					<div className="col-sm-12 mt-2 padding-small">
						<VLFCard
							title={
								<div className="d-flex align-items-center justify-content-between w-100">
									<div>Altre informazioni</div>
									{playerData?.status && playerData?.status !== "Interno" && (
										<div className="player-status">{playerData?.status}</div>
									)}
								</div>
							}
							content={<ViewField configuration={teamConfiguration} />}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2 col-container">
					<div className="col-sm-12 padding-small">
						<div className="f-bold" style={{fontSize: "16px"}}>
							Dati antropometrici
						</div>
					</div>
					<div className="col-12 col-lg-6 padding-small">
						<CardsTable
							// title="Altezza"
							fullHeight={false}
							configurationColumns={physicalHeightColumns}
							data={playerData?.playersGrowth?.filter((elem) => elem.height)}
							defaultSortedField="date"
							defaultSortedOrder="descend"
							showModes
							chartData={{
								type: "line",
								title: "Altezza",
								value: "height",
								label: "Altezza",
							}}
							actions={[
								{
									icon: <ControlPointIcon />,
									onClick: () => setOpenPhysicHeightInfo(true),
								},
							]}
							rowActions
							handleEdit={(item) => {
								setInitialData(Object.assign({}, item));
								setOpenPhysicHeightInfo(true);
							}}
							handleDelete={(item) => {
								deletePhysicInfo(item.id);
							}}
						/>
					</div>
					<div className="col-12 col-lg-6 padding-small">
						<CardsTable
							// title="Peso"
							fullHeight={false}
							configurationColumns={physicalWeightColumns}
							data={playerData?.playersGrowth?.filter((elem) => elem.weight)}
							defaultSortedField="date"
							defaultSortedOrder="descend"
							showModes
							chartData={{
								type: "bar",
								title: "Peso",
								value: "weight",
								label: "Peso",
							}}
							actions={[
								{
									icon: <ControlPointIcon />,
									onClick: () => setOpenPhysicInfoWeight(true),
								},
							]}
							rowActions
							handleEdit={(item) => {
								setInitialData(Object.assign({}, item));
								setOpenPhysicInfoWeight(true);
							}}
							handleDelete={(item) => {
								deletePhysicInfo(item.id);
							}}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<CardsTable
							title="Contatti"
							fullHeight={false}
							configurationColumns={contactsColumns}
							data={playerData?.playersContacts}
							actions={[
								{
									icon: <ControlPointIcon />,
									onClick: () => setOpenContact(true),
								},
							]}
							rowActions
							handleEdit={(item) => {
								setInitialData(Object.assign({}, item));
								setOpenContact(true);
							}}
							handleDelete={(item) => {
								deleteContact(item.id);
							}}
						/>
					</div>
				</div>
				{openPhysicHeightInfo ? (
					<HandlePhysicHeightInfo
						open={openPhysicHeightInfo}
						confirm={handlePhysicInfo}
						close={handleClose}
						initialData={initialData}
					/>
				) : null}
				{openPhysicInfoWeight ? (
					<HandlePhysicWeightInfo
						open={openPhysicInfoWeight}
						confirm={handlePhysicInfo}
						close={handleClose}
						initialData={initialData}
					/>
				) : null}
				{openContact ? (
					<HandleContactModal
						open={openContact}
						confirm={handleContact}
						close={handleClose}
						initialData={initialData}
					/>
				) : null}
			</div>
		</Page>
	);
}

export default PlayerProfilePage;
