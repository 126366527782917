import React from "react";

import Button from "@mui/material/Button";

import "./index.scss";

function MainActions(props) {
	const {actions} = props;

	return (
		<div className="main-actions-container">
			{actions.map((action, key) => {
				return (
					<div key={key} className="main-action">
						<Button
							variant={action.variant || "contained"}
							onClick={action.onClick}
							disabled={action.disabled}
							color={action.color || "blue"}
							size="small"
						>
							<div className="action-content d-flex align-items-center">
								{action.icon && <div>{action.icon}</div>}
								{action.label && (
									<div className="action-label">{action.label}</div>
								)}
							</div>
						</Button>
					</div>
				);
			})}
		</div>
	);
}

export default MainActions;
