import api from "./configuration";
import apiUrl from "./url";

const USE_AUTH_INTERCEPTOR = true;

export const ScoutReportAPI = {
	list: async (params) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/GoalkeepersReports",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params,
		});

		return response.data;
	},
	create: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/GoalkeepersReports",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	getById: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/GoalkeepersReports/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getByMatchId: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/GoalkeepersReports/Matches/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	delete: async (id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/GoalkeepersReports/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	update: async (id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/GoalkeepersReports/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	getByPlayerId: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/GoalkeepersReports/ProspectPlayer/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
};
