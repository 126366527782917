import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsList from "@/components/CardsList";

import {PlaylistAPI} from "@/api/playlist";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";

import {ERROR_OPERATION, playlistOptions} from "@/utils/constants";
import {usePlayers} from "@/utils/hooks";

import "./index.scss";

function VideosPage(props) {
	const {handleNotification} = props;
	const navigate = useNavigate();

	const type = new URLSearchParams(useLocation().search).get("type");

	const [loading, setLoading] = useState(false);
	const [playlists, setPlaylists] = useState([]);
	const [filtersData, setFiltersData] = useState(
		JSON.parse(sessionStorage.getItem("playlists_filters")) || {},
	);

	const {players} = usePlayers({
		setLoading: false,
		handleNotification,
	});

	const filters = [
		{
			key: "search",
			label: "Nome",
			value: filtersData?.search,
			setValue: (value) => {
				sessionStorage.setItem(
					"playlists_filters",
					JSON.stringify({search: value}),
				);
				setFiltersData({...filtersData, search: value});
			},
		},
	];

	const getPlaylists = async () => {
		setLoading(true);
		try {
			const response = await PlaylistAPI.list();
			setPlaylists(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getPlaylists();
	}, [filtersData]);

	const getTypesConfiguration = () => {
		return {
			data: playlistOptions.map((po) => {
				po.image = require("@/assets/images/manager/video.png");
				return po;
			}),
			onClick: (item) => navigate("/video?type=" + item.value),
		};
	};

	const getListConfiguration = () => {
		return {
			data: playlists
				.filter((p) => p.playlistType === type)
				?.map((p) => {
					p.player = players?.filter((pl) => pl.id === p.playerId)?.[0];
					return p;
				}),
			onClick: (video) =>
				navigate("/video/" + video.id + (type ? "?type=" + type : "")),
		};
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate("/video"),
			color: "info",
		},
		{
			icon: <AddIcon />,
			onClick: () => navigate("/video/new" + (type ? "?type=" + type : "")),
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				actions={<MainActions actions={!type ? [] : mainActions} />}
			/>
			{!type ? (
				<CardsList
					type="general"
					listConfiguration={getTypesConfiguration()}
					defaultSortedField="label"
					defaultSortedOrder="ascend"
				/>
			) : (
				<CardsList
					type="video"
					breadcrumbs
					filtersData={filters}
					listConfiguration={getListConfiguration()}
					defaultSortedField="name"
					defaultSortedOrder="ascend"
				/>
			)}
		</Page>
	);
}

export default VideosPage;
