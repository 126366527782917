import * as React from "react";

import "./index.scss";

export default function GeneralCard(props) {
	const {element} = props;

	return (
		<div className="general-card-container">
			<div className="general-card-info">
				<div className="card-image">
					<img src={element.image} alt="" />
				</div>
				<div className="info-title">{element.label}</div>
			</div>
		</div>
	);
}
