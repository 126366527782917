import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";
import VLFModal from "@/components/Modal";

import {CategoryAPI} from "@/api/category";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {SUCCESSFUL_OPERATION, ERROR_OPERATION} from "@/utils/constants";

import "./index.scss";

function CategoriesPage(props) {
	const {handleNotification} = props;

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [categories, setCategories] = useState([]);

	const [id, setId] = useState();
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);

	const getCategories = async () => {
		setLoading(true);
		try {
			const response = await CategoryAPI.list();
			setCategories(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const deleteCategory = async () => {
		setLoading(true);
		try {
			await CategoryAPI.delete(id);
			getCategories();
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	useEffect(() => {
		getCategories();
	}, []);

	const listConfiguration = {
		data: categories,
		imageUrl: () => require("@/assets/images/manager/category.png"),
		title: (category) => category.name,
		actions: [
			{
				icon: <EditIcon />,
				variant: "outlined",
				title: "Modifica",
				onClick: (category) =>
					navigate("/settings/categories/" + category.id + "/edit"),
			},
			{
				icon: <DeleteIcon />,
				variant: "outlined",
				title: "Elimina",
				onClick: (category) => {
					setId(category.id);
					setOpenHandleConfirm(true);
				},
				color: "error",
			},
		],
	};

	const columns = [
		{
			key: "logo",
			small: true,
			customRender: () => {
				return (
					<img
						className="image"
						src={require("@/assets/images/manager/category.png")}
						alt=""
					/>
				);
			},
		},
		{label: "Nome", key: "name", align: "start"},
		{
			label: "Attività di base",
			key: "basicActivities",
			customRender: (item) => {
				return <div className={"cell" + (item.basicActivities ? " on" : "")} />;
			},
			small: true,
		},
	];

	const headerActions = [
		{
			icon: <AddIcon />,
			title: "Aggiungi",
			onClick: () => navigate("/settings/categories/new"),
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={headerActions} />} />
			<CardsContainer
				tableComponent={
					<CardsTable
						breadcrumbs
						configurationColumns={columns}
						data={categories}
						fullWidth
						rowActions
						handleEdit={(item) =>
							navigate("/settings/categories/" + item.id + "/edit")
						}
						handleDelete={(item) => {
							setId(item.id);
							setOpenHandleConfirm(true);
						}}
						defaultSortedField="name"
					/>
				}
				listComponent={
					<CardsList
						type="category"
						breadcrumbs
						listConfiguration={listConfiguration}
					/>
				}
			/>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={deleteCategory}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default CategoriesPage;
