import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import VLFCard from "@/components/Card";
import MainActions from "@/components/MainActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import {CategoryAPI} from "@/api/category";

import ArrowBack from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {checkRequiredFields, getTextField} from "@/utils/functions";
import {SUCCESSFUL_OPERATION, ERROR_OPERATION} from "@/utils/constants";

import "./index.scss";

const HandleCategoryPage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({});

	const getCategory = async () => {
		setLoading(true);
		try {
			const response = await CategoryAPI.getById(id);
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const saveCategory = async () => {
		const requiredFields = ["name"];
		const errors = checkRequiredFields(requiredFields, fields);
		setErrors(errors);

		if (!errors.length) {
			let body = Object.assign({}, fields);
			setLoading(true);
			try {
				edit
					? await CategoryAPI.update(id, body)
					: await CategoryAPI.create(body);
				navigate("/settings/categories");
				handleNotification(SUCCESSFUL_OPERATION, "success");
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		if (edit) {
			getCategory();
		}
	}, [edit]);

	const handleSubmit = (event) => {
		event.preventDefault();
		saveCategory();
	};

	const goBack = () => {
		navigate("/settings/categories");
	};

	const mainActions = [
		{icon: <ArrowBack />, title: "Indietro", onClick: goBack, color: "info"},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
		},
	];

	const breadcrumbsElements = [
		{label: "Categorie", path: "/settings/categories"},
	];

	const defaultTextField = {
		values: fields,
		setValues: setFields,
		errors,
		setErrors,
	};

	return (
		<Page>
			{loading ? (
				<Loading />
			) : (
				<>
					<Breadcrumbs
						elements={breadcrumbsElements}
						actions={<MainActions actions={mainActions} />}
					/>
					<VLFCard
						title="Informazioni principali"
						content={
							<>
								<div className="row no-margin">
									<div className="col-12 col-md-6 padding-small">
										{getTextField({
											...defaultTextField,
											fieldKey: "name",
											label: "Nome",
											required: true,
										})}
									</div>
									<div className="col-12 col-md-6">
										<div className="field-label-container">
											<div className="field-label">Tipologia</div>
										</div>
										<FormControlLabel
											control={
												<Checkbox
													checked={fields?.basicActivities || false}
													onChange={(event) =>
														setFields({
															...fields,
															basicActivities: event.target.checked,
														})
													}
													inputProps={{"aria-label": "controlled"}}
												/>
											}
											label="Attività di base"
										/>
									</div>
								</div>
							</>
						}
					/>
				</>
			)}
		</Page>
	);
};

export default HandleCategoryPage;
