import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import VLFModal from "@/components/Modal";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {StaffRoleAPI} from "@/api/staff_role";

import {ERROR_OPERATION, SUCCESSFUL_OPERATION} from "@/utils/constants";

function StaffRole(props) {
	const {handleNotification} = props;
	const {id} = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [roleData, setRoleData] = useState({});

	const getRole = async () => {
		setLoading(true);
		try {
			const response = await StaffRoleAPI.getById(id);
			setRoleData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getRole();
	}, []);

	const deleteRole = async () => {
		setLoading(true);
		try {
			await StaffRoleAPI.delete(roleData?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/settings/staff-roles");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const mainConfiguration = [
		{value: roleData?.name, label: "Nome", size: "col-sm-12 col-lg-4"},
	];

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate("/settings/staff-roles"),
			color: "info",
		},
		{
			icon: <EditIcon />,
			title: "Modifica",
			onClick: () =>
				navigate("/settings/staff-roles/" + roleData?.id + "/edit"),
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => setOpenHandleConfirm(true),
			color: "error",
		},
	];

	const breadcrumbsElements = [
		{label: "Ruoli staff", path: "/settings/staff-roles"},
		{label: "/"},
	];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={roleData ? roleData.name || "" : ""}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="row no-margin">
				<div className="col-sm-12 padding-small">
					<VLFCard
						title="Informazioni principali"
						content={
							<div className="row">
								{/* <div className="col-sm-12 col-md-2">
									<div className="image-container generic">
										<img
											src={require("@/assets/images/manager/staff.png")}
											alt=""
										/>
									</div>
								</div> */}
								<div className="col-sm-12">
									<ViewField configuration={mainConfiguration} />
								</div>
							</div>
						}
						openable={false}
					/>
				</div>
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => deleteRole()}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default StaffRole;
