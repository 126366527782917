import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import {MovementTypeAPI} from "@/api/movement_type";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {SUCCESSFUL_OPERATION, ERROR_OPERATION} from "@/utils/constants";
import {
	checkRequiredFields,
	getTextField,
	getTextAreaField,
} from "@/utils/functions";

const HandleMovementPage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({});

	const getMovement = async () => {
		setLoading(true);
		try {
			const response = await MovementTypeAPI.getById(id);
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit) {
			getMovement();
		}
	}, [edit]);

	const getRequiredFields = () => {
		return ["code", "description"];
	};

	const saveMovement = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);
		setErrors(errors);

		if (!errors.length) {
			setLoading(true);

			let body = Object.assign({}, fields);

			try {
				edit
					? await MovementTypeAPI.update(id, body)
					: await MovementTypeAPI.create(body);
				setTimeout(() => {
					navigate("/player-movements");
					// navigate(`/player-movements/${response?.id || id}`);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveMovement();
	};

	const goBack = () => {
		if (edit) {
			navigate("/player-movements/" + id);
		} else {
			navigate("/player-movements");
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [{label: "Movimenti", path: "/player-movements"}];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: fields ? fields.description : "",
				path: "/player-movements/" + id,
			},
		);
	}

	const defaultTextField = {
		values: fields,
		setValues: setFields,
		errors,
		setErrors,
	};

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="row no-margin">
				<div className="col-sm-12 padding-small">
					<VLFCard
						title="Informazioni principali"
						content={
							<div className="row">
								<div className="col-sm-12 col-md-6">
									{getTextField({
										...defaultTextField,
										fieldKey: "code",
										label: "Codice",
										required: true,
									})}
								</div>
								<div className="col-sm-12 col-md-6">
									{getTextField({
										...defaultTextField,
										fieldKey: "description",
										label: "Descrizione",
										required: true,
									})}
								</div>
								<div className="col-sm-12 col-md-6">
									<FormControlLabel
										control={
											<Checkbox
												checked={fields?.updateStatus || false}
												onChange={(event) =>
													setFields({
														...fields,
														updateStatus: event.target.checked,
													})
												}
												inputProps={{"aria-label": "controlled"}}
											/>
										}
										label="Aggiornamento status"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									{getTextField({
										...defaultTextField,
										fieldKey: "status",
										label: "Status",
										disabled: !fields?.updateStatus,
									})}
								</div>
								<div className="col-sm-12 col-md-6">
									<FormControlLabel
										control={
											<Checkbox
												checked={fields?.updateMembershipDate || false}
												onChange={(event) =>
													setFields({
														...fields,
														updateMembershipDate: event.target.checked,
													})
												}
												inputProps={{"aria-label": "controlled"}}
											/>
										}
										label="Aggiornamento tesseramento"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<FormControlLabel
										control={
											<Checkbox
												checked={fields?.defineAnotherTeam || false}
												onChange={(event) =>
													setFields({
														...fields,
														defineAnotherTeam: event.target.checked,
													})
												}
												inputProps={{"aria-label": "controlled"}}
											/>
										}
										label="Aggiornamento squadra"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									{getTextAreaField({
										...defaultTextField,
										fieldKey: "notes",
										label: "Note",
										minRows: 5,
										maxRows: 10,
									})}
								</div>
							</div>
						}
						openable={false}
					/>
				</div>
			</div>
		</Page>
	);
};

export default HandleMovementPage;
