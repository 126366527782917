let apiUrl;

switch (process.env.REACT_APP_ENV) {
	default:
	case "development":
		apiUrl = "https://api.vlfsportsperformance.it";
		break;
	case "production":
		apiUrl = "https://api.goalplayerslab.it";
		break;
}
export default apiUrl;
