import api from "./configuration";
import apiUrl from "./url";

export const AuthorizationAPI = {
	login: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Authentication",
			data,
		});

		return response.data;
	},
	verifyToken: async (token) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Authentication/VerifyToken?token=" + token,
		});

		return response;
	},
	getUserData: async () => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Authentication",
			useAuthInterceptor: true,
		});

		return response.data;
	},
};
