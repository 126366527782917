import * as React from "react";
import moment from "moment";

import {dateFormat} from "@/utils/constants";

import "./index.scss";

export default function VideoCard(props) {
	const {element} = props;

	const getSubtitleList = () => {
		return (
			<div className="categories-list">
				{element.categories.map((c, k) => {
					return (
						<div className="categories-element" key={k}>
							{c?.playersCategory}
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<div className="video-card-container">
			<div className="video-card-header">
				<div className="top-left">
					{moment(element.creationDate).format(dateFormat)}
				</div>
				<div className="top-right">{element.playlistType}</div>
			</div>
			<div className="video-card-info">
				<div className="info-title">{element.name}</div>
				<div className="info-subtitle">
					{element.categories?.length ? (
						getSubtitleList()
					) : (
						<div className="categories-list">
							<div className="categories-element">
								{element.player?.firstName + " " + element.player?.lastName}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="separator"></div>
			<div className="video-card-preview">
				<div className="video-type"></div>
				<div className="video-stats">
					<div className="single-stat">
						<img src={require("@/assets/images/manager/video.png")} alt="" />
						<div>{element.videos?.length || 0}</div>
					</div>
				</div>
			</div>
		</div>
	);
}
