import * as React from "react";

import "./index.scss";

export default function Tabs(props) {
	const {tabs, currentTab, setCurrentTab} = props;

	return (
		<div className="tabs-container">
			<div className="row no-margin">
				<div className="col-sm-12 padding-small">
					<div className="tabs-header-container">
						<div className="tabs-header">
							{tabs
								.filter((t) => !t.hidden)
								.map((t, k) => {
									return (
										<div
											key={t.key}
											className={
												"single-tab" + (currentTab === t.key ? " selected" : "")
											}
											onClick={() => setCurrentTab(t.key)}
										>
											{t.label}
										</div>
									);
								})}
						</div>
					</div>
				</div>
			</div>
			<div className="tab-body">
				{tabs.filter((t) => t.key === currentTab)?.[0]?.render()}
			</div>
		</div>
	);
}
