import {
	LOGIN_SUCCESS,
	LOGOUT,
	USER_SUCCESS,
	SET_SELECTED_MENU_ITEM,
	SET_PLAYER_TAB,
} from "@/actions";

const initialState = {
	isLoggedIn: false,
	token: null,
	user: null,
	selectedMenuItem: "",
	playerTab: "main_info",
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				isLoggedIn: true,
				token: action.payload,
			};
		case LOGOUT:
			return {
				...state,
				isLoggedIn: false,
				token: null,
				user: null,
			};
		case USER_SUCCESS:
			return {
				...state,
				user: action.payload?.user,
				season: action.payload?.season,
			};
		case SET_SELECTED_MENU_ITEM:
			return {
				...state,
				selectedMenuItem: action.payload,
			};
		case SET_PLAYER_TAB:
			return {
				...state,
				playerTab: action.payload,
			};
		default:
			return state;
	}
};

export default authReducer;
