import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsList from "@/components/CardsList";

import {StaffAPI} from "@/api/staff";

import AddIcon from "@mui/icons-material/Add";

import {ERROR_OPERATION} from "@/utils/constants";
// import {useStaffRoles} from "@/utils/hooks";

import "./index.scss";

function StaffsPage(props) {
	const {handleNotification} = props;
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [staffs, setStaffs] = useState([]);
	const [filtersData, setFiltersData] = useState(
		JSON.parse(sessionStorage.getItem("staffs_filters")) || {},
	);

	// const {staffRoles} = useStaffRoles({setLoading: false, handleNotification});

	const filters = [
		{
			key: "search",
			label: "Componente staff",
			value: filtersData?.search,
			setValue: (value) => {
				sessionStorage.setItem(
					"staffs_filters",
					JSON.stringify({search: value}),
				);
				setFiltersData({...filtersData, search: value});
			},
		},
	];

	const getStaffRoleOrder = (role) => {
		let order = 0;
		switch (role) {
			case "Responsabile d'Area":
				order = 1;
				break;
			case "Performance":
				order = 2;
				break;
			case "Match Analyst":
				order = 2;
				break;
			case "Allenatore":
				order = 3;
				break;
			case "Capo Scout":
				order = 4;
				break;
			case "Scout":
				order = 5;
				break;
			default:
				break;
		}

		return order;
	};

	const getStaffs = async () => {
		if (!filtersData) {
			setLoading(true);
		}
		try {
			const response = await StaffAPI.list(filtersData);
			setStaffs(
				response.map((s) => {
					s.order = getStaffRoleOrder(s.role);
					return s;
				}),
			);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (filtersData) {
			getStaffs();
		}
	}, [filtersData]);

	const getListConfiguration = () => {
		return {
			data: staffs.sort((a, b) =>
				a.order > b.order ? 1 : b.order > a.order ? -1 : 0,
			),
			onClick: (staff) => navigate("/settings/staffs/" + staff.id),
			imageUrl: (item) => require("@/assets/images/manager/staff.png"),
			mainTitle: (staff) => staff.role,
			title: (staff) => staff.firstName + " " + staff.lastName,
			text: (staff) => {
				const sortedCategories =
					staff.staffCategories
						?.slice()
						.sort((a, b) =>
							a.playersCategory?.localeCompare(b.playersCategory),
						) || [];

				return sortedCategories.length ? (
					<div className="categories-list">
						{sortedCategories.map((c, k) => {
							return (
								<div className="categories-element" key={k}>
									{c?.playersCategory}
								</div>
							);
						})}
					</div>
				) : undefined;
			},
		};
	};

	const mainActions = [
		{
			icon: <AddIcon />,
			title: "Aggiungi staff",
			onClick: () => navigate("/settings/staffs/new"),
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={mainActions} />} />
			<CardsList
				breadcrumbs
				filtersData={filters}
				type="staff"
				listConfiguration={getListConfiguration()}
			/>
		</Page>
	);
}

export default StaffsPage;
