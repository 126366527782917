import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFTextField from "@/components/Input/TextField";
import ViewField from "@/components/Input/ViewField";
import VLFModal from "@/components/Modal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AttachmentsContainer from "@/components/AttachmentsContainer";

import {ScoutReportAPI} from "@/api/scout_report";
import {AttachmentAPI} from "@/api/attachment";

import {
	aptitudeForRole,
	bodyTypeOptions,
	childTypeOptions,
	communicationTypeOptions,
	dateFormat,
	defaultReportOptions,
	originReportOptions,
	valuationAnalysisOption,
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
} from "@/utils/constants";

import "./index.scss";

function ScoutReportPage(props) {
	const {handleNotification} = props;
	const {id} = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);

	const [reportData, setReportData] = useState({});
	const [attachments, setAttachments] = useState([]);
	const [idAttachment, setIdAttachment] = useState();
	const [openHandleAttachmentConfirm, setOpenHandleAttachmentConfirm] =
		useState(false);

	const getAlertSeverity = (valuation) => {
		switch (valuation) {
			case 0:
				return {severity: "success", message: "Idoneo", value: "Idoneo"};
			case 1:
				return {severity: "error", message: "Non Idoneo", value: "Non Idoneo"};
			case 2:
				return {
					severity: "warning",
					message: "Da seguire",
					value: "Da seguire",
				};
			case -1:
				return {severity: null, message: null, value: null};
			default:
				return {severity: "info", message: "Nessuna valutazione", value: "N/A"};
		}
	};

	const getReport = async () => {
		setLoading(true);
		try {
			const response = await ScoutReportAPI.getById(id);
			const currentOrigin = originReportOptions.find(
				(option) => option.value === response.origin?.toString(),
			);
			if (currentOrigin) {
				response.origin = {
					value: currentOrigin.value,
					label: currentOrigin.label,
				};
			}

			response.deepening = response.deepening?.split(",");

			setReportData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const getReportAttachmentList = async () => {
		try {
			const response = await AttachmentAPI.list("report", id);
			setAttachments(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const getAttachment = async (id) => {
		setLoading(true);
		try {
			const currentFile = attachments.filter((a) => a.id === id)?.[0];
			const response = await AttachmentAPI.getById(id, {
				responseType: "blob",
			});

			// Ottieni l'estensione del file
			const extension = currentFile.filePath
				.split("\\")
				.pop()
				.split(".")
				.pop()
				.toLowerCase();
			let mimeType = "application/octet-stream"; // Default per file sconosciuti

			// eslint-disable-next-line default-case
			switch (extension) {
				case "pdf":
					mimeType = "application/pdf";
					break;
				case "png":
					mimeType = "image/png";
					break;
				case "jpg":
				case "jpeg":
					mimeType = "image/jpeg";
					break;
				case "doc":
					mimeType = "doc";
					break;
			}

			const blob = new Blob([response], {type: mimeType});

			const url = window.URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", currentFile.filePath.split("\\").pop());

			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getReport();
		getReportAttachmentList();
	}, []);

	const deleteReport = async () => {
		setLoading(true);
		try {
			await ScoutReportAPI.delete(reportData?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/scout/reports");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const deleteAttachment = async () => {
		setLoading(true);
		try {
			await AttachmentAPI.delete(idAttachment);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			setOpenHandleAttachmentConfirm(false);
			getReportAttachmentList();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const reportConfiguration = [
		{
			value: reportData?.reportDate
				? moment(reportData?.reportDate).format(dateFormat)
				: null,
			label: "Data",
			size: "col-6 col-lg-4",
		},
		{
			value: reportData?.signalerName,
			label: "Segnalatore",
			size: "col-6 col-lg-4",
		},
		{
			value: reportData?.origin?.label,
			label: "Origine",
			size: "col-6 col-lg-4",
		},
		{
			value: reportData?.matchObserved,
			label: "Partita",
			size: "col-6 col-lg-4",
		},
		{
			value: reportData?.prospectTeam?.name,
			label: "Squadra",
			size: "col-6 col-lg-4",
		},
		{
			value: reportData?.playerCategory?.name,
			label: "Categoria",
			size: "col-6 col-lg-4",
		},
	];

	const reportPlayerInfoConfiguration = [
		{
			value: reportData?.prospectPlayer?.firstName,
			label: "Nome",
			size: "col-6 col-lg-3",
		},
		{
			value: reportData?.prospectPlayer?.lastName,
			label: "Cognome",
			size: "col-6 col-lg-3",
		},
		{
			value: reportData?.prospectPlayer?.dateOfBirth
				? moment(reportData?.prospectPlayer?.dateOfBirth).format(dateFormat)
				: null,
			label: "Data di nascita",
			size: "col-6 col-lg-3",
		},
		{
			value: reportData?.prospectPlayer?.mainFoot,
			label: "Piede principale",
			size: "col-6 col-lg-3",
		},
	];

	const reportInfoConfiguration = [
		{
			value: (
				<div
					className={
						"severity " +
						(getAlertSeverity(reportData?.valuation)?.severity || "")
					}
				>
					{getAlertSeverity(reportData?.valuation)?.message ||
						"Nessuna valutazione"}
				</div>
			),
			label: "Valutazione",
			size: "col-12 col-lg-4",
		},
		{
			value: (
				<div className="d-flex align-items-center flex-wrap gap-1">
					{reportData?.deepening
						?.filter((d) => d)
						.map((d, k) => {
							const current = valuationAnalysisOption.filter(
								(o) => o.value === d,
							)?.[0];
							return (
								<div
									key={k}
									className={"severity" + (current ? " deepening" : "")}
								>
									{current?.label || "---"}
								</div>
							);
						})}
				</div>
			),
			label: "Approfondimento",
			size: "col-12 col-lg-8",
		},
	];

	const reportDetailsConfiguration = [
		{
			value: bodyTypeOptions[reportData?.fatherBodyType]?.label,
			label: "Struttura padre",
			size: "col-6",
		},
		{
			value: bodyTypeOptions[reportData?.motherBodyType]?.label,
			label: "Struttura madre",
			size: "col-6",
		},
		{
			value: bodyTypeOptions[reportData?.bodyType]?.label,
			label: "Struttura apparente",
			size: "col-6",
		},
		{
			value: childTypeOptions[reportData?.childType]?.label,
			label: "Tipologia di bambino",
			size: "col-6",
		},
		{
			value: aptitudeForRole[reportData?.aptitudeForRole]?.label,
			label: "Attitudine al ruolo",
			size: "col-6",
		},
		{
			value: bodyTypeOptions[reportData?.reactivity]?.label,
			label: "Reattività",
			size: "col-6",
		},
		{
			value: communicationTypeOptions[reportData?.verbalCommunication]?.label,
			label: "Comunicazione verbale",
			size: "col-6",
		},
		{
			value: bodyTypeOptions[reportData?.athleticism]?.label,
			label: "Atleticità",
			size: "col-6",
		},
		{
			value: defaultReportOptions[reportData?.participationLevel]?.label,
			label: "Partecipazione al gioco della propria squadra",
			size: "col-6",
		},
		{
			value: (
				<VLFTextField
					value={
						reportData?.notes?.length
							? reportData.notes
							: "Nessuna nota inserita"
					}
					size="small"
					multiline
					minRows={3}
					maxRows={3}
					readOnly
				/>
			),
			label: "Note",
			size: "col-12",
		},
	];

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate("/scout/reports"),
			color: "info",
		},
		{
			icon: <EditIcon />,
			title: "Modifica",
			onClick: () => navigate("/scout/reports/" + reportData?.id + "/edit"),
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => setOpenHandleConfirm(true),
			color: "error",
		},
	];

	const breadcrumbsElements = [
		{label: "Segnalazioni", path: "/scout/reports"},
		{label: "/"},
	];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={
					reportData
						? (reportData?.prospectPlayer?.firstName || "") +
							" " +
							(reportData?.prospectPlayer?.lastName || "")
						: ""
				}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin col-container">
					<div className="col-sm-12 col-lg-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-2">
										<div className="image-container generic">
											<img
												src={require("@/assets/images/manager/evaluation.png")}
												alt=""
											/>
										</div>
									</div>
									<div className="col-sm-12 col-md-10">
										<ViewField configuration={reportConfiguration} />
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni giocatore"
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={reportPlayerInfoConfiguration} />
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni segnalazione"
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={reportInfoConfiguration} />
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Dettagli"
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={reportDetailsConfiguration} />
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin">
					<div className="col-sm-12 padding-small mt-2">
						<VLFCard
							title="Allegati"
							content={
								<AttachmentsContainer
									entityId={id}
									entityType="report"
									attachments={attachments}
									handleAdd={() => getReportAttachmentList()}
									handleDownload={getAttachment}
									handleDelete={(id) => {
										setIdAttachment(id);
										setOpenHandleAttachmentConfirm(true);
									}}
									handleNotification={handleNotification}
								/>
							}
						/>
					</div>
				</div>
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => deleteReport()}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
			<VLFModal
				open={openHandleAttachmentConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => deleteAttachment()}
				close={() => setOpenHandleAttachmentConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default ScoutReportPage;
