import * as React from "react";
import moment from "moment";

import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

import {dateFormat, prospectPlayerStatus} from "@/utils/constants";

import "./index.scss";

export default function ScoutPlayerCard(props) {
	const {element, handleDelete, handleUpdate} = props;

	const status = prospectPlayerStatus?.filter(
		(s) => s.value === element.prospectStatus,
	)?.[0]?.label;

	return (
		<div className="scout-player-card-container">
			<div className="scout-player-card-header">
				<div className="top-left">
					{moment(element.dateOfBirth)?.year() > 1900
						? moment(element.dateOfBirth).format(dateFormat)
						: "---"}
				</div>
				<div className="top-right">
					{handleDelete || handleUpdate ? (
						<div className="card-actions-container">
							{handleUpdate && (
								<div className="action">
									<EditIcon />
								</div>
							)}
							{handleDelete && (
								<Button
									variant={"outlined"}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();

										handleDelete(element.observedId);
									}}
									color={"error"}
									size="small"
								>
									<div className="action-content d-flex align-items-center">
										<CloseIcon />
									</div>
								</Button>
							)}
						</div>
					) : null}
				</div>
			</div>
			<div className="scout-player-card-info">
				<div className="info-title">
					{handleDelete && (
						<img
							src={require("@/assets/images/manager/player.png")}
							alt=""
							style={{marginRight: "5px"}}
						/>
					)}
					<div>{element.firstName + " " + element.lastName}</div>
				</div>
				<div className="info-subtitle">
					{status ? (
						<div
							style={{
								border: "1px solid #002160",
								borderRadius: "10px",
								padding: "0px 10px",
							}}
						>
							{status}
						</div>
					) : (
						"---"
					)}
				</div>
			</div>
		</div>
	);
}
