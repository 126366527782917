import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import VLFModal from "@/components/Modal";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {AthleticTestAPI} from "@/api/athletic_test";

import {
	dateFormat,
	testTypes,
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
} from "@/utils/constants";
import {getCurrentCategory, getCurrentPlayer} from "@/utils/functions";
import {useCategories, usePlayers} from "@/utils/hooks";

import "./index.scss";

function AthleticTestPage(props) {
	const {handleNotification} = props;

	const {id} = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [testData, setTestData] = useState({});

	const {categories} = useCategories({
		setLoading: false,
		handleNotification,
	});
	const {players} = usePlayers({
		setLoading: false,
		handleNotification,
	});

	const getTest = async () => {
		setLoading(true);
		try {
			const response = await AthleticTestAPI.getById(id);
			setTestData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getTest();
	}, []);

	const deleteTest = async () => {
		setLoading(true);
		try {
			await AthleticTestAPI.delete(testData?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/athletic-tests");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const mainConfiguration = [
		{
			value: testData?.testDate
				? moment(testData?.testDate).format(dateFormat)
				: null,
			label: "Data",
			size: "col-6 col-lg-4",
		},
		{
			value: getCurrentPlayer(players, testData),
			label: "Portiere",
			size: "col-6 col-lg-4",
		},
		{
			value: getCurrentCategory(categories, testData),
			label: "Categoria",
			size: "col-6 col-lg-4",
		},
		{
			value: testData?.testType,
			label: "Tipologia",
			size: "col-6 col-lg-4",
		},
		{
			value: testData?.description,
			label: "Descrizione",
			size: "col-6 col-lg-4",
		},
	];

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate(-1),
			color: "info",
		},
		{
			icon: <EditIcon />,
			title: "Modifica",
			onClick: () => navigate("/athletic-tests/" + testData?.id + "/edit"),
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => setOpenHandleConfirm(true),
			color: "error",
		},
	];

	const breadcrumbsElements = [
		{label: "Test atletici", path: "/athletic-tests"},
		{label: "/"},
	];

	const step = testData?.testType === "Spinte" ? 3 : 1;
	const blocks = [];
	const details = testData?.details?.sort((a, b) => {
		let numA = parseInt(a.valueType);
		let numB = parseInt(b.valueType);

		return numA - numB;
	});
	for (let i = 0; i < details?.length; i += step) {
		blocks.push(details.slice(i, i + step));
	}

	const currentType = testTypes.filter(
		(t) => t.value === testData?.testType,
	)?.[0];

	const getCurrentValueType = (e) => {
		return currentType?.params?.filter((p) => p.value === e.valueType)?.[0];
	};

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={
					testData
						? moment(testData?.testDate).format(dateFormat) +
							" " +
							getCurrentPlayer(players, testData)
						: ""
				}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-2">
										<div className="image-container generic">
											<img
												src={
													testData?.testType === "Spinte"
														? require("@/assets/images/coach/jumping.png")
														: require("@/assets/images/coach/running.png")
												}
												alt=""
											/>
										</div>
									</div>
									<div className="col-sm-12 col-md-10">
										<ViewField configuration={mainConfiguration} />
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Parametri"
							content={
								<div className="row">
									{blocks?.map((b, k) => {
										return (
											<div key={k} className="p-col col-12 col-lg-4">
												<div className="parameters-container">
													{b.map((e, k1) => {
														return (
															<div key={k1} className="parameter">
																<div className="p-label">{e.valueType}</div>
																<div className="p-value">
																	{e.value +
																		(getCurrentValueType(e)
																			? " " + getCurrentValueType(e).um
																			: "")}
																</div>
															</div>
														);
													})}
												</div>
											</div>
										);
									})}
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => deleteTest()}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default AthleticTestPage;
