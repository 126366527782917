import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsTable from "@/components/CardsTable";

import AddIcon from "@mui/icons-material/Add";

import {MovementTypeAPI} from "@/api/movement_type";

import {ERROR_OPERATION} from "@/utils/constants";

function MovementsPage(props) {
	const {handleNotification} = props;

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [movements, setMovements] = useState([]);

	const getMovements = async () => {
		setLoading(true);
		try {
			const response = await MovementTypeAPI.list();
			setMovements(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getMovements();
	}, []);

	const columns = [
		{label: "Codice", key: "code", align: "start"},
		{
			label: "Descrizione",
			key: "description",
			align: "start",
		},
		{
			label: "Aggiornamento stato",
			key: "updateStatus",
			align: "center",
			medium: true,
			customRender: (row) => {
				return (
					<div className={"cell" + (row.updateStatus ? " on" : " off")}></div>
				);
			},
		},
		{
			label: "Aggiornamento tesseramento",
			key: "updateMembershipDate",
			align: "center",
			medium: true,
			customRender: (row) => {
				return (
					<div
						className={"cell" + (row.updateMembershipDate ? " on" : " off")}
					></div>
				);
			},
		},
		{
			label: "Cambio squadra",
			key: "defineAnotherTeam",
			align: "center",
			medium: true,
			customRender: (row) => {
				return (
					<div
						className={"cell" + (row.defineAnotherTeam ? " on" : " off")}
					></div>
				);
			},
		},
	];

	const headerActions = [
		{
			icon: <AddIcon />,
			title: "Aggiungi",
			onClick: () => navigate("/player-movements/new"),
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={headerActions} />} />
			<CardsTable
				breadcrumbs
				fullWidth
				configurationColumns={columns}
				data={movements}
				handleClick={(item) => navigate("/player-movements/" + item.id)}
				defaultSortedField="code"
				defaultSortedOrder="ascend"
			/>
		</Page>
	);
}

export default MovementsPage;
