import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import AddIcon from "@mui/icons-material/Add";

import {ProspectAPI} from "@/api/prospect";

import {
	ERROR_OPERATION,
	dateFormat,
	prospectPlayerStatus,
} from "@/utils/constants";

function ScoutPlayersPage(props) {
	const {handleNotification} = props;

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [players, setPlayers] = useState([]);
	const [birthYears, setBirthYears] = useState([]);

	const [filtersData, setFiltersData] = useState(
		JSON.parse(sessionStorage.getItem("prospect_players_filters")) || {},
	);

	const filters = [
		{
			key: "search",
			label: "Portiere",
			value: filtersData?.search || "",
			setValue: (value) => {
				sessionStorage.setItem(
					"prospect_players_filters",
					JSON.stringify({search: value}),
				);
				setFiltersData({...filtersData, search: value});
			},
		},
		{
			key: "status",
			type: "select",
			options: prospectPlayerStatus,
			sorted: false,
			label: "Status",
			clearable: true,
			value: filtersData?.status,
			setValue: (value) => {
				setFiltersData({...filtersData, status: value});
			},
		},
	];

	const getPlayers = async () => {
		setLoading(true);
		try {
			const response = await ProspectAPI.playersList();
			setPlayers(response);

			const years = [];
			response.forEach((p) => {
				let currentYear = moment(p.dateOfBirth).year();
				if (!currentYear) {
					currentYear = "Non specificato";
				}
				if (years.indexOf(currentYear) === -1) {
					years.push(currentYear);
				}
			});
			setBirthYears(years.sort());
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getPlayers();
	}, []);

	let filteredPlayers = !filtersData?.search
		? players
		: players.filter((p) => {
				return (
					p.firstName
						.toLowerCase()
						.indexOf(filtersData?.search.toLowerCase()) !== -1 ||
					p.lastName
						.toLowerCase()
						.indexOf(filtersData?.search.toLowerCase()) !== -1
				);
			});

	filteredPlayers = !filtersData?.status
		? filteredPlayers
		: filteredPlayers.filter(
				(fp) => fp.prospectStatus === filtersData.status.value,
			);

	const groupedPlayers = {};
	birthYears.forEach((c) => {
		const members = filteredPlayers?.filter(
			(p) =>
				(c === "Non specificato" && !p.dateOfBirth) ||
				moment(p.dateOfBirth)?.year() === c,
		);
		groupedPlayers[c] = members;
	});

	const listConfiguration = {
		data: groupedPlayers,
		onClick: (player) => navigate("/scout-players/" + player.id),
	};

	const columns = [
		{label: "Nome", key: "firstName", align: "start"},
		{label: "Cognome", key: "lastName", align: "start"},
		{
			label: "Data di nascita",
			key: "dateOfBirth",
			align: "center",
			small: true,
			customRender: (item) => {
				if (moment(item.dateOfBirth).year() > 1900) {
					return moment(item.dateOfBirth).format(dateFormat);
				}
			},
		},
		{
			label: "Status",
			key: "prospectStatus",
			medium: true,
			customRender: (row) => {
				const status = prospectPlayerStatus?.filter(
					(s) => s.value === row.prospectStatus,
				)?.[0]?.label;

				return status ? (
					<div
						style={{
							border: "1px solid #002160",
							borderRadius: "10px",
							padding: "0px 10px",
						}}
					>
						{status}
					</div>
				) : (
					"---"
				);
			},
		},
	];

	const headerActions = [
		{
			icon: <AddIcon />,
			title: "Aggiungi",
			onClick: () => navigate("/scout-players/new"),
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={headerActions} />} />
			<CardsContainer
				tableComponent={
					<CardsTable
						breadcrumbs
						fullWidth
						filtersData={filters}
						configurationColumns={columns}
						data={filteredPlayers.sort((a, b) => {
							const dateA = new Date(a.dateOfBirth);
							const dateB = new Date(b.dateOfBirth);

							return dateB - dateA;
						})}
						handleClick={(player) => navigate("/scout-players/" + player.id)}
						// defaultSortedField="lastName"
						// defaultSortedOrder="ascend"
					/>
				}
				listComponent={
					<CardsList
						type="scout_player"
						breadcrumbs
						grouped
						filtersData={filters}
						listConfiguration={listConfiguration}
						// defaultSortedField="lastName"
						// defaultSortedOrder="ascend"
					/>
				}
			/>
		</Page>
	);
}

export default ScoutPlayersPage;
