import * as React from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function VLFRadio(props) {
	const {
		name,
		value,
		onChange,
		options,
		label,
		required,
		size,
		rowDirection = true,
	} = props;

	return (
		<FormControl>
			{label && (
				<div className="field-label-container">
					<div className="field-label">{label}</div>
					{required && <div className="field-required">*</div>}
				</div>
			)}
			<RadioGroup
				row={rowDirection}
				name={name}
				value={value}
				onChange={onChange}
			>
				{options.map((option, key) => {
					return (
						<FormControlLabel
							key={key}
							value={option.value}
							control={<Radio size={size} />}
							label={option.label}
						/>
					);
				})}
			</RadioGroup>
		</FormControl>
	);
}
