import React, {useState} from "react";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import "./index.scss";

export default function VLFCard(props) {
	const {
		className,
		variant = "outlined",
		header,
		title,
		content,
		actions,
		openable = true,
		opened = undefined,
		setOpened,
	} = props;

	const [open, setOpen] = useState(opened !== undefined ? opened : true);

	return (
		<Card variant={variant} className={className}>
			{header ? <CardHeader title={header.title} /> : null}
			<CardContent>
				<div className={"card-content" + (open ? " open" : " closed")}>
					<div className="row">
						{title && (
							<div className="col-sm-12">
								<div className="card-title-container d-flex align-items-center justify-content-between w-100">
									<div className="card-title w-100">{title}</div>
									{openable && (
										<div
											className="card-open"
											onClick={() => {
												setOpen(!open);

												if (setOpened) {
													setOpened(!opened);
												}
											}}
										>
											{open ? (
												<KeyboardArrowUpIcon />
											) : (
												<KeyboardArrowDownIcon />
											)}
										</div>
									)}
								</div>
							</div>
						)}
					</div>
					{open && <div className="py-2 card-padded-content">{content}</div>}
				</div>
			</CardContent>

			{actions ? <CardActions>{actions}</CardActions> : null}
		</Card>
	);
}
