import * as React from "react";

import "./index.scss";

export default function CardsContainer(props) {
	const {tableComponent, listComponent} = props;

	return (
		<div className="cards-container">
			<div className="cards-table-container">{tableComponent}</div>
			<div className="cards-list-container">{listComponent}</div>
		</div>
	);
}
