import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import VLFModal from "@/components/Modal";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {ExerciseAPI} from "@/api/exercise";

import {ERROR_OPERATION, SUCCESSFUL_OPERATION} from "@/utils/constants";

function ExercisePage(props) {
	const {handleNotification} = props;
	const {id} = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [exerciseData, setExerciseData] = useState({});

	const getExercise = async () => {
		setLoading(true);
		try {
			const response = await ExerciseAPI.getById(id);
			setExerciseData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getExercise();
	}, []);

	const deleteExercise = async () => {
		setLoading(true);
		try {
			await ExerciseAPI.delete(exerciseData?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/exercises");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const mainConfiguration = [
		{value: exerciseData?.name, label: "Nome", size: "col-12 col-lg-6"},
		{
			value: exerciseData?.exerciseType,
			label: "Tipologia",
			size: "col-12 col-lg-6",
		},
		{
			value: exerciseData?.duration ? exerciseData?.duration + "'" : "---",
			label: "Durata",
			size: "col-12 col-lg-6",
		},
		{
			value: exerciseData?.description,
			label: "Descrizione",
			size: "col-12 col-lg-6",
		},
	];

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate("/exercises"),
			color: "info",
		},
		{
			icon: <EditIcon />,
			title: "Modifica",
			onClick: () => navigate("/exercises/" + exerciseData?.id + "/edit"),
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => setOpenHandleConfirm(true),
			color: "error",
		},
	];

	const breadcrumbsElements = [
		{label: "Esercizi", path: "/exercises"},
		{label: "/"},
	];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={exerciseData ? exerciseData.name : ""}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="row no-margin">
				<div className="col-sm-12 padding-small">
					<VLFCard
						title="Informazioni principali"
						content={
							<div className="row">
								<div className="col-sm-12">
									<ViewField configuration={mainConfiguration} />
								</div>
							</div>
						}
						openable={false}
					/>
				</div>
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => deleteExercise()}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default ExercisePage;
