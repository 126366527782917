import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFTextField from "@/components/Input/TextField";
import VLFDatePicker from "@/components/Input/DatePicker";
import VLFSelectNew from "@/components/Input/SelectNew";

import {TargetAPI} from "@/api/target";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {
	REQUIRED_FIELD,
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

const reachedOptions = [
	{value: "Raggiunto", label: "Raggiunto"},
	{value: "Non raggiunto", label: "Non raggiunto"},
];
const HandleTargetPage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const userId = useSelector((state) => state?.user?.userId);
	const clubId = useSelector((state) => state?.user?.clubId);
	const userName = useSelector((state) => state?.user?.userName);

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: null,
	});
	const formattedCategories = categories?.map((c) => {
		return {
			value: c.id,
			label: c.name,
		};
	});

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({
		targetDate: null,
		playersCategory: null,
		targetDeadline: null,
	});

	const getTarget = async () => {
		setLoading(true);
		try {
			const response = await TargetAPI.getById(id);
			response.targetDeadline =
				moment(response.targetDeadline).year() > 2000
					? response.targetDeadline
					: null;
			response.playersCategory = {
				value: response.playersCategoryId,
				label: formattedCategories?.filter(
					(fc) => fc.value === response.playersCategoryId,
				)?.[0]?.label,
			};
			response.reached =
				response.reached !== undefined && response.reached !== null
					? response.reached
						? reachedOptions[0]
						: reachedOptions[1]
					: undefined;
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit && categories?.length) {
			getTarget();
		}
	}, [edit, categories]);

	const getRequiredFields = () => {
		return ["targetDate", "playersCategory", "description"];
	};

	const saveTarget = async () => {
		try {
			const errors = checkRequiredFields(getRequiredFields(), fields);
			setErrors(errors);

			if (!errors.length) {
				setLoading(true);

				let body = Object.assign({}, fields);

				body = {
					...fields,
					targetDate: fields.targetDate
						? moment(fields.targetDate).format("YYYY-MM-DD")
						: undefined,
					playersCategoryId: fields.playersCategory.value,
					playersCategory: {
						id: fields.playersCategory.value,
						name: fields.playersCategory.label,
					},
					targetDeadline: fields.targetDeadline
						? moment(fields.targetDeadline).format("YYYY-MM-DD")
						: undefined,
					dateReached: fields.reached
						? fields.dateReached
							? moment(fields.dateReached).format("YYYY-MM-DD")
							: moment().format("YYYY-MM-DD")
						: undefined,
					reached: fields.reached?.value === "Raggiunto",
					clubId,
					userId,
					user: {userId, userName},
				};

				const response = edit
					? await TargetAPI.update(id, body)
					: await TargetAPI.create(body);

				setTimeout(() => {
					navigate(`/manager/targets/${response?.id || id}`);
					setLoading(false);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			}
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveTarget();
	};

	const goBack = () => {
		if (edit) {
			navigate("/manager/targets/" + id);
		} else {
			navigate("/manager/targets");
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [{label: "Obiettivi", path: "/manager/targets"}];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: formattedCategories?.filter(
					(fc) => fc.value === fields?.playersCategoryId,
				)?.[0]?.label,
				path: "/manager/targets/" + id,
			},
		);
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-6">
										<VLFDatePicker
											label="Data"
											value={fields.targetDate}
											onChange={(value) => {
												setErrors([]);
												setFields({...fields, targetDate: value});
											}}
											required
											helperText={
												errors.indexOf("targetDate") !== -1
													? REQUIRED_FIELD
													: ""
											}
											error={errors.indexOf("targetDate") !== -1}
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={formattedCategories}
											value={fields.playersCategory}
											onChange={(newValue) => {
												setErrors([]);
												setFields({...fields, playersCategory: newValue});
											}}
											label="Categoria"
											required
											helperText={
												errors.indexOf("playersCategory") !== -1
													? REQUIRED_FIELD
													: ""
											}
											error={errors.indexOf("playersCategory") !== -1}
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFDatePicker
											label="Scadenza"
											value={fields.targetDeadline}
											onChange={(value) => {
												setFields({...fields, targetDeadline: value});
											}}
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFTextField
											value={fields.description || ""}
											onChange={(e) => {
												setErrors([]);
												setFields({...fields, description: e.target.value});
											}}
											label="Descrizione"
											required
											size="small"
											helperText={
												errors.indexOf("description") !== -1
													? REQUIRED_FIELD
													: ""
											}
											error={errors.indexOf("description") !== -1}
											multiline
											minRows={3}
											maxRows={3}
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFDatePicker
											label="Data raggiungimento"
											value={fields.dateReached}
											onChange={(value) => {
												setFields({...fields, dateReached: value});
											}}
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={reachedOptions}
											value={fields.reached}
											onChange={(newValue) => {
												setFields({...fields, reached: newValue});
											}}
											label="Raggiunto"
										/>
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default HandleTargetPage;
