import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFSelectNew from "@/components/Input/SelectNew";

import {MedicalAPI} from "@/api/medical";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
	REQUIRED_FIELD,
} from "@/utils/constants";
import {checkRequiredFields, getTextAreaField} from "@/utils/functions";
import {usePlayers} from "@/utils/hooks";

const HandleMedicalRecordPage = (props) => {
	const {handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const {players} = usePlayers({
		setLoading: false,
		handleNotification,
		onlyInternal: true,
	});

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({});

	const getRequiredFields = () => {
		return ["player"];
	};

	const saveRecord = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);
		setErrors(errors);

		if (!errors.length) {
			setLoading(true);

			let body = Object.assign({}, fields);
			body.playerId = body.player?.value;
			body.notes = body.notes || "";

			delete body.player;

			try {
				const response = await MedicalAPI.recordCreate(body);
				setTimeout(() => {
					navigate(`/medical/records/${response?.id || id}`);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveRecord();
	};

	const goBack = () => {
		navigate("/medical/records");
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [
		{label: "Passaporti medici", path: "/medical/records"},
	];

	const defaultTextField = {
		values: fields,
		setValues: setFields,
		errors,
		setErrors,
	};

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="row no-margin">
				<div className="col-sm-12 padding-small">
					<VLFCard
						title="Informazioni principali"
						content={
							<div className="row">
								<div className="col-sm-12 col-md-6">
									<VLFSelectNew
										options={players.map((p) => {
											return {
												value: p.id,
												label:
													p.firstName +
													" " +
													p.lastName +
													" (" +
													p.category +
													")",
											};
										})}
										value={fields.player || ""}
										onChange={(value) => {
											setErrors([]);
											setFields({...fields, player: value});
										}}
										required
										helperText={
											errors.indexOf("player") !== -1 ? REQUIRED_FIELD : ""
										}
										error={errors.indexOf("player") !== -1}
										label={"Portiere"}
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									{getTextAreaField({
										...defaultTextField,
										fieldKey: "notes",
										label: "Note",
										minRows: 5,
										maxRows: 10,
									})}
								</div>
							</div>
						}
						openable={false}
					/>
				</div>
			</div>
		</Page>
	);
};

export default HandleMedicalRecordPage;
