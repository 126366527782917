import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import ScoutReportsContainer from "@/components/ScoutReportsContainer";

import {ScoutReportAPI} from "@/api/scout_report";

import AddIcon from "@mui/icons-material/Add";

import "./index.scss";

const fieldDateFormat = "YYYY-MM-DD";

const defaultFiltersData = {
	// fromDate: moment().startOf("month").format(fieldDateFormat),
	toDate: moment().endOf("month").format(fieldDateFormat),
};

function ScoutReportsPage() {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [reports, setReports] = useState([]);
	const [filtersData, setFiltersData] = useState(
		// JSON.parse(sessionStorage.getItem("scout_reports_filters")) ||
		defaultFiltersData,
	);

	useEffect(() => {
		if (filtersData) {
			sessionStorage.setItem(
				"scout_reports_filters",
				JSON.stringify(filtersData),
			);
		}
	}, [filtersData]);

	const filters = [
		{
			key: "fromDate",
			type: "date",
			label: "Da",
			customClassName: "col-6 col-sm-4",
			clearable: true,
			value: filtersData?.fromDate
				? moment(filtersData?.fromDate).format(fieldDateFormat)
				: null,
			setValue: (e) => {
				setFiltersData({
					...filtersData,
					fromDate: e ? moment(e).format(fieldDateFormat) : "",
				});
			},
		},
		{
			key: "toDate",
			type: "date",
			label: "A",
			customClassName: "col-6 col-sm-4",
			clearable: false,
			value: moment(filtersData?.toDate).format(fieldDateFormat),
			setValue: (e) => {
				if (e) {
					setFiltersData({
						...filtersData,
						toDate: moment(e).format(fieldDateFormat),
					});
				}
			},
		},
	];

	const getReports = async () => {
		setLoading(true);
		try {
			const response = await ScoutReportAPI.list(filtersData);
			setReports(response);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getReports();
	}, [filtersData]);

	const mainActions = [
		{
			icon: <AddIcon />,
			onClick: () => navigate("/scout/reports/new"),
		},
	];

	return (
		<Page className="scout-reports-container">
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={mainActions} />} />
			<ScoutReportsContainer reports={reports} filters={filters} breadcrumbs />
		</Page>
	);
}

export default ScoutReportsPage;
