import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFDatePicker from "@/components/Input/DatePicker";
import VLFTextField from "@/components/Input/TextField";
import VLFToggleButtonGroup from "@/components/Input/TogleButtonGroup";
import CreateSelectEntity from "@/components/CreateSelectEntity";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VLFSelectNew from "@/components/Input/SelectNew";
import SaveIcon from "@mui/icons-material/Save";

import {MatchAPI} from "@/api/match";
import {ProspectAPI} from "@/api/prospect";
import {ScoutReportAPI} from "@/api/scout_report";

import {
	defaultReportOptions,
	originReportOptions,
	childTypeOptions,
	aptitudeForRole,
	communicationTypeOptions,
	bodyTypeOptions,
	REQUIRED_FIELD,
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
	valuationOption,
	valuationAnalysisOption,
	dateFormat,
} from "@/utils/constants";
import {checkRequiredFields, getTextField} from "@/utils/functions";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

const HandleScoutReportPage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const matchId = new URLSearchParams(useLocation().search).get("matchId");

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [saveOption, setSaveOption] = useState(edit ? "select" : "new");

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: null,
		formatted: true,
	});

	const [fields, setFields] = useState({
		aptitudeForRole: -1,
		participationLevel: -1,
		childType: -1,
		verbalCommunication: -1,
		bodyType: -1,
		athleticism: -1,
		fatherBodyType: -1,
		motherBodyType: -1,
		reactivity: -1,
	});

	const getReport = async () => {
		setLoading(true);
		try {
			const response = await ScoutReportAPI.getById(id);

			const currentOrigin = originReportOptions.find(
				(option) => option.value === response.origin?.toString(),
			);
			if (currentOrigin) {
				response.origin = {
					value: response.origin,
					label: currentOrigin?.label,
				};
			}

			const currentCategory = categories.find(
				(c) => c.value === response.playerCategoryId,
			);
			if (currentCategory) {
				response.playerScoutCategory = {
					value: currentCategory.value,
					label: currentCategory.label,
				};
			}

			if (response.prospectPlayer) {
				response.prospectScoutPlayer = {
					value: response.prospectPlayer?.id,
					label:
						response.prospectPlayer?.firstName +
						" " +
						response.prospectPlayer?.lastName +
						" (" +
						moment(response.prospectPlayer?.dateOfBirth).format(dateFormat) +
						")",
				};
			}

			if (response.prospectTeam) {
				response.prospectScoutTeam = {
					value: response.prospectTeam.id,
					label: response.prospectTeam.name,
				};
			}

			response.deepening = response.deepening?.split(",");

			setFields(response);

			if (matchId) {
				await getMatch();
			}
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const getMatch = async () => {
		try {
			const response = await MatchAPI.getById(matchId);
			setFields({
				...fields,
				matchObserved: response.homeTeam + " - " + response.awayTeam,
			});
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	useEffect(() => {
		if (edit && categories?.length) {
			getReport();
		} else {
			if (matchId) {
				getMatch();
			}
		}
	}, [edit, categories?.length, matchId]);

	const [prospectPlayers, setProspectPlayers] = useState([]);
	const getPlayers = async () => {
		try {
			const response = await ProspectAPI.playersList();
			setProspectPlayers(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const [clubs, setClubs] = useState([]);
	const getClubs = async () => {
		try {
			const response = await ProspectAPI.teamsList();
			setClubs(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	useEffect(() => {
		getPlayers();
		getClubs();
	}, []);

	const getRequiredFields = () => {
		let reqFields = [
			"reportDate",
			"signalerName",
			"prospectScoutTeam",
			"playerScoutCategory",
		];

		if (saveOption === "select") {
			reqFields.push("prospectScoutPlayer");
		} else {
			reqFields.push("lastName");
		}

		if (!edit) {
			if (!matchId) {
				reqFields.push("matchObserved");
			}
		}

		return reqFields;
	};

	const createClub = async (name) => {
		try {
			const response = await ProspectAPI.createTeam({name});
			setFields({
				...fields,
				prospectScoutTeam: {value: response.id, label: response.name},
			});
			getClubs();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const createPlayer = async () => {
		try {
			const body = {
				firstName: fields?.firstName ? fields.firstName : "",
				lastName: fields?.lastName,
				dateOfBirth: moment(fields?.dateOfBirth).format("YYYY-MM-DD"),
				mainFoot: fields?.mainFoot,
			};

			const response = await ProspectAPI.createPlayer(body);
			return response;
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const saveReport = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);
		setErrors(errors);

		if (!errors.length) {
			setLoading(true);

			const body = Object.assign({}, fields);
			body.reportDate = moment(body.reportDate).format("YYYY-MM-DD");
			body.origin = body.origin?.value;
			body.prospectTeamId = fields.prospectScoutTeam?.value;
			body.playerCategoryId = fields.playerScoutCategory?.value;

			if (saveOption === "new") {
				const playerResponse = await createPlayer();
				body.prospectPlayerId = playerResponse?.id;
			} else {
				body.prospectPlayerId = fields.prospectScoutPlayer?.value;
			}

			body.matchId = matchId;
			body.deepening = body.deepening?.join();

			try {
				const reportData = edit
					? await ScoutReportAPI.update(id, body)
					: await ScoutReportAPI.create(body);
				setTimeout(() => {
					navigate("/scout/reports/" + (id || reportData?.id));
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		saveReport();
	};

	const goBack = () => {
		if (edit) {
			navigate("/scout/reports/" + id);
		} else {
			navigate("/scout/reports");
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [{label: "Segnalazioni", path: "/scout/reports"}];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: [
					fields?.prospectPlayer?.firstName,
					fields?.prospectPlayer?.lastName,
				]
					.filter(Boolean)
					.join(" "),
				path: "/scout/reports/" + id,
			},
		);
	}

	const defaultTextField = {
		values: fields,
		setValues: setFields,
		errors,
		setErrors,
	};

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-12 padding-small">
						<VLFCard
							title="Informazioni segnalazione"
							content={
								<div className="row no-margin single-area-container">
									<div className="col-12 col-sm-6 col-xl-4">
										<VLFDatePicker
											label="Data"
											value={fields.reportDate}
											onChange={(value) => {
												setErrors([]);
												setFields({...fields, reportDate: value});
											}}
											required
											format="DD-MM-YYYY"
											size="small"
											helperText={
												errors.indexOf("reportDate") !== -1
													? REQUIRED_FIELD
													: ""
											}
											error={errors.indexOf("reportDate") !== -1}
										/>
									</div>
									<div className="col-12 col-sm-6 col-xl-4">
										{getTextField({
											...defaultTextField,
											fieldKey: "signalerName",
											label: "Segnalatore",
											required: true,
										})}
									</div>
									<div className="col-12 col-sm-6 col-xl-4">
										<VLFSelectNew
											options={originReportOptions}
											value={fields.origin}
											onChange={(event) => {
												setFields({
													...fields,
													origin: {label: event.label, value: event.value},
												});
											}}
											label="Origine"
										/>
									</div>
									<div className="col-12 col-sm-6 col-xl-4">
										{getTextField({
											...defaultTextField,
											fieldKey: "matchObserved",
											label: "Partita",
											required: !edit && !matchId,
										})}
									</div>
									<div className="col-12 col-sm-6 col-xl-4">
										<VLFSelectNew
											options={clubs.map((c) => {
												return {
													value: c.id,
													label: c.name,
												};
											})}
											value={fields.prospectScoutTeam}
											onChange={(newValue) => {
												setErrors([]);
												setFields({...fields, prospectScoutTeam: newValue});
											}}
											onCreateOption={createClub}
											label="Squadra"
											required
											helperText={
												errors.indexOf("prospectScoutTeam") !== -1
													? REQUIRED_FIELD
													: ""
											}
											error={errors.indexOf("prospectScoutTeam") !== -1}
										/>
									</div>
									<div className="col-12 col-sm-6 col-xl-4">
										<VLFSelectNew
											options={categories}
											sortOrder="descend"
											value={fields.playerScoutCategory}
											onChange={(newValue) => {
												setErrors([]);
												setFields({...fields, playerScoutCategory: newValue});
											}}
											label="Categoria"
											required
											helperText={
												errors.indexOf("playerScoutCategory") !== -1
													? REQUIRED_FIELD
													: ""
											}
											error={errors.indexOf("playerScoutCategory") !== -1}
										/>
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-12 padding-small">
						<VLFCard
							title="Informazioni portiere"
							content={
								<div className="row no-margin single-area-container">
									<div className="col-12 padding-small">
										<CreateSelectEntity
											currentOption={saveOption}
											setCurrentOption={setSaveOption}
										/>
									</div>
									{saveOption === "new" ? (
										<>
											<div className="col-12 col-sm-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													fieldKey: "firstName",
													label: "Nome",
												})}
											</div>
											<div className="col-12 col-sm-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													fieldKey: "lastName",
													label: "Cognome",
													required: true,
												})}
											</div>
											<div className="col-12 col-sm-6 col-xl-4">
												<VLFDatePicker
													label="Data di nascita"
													value={fields.dateOfBirth}
													onChange={(value) => {
														setFields({...fields, dateOfBirth: value});
													}}
													format="DD-MM-YYYY"
												/>
											</div>
											<div className="col-12 col-sm-6 col-xl-4">
												{getTextField({
													...defaultTextField,
													fieldKey: "mainFoot",
													label: "Piede principale",
												})}
											</div>
										</>
									) : (
										<div className="col-12 col-sm-6 col-xl-4">
											<VLFSelectNew
												options={prospectPlayers.map((pp) => {
													return {
														value: pp.id,
														label:
															pp.firstName +
															" " +
															pp.lastName +
															" (" +
															(pp.dateOfBirth
																? moment(pp.dateOfBirth).format(dateFormat)
																: "---") +
															")",
													};
												})}
												value={fields?.prospectScoutPlayer}
												onChange={(newValue) => {
													setFields({...fields, prospectScoutPlayer: newValue});
												}}
												label="Portiere"
												required
											/>
										</div>
									)}
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-12 padding-small">
						<VLFCard
							title="Dettagli"
							content={
								<div className="row no-margin single-area-container">
									<div className="col-12 col-md-6 my-2">
										<VLFToggleButtonGroup
											name="fatherBodyType"
											value={fields.fatherBodyType.toString() || ""}
											onChange={(event) => {
												setFields({
													...fields,
													fatherBodyType: event.target.value,
												});
											}}
											options={bodyTypeOptions}
											label="Struttura padre"
											size="small"
											rowDirection={true}
										/>
									</div>
									<div className="col-12 col-md-6 my-2">
										<VLFToggleButtonGroup
											name="motherBodyType"
											value={fields.motherBodyType.toString() || ""}
											onChange={(event) => {
												setFields({
													...fields,
													motherBodyType: event.target.value,
												});
											}}
											options={bodyTypeOptions}
											label="Struttura madre"
											size="small"
											rowDirection={true}
										/>
									</div>
									<div className="col-12 col-md-6 my-2">
										<VLFToggleButtonGroup
											name="bodyType"
											value={fields.bodyType.toString() || ""}
											onChange={(event) => {
												setFields({
													...fields,
													bodyType: event.target.value,
												});
											}}
											options={bodyTypeOptions}
											label="Struttura apparente"
											size="small"
											rowDirection={true}
										/>
									</div>
									<div className="col-12 col-md-6 my-2">
										<VLFToggleButtonGroup
											name="childType"
											value={fields.childType.toString() || ""}
											onChange={(event) => {
												setFields({
													...fields,
													childType: event.target.value,
												});
											}}
											options={childTypeOptions}
											label="Tipologia di bambino"
											size="small"
											rowDirection={true}
										/>
									</div>
									<div className="col-12 col-md-6 my-2">
										<VLFToggleButtonGroup
											name="aptitudeForRole"
											value={fields.aptitudeForRole.toString() || ""}
											onChange={(event) => {
												setFields({
													...fields,
													aptitudeForRole: event.target.value,
												});
											}}
											options={aptitudeForRole}
											label="Attitudine al ruolo"
											size="small"
											rowDirection={true}
										/>
									</div>
									<div className="col-12 col-md-6 my-2">
										<VLFToggleButtonGroup
											name="reactivity"
											value={fields.reactivity.toString() || ""}
											onChange={(event) => {
												setFields({
													...fields,
													reactivity: event.target.value,
												});
											}}
											options={bodyTypeOptions}
											label="Reattività"
											size="small"
											rowDirection={true}
										/>
									</div>
									<div className="col-12 col-md-6 my-2">
										<VLFToggleButtonGroup
											name="verbalCommunication"
											value={fields.verbalCommunication.toString() || ""}
											onChange={(event) => {
												setFields({
													...fields,
													verbalCommunication: event.target.value,
												});
											}}
											options={communicationTypeOptions}
											label="Comunicazione verbale"
											size="small"
											rowDirection={true}
										/>
									</div>
									<div className="col-12 col-md-6 my-2">
										<VLFToggleButtonGroup
											name="athleticism"
											value={fields.athleticism.toString() || ""}
											onChange={(event) => {
												setFields({
													...fields,
													athleticism: event.target.value,
												});
											}}
											options={bodyTypeOptions}
											label="Atleticità"
											size="small"
											rowDirection={true}
										/>
									</div>
									<div className="col-12 col-md-6 my-2">
										<VLFToggleButtonGroup
											name="participationLevel"
											value={fields.participationLevel.toString() || ""}
											onChange={(event) => {
												setFields({
													...fields,
													participationLevel: event.target.value,
												});
											}}
											options={defaultReportOptions}
											label="Partecipazione al gioco della propria squadra"
											size="small"
											rowDirection={true}
										/>
									</div>
									<div className="col-12 my-2">
										<div className="evaluation-choice d-flex flex-column">
											<div className="choice-label">Valutazione</div>
											<div className="choice-options">
												{valuationOption.map((as, k) => {
													return (
														<div
															key={k}
															className={
																`single-option ${fields?.valuation?.toString() === as.value ? "selected" : ""}` +
																(as.value === "0"
																	? " idoneo"
																	: as.value === "1"
																		? " non-idoneo"
																		: as.value === "2"
																			? " da-rivedere"
																			: "")
															}
															onClick={() => {
																const newValue =
																	fields?.valuation?.toString() === as.value
																		? -1
																		: as.value;
																setFields({
																	...fields,
																	valuation: newValue,
																});
															}}
														>
															{as.label}
														</div>
													);
												})}
											</div>
										</div>
									</div>
									<div className="col-12 my-2">
										<div className="evaluation-choice d-flex flex-column">
											<div className="choice-label">Approfondimento</div>
											<div className="choice-options">
												{valuationAnalysisOption.map((o, k) => {
													return (
														<div
															key={k}
															className={
																"single-option" +
																(fields?.deepening &&
																fields?.deepening?.indexOf(o.value) !== -1
																	? " selected"
																	: "")
															}
															onClick={() => {
																let updatedValue = fields?.deepening
																	? Array.from(fields?.deepening)
																	: [];
																if (updatedValue?.indexOf(o.value) !== -1) {
																	const index = updatedValue.indexOf(o.value);
																	updatedValue.splice(index, 1);
																} else {
																	updatedValue.push(o.value);
																}
																setFields({
																	...fields,
																	deepening: updatedValue,
																});
															}}
														>
															{o.label}
														</div>
													);
												})}
											</div>
										</div>
									</div>
									<div className="col-12 my-2">
										<VLFTextField
											value={fields["notes"] || ""}
											onChange={(e) => {
												setFields({...fields, notes: e.target.value});
											}}
											label="Note"
											size="small"
											multiline
											minRows={3}
											maxRows={3}
										/>
									</div>
								</div>
							}
						/>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default HandleScoutReportPage;
