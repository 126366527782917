import React from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function Loading({visible}) {
	return (
		visible && (
			<Backdrop sx={{zIndex: (theme) => theme.zIndex.drawer + 1}} open>
				<CircularProgress color="inherit" />
			</Backdrop>
		)
	);
}

export default Loading;
