import api from "./configuration";
import apiUrl from "./url";

const USE_AUTH_INTERCEPTOR = true;

export const PlaylistAPI = {
	list: async () => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Playlists/",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	create: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Playlists",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	getById: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Playlists/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	delete: async (id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Playlists/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	upload: async (type, id, file, fileName) => {
		const formData = new FormData();
		formData.append("file", file);

		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Playlists/" + type + "/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data: formData,
			headers: {"Content-Type": "multipart/form-data"},
			fileName,
		});

		return response.data;
	},
	update: async (id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Playlists/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
};
