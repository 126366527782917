import * as React from "react";
import moment from "moment";

import {dateFormat, externalValuationOptions} from "@/utils/constants";

import "./index.scss";

export default function ValuationCard(props) {
	const {element} = props;

	const external = element.valuationType > 1;
	const player = external ? element.prospectPlayer : element.player;
	const category = external
		? element.playerCategory?.name
		: element.player?.category;

	const result = externalValuationOptions.filter(
		(v) => v.value === element.finalOvarallRating?.toString(),
	)?.[0]?.label;

	const imagePath = external
		? element.valuationType === 2 || element.valuationType === 4
			? require("@/assets/images/coach/match.png")
			: require("@/assets/images/coach/training.png")
		: require("@/assets/images/manager/evaluation.png");

	return (
		<div className="valuation-card-container">
			<div className="valuation-card-header">
				<div className="top-left">
					{moment(element.valuationDate).format(dateFormat)}
				</div>
				<div className="top-right">{category}</div>
			</div>
			<div className="valuation-card-info">
				<img src={imagePath} alt="" />
				<div className="d-flex flex-column">
					<div className="info-title">
						{player?.firstName + " " + player?.lastName}
					</div>
					{element.dateOfBirth ? (
						<div className="info-second-title">
							{moment(element.dateOfBirth).format(dateFormat)}
						</div>
					) : null}
				</div>
				<div className="info-subtitle">
					{+parseFloat(element.finalAverage).toFixed(2)}
				</div>
			</div>
			<div className="separator"></div>
			<div className="valuation-card-preview">
				<div className="valuation-type">
					<div className="type-info">{element.valuationAuthor || "---"}</div>
				</div>
				{external ? (
					<div className="valuation-stats">
						<div>{result || "---"}</div>
					</div>
				) : null}
			</div>
		</div>
	);
}
