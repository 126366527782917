import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
// import PlayerRadarChart from "@/components/PlayerRadarChart";
import ViewField from "@/components/Input/ViewField";
import ScoutReportsContainer from "@/components/ScoutReportsContainer";
import ValuationsPage from "@/pages/Valuations";
import CardsTable from "@/components/CardsTable";
import NotesSection from "@/components/NotesSection";
import AttachmentsContainer from "@/components/AttachmentsContainer";
import VLFModal from "@/components/Modal";
import HandleCareerModal from "@/pages/Scout/Players/Player/handleCareer";

import {AttachmentAPI} from "@/api/attachment";
import {ProspectAPI} from "@/api/prospect";
import {ScoutReportAPI} from "@/api/scout_report";
// import {ValuationAPI} from "@/api/valuation";

import ArrowBack from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import {
	prospectPlayerStatus,
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
} from "@/utils/constants";

const ScoutPlayerPage = (props) => {
	const {handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const [loading, setLoading] = useState(false);
	const [player, setPlayer] = useState({});
	const [playerReports, setPlayerReports] = useState([]);

	const [idAttachment, setIdAttachment] = useState();
	const [attachments, setAttachments] = useState([]);

	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [openCareerDetail, setOpenCareerDetail] = useState(false);
	const [openHandleAttachmentConfirm, setOpenHandleAttachmentConfirm] =
		useState(false);

	const [initialData, setInitialData] = useState();

	const getPlayer = async () => {
		setLoading(true);
		try {
			const response = await ProspectAPI.playerById(id);
			response.career = response.career?.map((c) => {
				if (c.id?.startsWith("00000000")) {
					c.readable = true;
				}
				return c;
			});

			const seen = new Set();
			response.career = response.career.filter((obj) => {
				const key = `${obj.season?.name}-${obj.prospectTeam?.name}-${obj.playerCategory?.name}`;
				if (seen.has(key)) {
					return false;
				}
				seen.add(key);
				return true;
			});
			setPlayer(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	const getPlayerReports = async () => {
		try {
			const response = await ScoutReportAPI.getByPlayerId(id);
			setPlayerReports(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const getPlayerAttachment = async () => {
		try {
			const response = await AttachmentAPI.list("prospect_player", id);
			setAttachments(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const deletePlayer = async () => {
		setLoading(true);
		try {
			await ProspectAPI.deletePlayer(id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/scout-players");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};
	const deleteAttachment = async () => {
		setLoading(true);
		try {
			await AttachmentAPI.delete(idAttachment);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			setOpenHandleAttachmentConfirm(false);
			getPlayerAttachment();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getPlayer();
		getPlayerReports();
		getPlayerAttachment();
	}, []);

	const getAttachment = async (id) => {
		setLoading(true);
		try {
			const currentFile = attachments.filter((a) => a.id === id)?.[0];
			const response = await AttachmentAPI.getById(id, {
				responseType: "blob",
			});

			// Ottieni l'estensione del file
			const extension = currentFile.filePath
				.split("\\")
				.pop()
				.split(".")
				.pop()
				.toLowerCase();
			let mimeType = "application/octet-stream"; // Default per file sconosciuti

			// eslint-disable-next-line default-case
			switch (extension) {
				case "pdf":
					mimeType = "application/pdf";
					break;
				case "png":
					mimeType = "image/png";
					break;
				case "jpg":
				case "jpeg":
					mimeType = "image/jpeg";
					break;
				case "doc":
					mimeType = "doc";
					break;
			}

			const blob = new Blob([response], {type: mimeType});

			const url = window.URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", currentFile.filePath.split("\\").pop());

			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const handleCareerDetail = async (data, id) => {
		setLoading(true);
		try {
			const body = {
				...data,
				prospectPlayerId: player?.id,
				seasonId: data?.careerSeason?.value,
				prospectTeamId: data?.careerTeam?.value,
				playerCategoryId: data?.careerCategory?.value,
			};

			id
				? await ProspectAPI.updatePlayerCareer(player?.id, id, body)
				: await ProspectAPI.createPlayerCareer(body);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayer();
			setOpenCareerDetail(false);
			setInitialData();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const deleteCareerDetail = async (id) => {
		setLoading(true);
		try {
			await ProspectAPI.deletePlayerCareer(player?.id, id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getPlayer();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const goBack = () => {
		navigate("/scout-players");
	};

	const mainActions = [
		{icon: <ArrowBack />, title: "Indietro", onClick: goBack, color: "info"},
		{
			icon: <EditIcon />,
			title: "Modifica",
			onClick: () => navigate("/scout-players/" + player?.id + "/edit"),
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => setOpenHandleConfirm(true),
			color: "error",
		},
	];
	const mainConfiguration = [
		{
			value: player?.firstName,
			label: "Nome",
			size: "col-6 col-lg-4",
		},
		{
			value: player?.lastName,
			label: "Cognome",
			size: "col-6 col-lg-4",
		},
		{
			value:
				player?.dateOfBirth &&
				moment(player?.dateOfBirth).year() > 1900 &&
				moment(player?.dateOfBirth).format("DD/MM/YYYY"),
			label: "Data di nascita",
			size: "col-6 col-lg-4",
		},
		{
			value: player?.height ? player.height + " cm" : "",
			label: "Altezza",
			size: "col-6 col-lg-4",
		},
		{
			value: player?.weight ? player.weight + " cm" : "",
			label: "Peso",
			size: "col-6 col-lg-4",
		},
		{
			value: player?.prospectStatus ? (
				<div
					style={{
						border: "1px solid #002160",
						borderRadius: "10px",
						padding: "0px 10px",
						width: "fit-content",
					}}
				>
					{
						prospectPlayerStatus?.filter(
							(s) => s.value === player.prospectStatus,
						)?.[0]?.label
					}
				</div>
			) : (
				"---"
			),
			label: "Status",
			size: "col-12 col-sm-6 col-lg-4",
		},
		{
			value: player?.favoriteHand,
			label: "Mano",
			size: "col-6 col-lg-4",
		},
		{
			value: player?.favoriteFoot,
			label: "Piede",
			size: "col-6 col-lg-4",
		},
		{
			value: player?.fatherHeight,
			label: "Altezza padre",
			size: "col-6 col-lg-4",
		},
		{
			value: player?.motherHeight,
			label: "Altezza madre",
			size: "col-6 col-lg-4",
		},
		{
			value: player?.notes,
			label: "Note",
			size: "col-6 col-lg-4",
		},
	];

	const careerColumns = [
		{
			label: "Stagione",
			key: "season",
			customRender: (item) => item.season?.name,
		},
		{
			label: "Squadra",
			key: "prospectTeam",
			customRender: (item) => item.prospectTeam?.name,
		},
		{
			label: "Categoria",
			key: "playerCategory",
			customRender: (item) => item.playerCategory?.name,
		},
	];

	const breadcrumbsElements = [
		{label: "Portieri", path: "/scout-players"},
		{label: "/"},
	];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={
					player ? (player.firstName || "") + " " + (player.lastName || "") : ""
				}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-2">
										<div className="image-container generic">
											<img
												src={require("@/assets/images/manager/player.png")}
												alt=""
											/>
										</div>
									</div>
									<div className="col-sm-12 col-md-10">
										<ViewField configuration={mainConfiguration} />
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
				<div className="row no-margin mt-3">
					<div className="col-sm-12 padding-small">
						<CardsTable
							title="Carriera"
							fullHeight={false}
							middleHeight
							configurationColumns={careerColumns}
							data={player?.career}
							actions={[
								{
									icon: <ControlPointIcon />,
									onClick: () => setOpenCareerDetail(true),
								},
							]}
							handleEdit={(item) => {
								setInitialData(Object.assign({}, item));
								setOpenCareerDetail(true);
							}}
							handleDelete={(item) => {
								deleteCareerDetail(item.id);
							}}
							defaultSortedField="dateEvent"
							defaultSortedOrder="descend"
						/>
					</div>
				</div>
				<div className="row no-margin mt-3">
					<div className="col-sm-12 padding-small">
						<div
							className="px-1"
							style={{fontSize: "16px", fontWeight: "bold"}}
						>
							Segnalazioni
						</div>
						<ScoutReportsContainer
							reports={playerReports}
							fullHeight={false}
							grouped={false}
							breadcrumbs={false}
						/>
					</div>
				</div>
				<div className="row no-margin mt-3">
					<div className="col-sm-12 padding-small">
						<div
							className="px-1"
							style={{fontSize: "16px", fontWeight: "bold"}}
						>
							Valutazioni
						</div>
						<ValuationsPage external prospectPlayerId={id} />
					</div>
				</div>
				<NotesSection
					type="player_match"
					notes={player?.matchesNotes}
					prospect
				/>
				<div className="row no-margin mt-3">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Allegati"
							content={
								<AttachmentsContainer
									entityId={id}
									entityType="prospect_player"
									attachments={attachments}
									handleAdd={() => getPlayerAttachment()}
									handleDownload={getAttachment}
									handleDelete={(id) => {
										setIdAttachment(id);
										setOpenHandleAttachmentConfirm(true);
									}}
									handleNotification={handleNotification}
								/>
							}
						/>
						<VLFModal
							open={openHandleAttachmentConfirm}
							title="Eliminazione"
							content={
								<div className="mt-2">
									Sei sicuro di voler eliminare questo elemento?
								</div>
							}
							confirm={() => deleteAttachment()}
							close={() => setOpenHandleAttachmentConfirm(false)}
							confirmLabel="Conferma"
						/>
					</div>
				</div>
				<VLFModal
					open={openHandleConfirm}
					title="Eliminazione"
					content={
						<div className="mt-2">
							Sei sicuro di voler eliminare questo elemento?
						</div>
					}
					confirm={() => {
						deletePlayer();
					}}
					close={() => setOpenHandleConfirm(false)}
					confirmLabel="Conferma"
				/>
				{openCareerDetail ? (
					<HandleCareerModal
						open={openCareerDetail}
						confirm={handleCareerDetail}
						close={() => {
							setOpenCareerDetail(false);
							setInitialData();
						}}
						initialData={initialData}
						handleNotification={handleNotification}
					/>
				) : null}
			</div>
		</Page>
	);
};

export default ScoutPlayerPage;
