import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import EmptyData from "@/components/EmptyData";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import VLFModal from "@/components/Modal";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {ValuationAPI} from "@/api/valuation";

import {
	dateFormat,
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
	initialValuationParameters,
	initialBaseValuationParameters,
	proExternMatchParameters,
	baseExternMatchParameters,
	proExternTrainingParameters,
	baseExternTrainingParameters,
	externalValuationOptions,
} from "@/utils/constants";

import "./index.scss";

function Valuation(props) {
	const {handleNotification, external} = props;

	const playerId = new URLSearchParams(useLocation().search).get("playerId");

	const {id} = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [valuationData, setValuationData] = useState({});

	const getValuation = async () => {
		setLoading(true);
		try {
			const response = await ValuationAPI.getById(id);
			response.rating =
				response.finalOvarallRating !== null &&
				response.finalOvarallRating !== undefined
					? externalValuationOptions.filter(
							(v) => v.value === response.finalOvarallRating.toString(),
						)?.[0]?.label
					: undefined;
			setValuationData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getValuation();
	}, []);

	const deleteValuation = async () => {
		setLoading(true);
		try {
			await ValuationAPI.delete(valuationData?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate(external ? "/scout/valuations" : "/players/" + playerId);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const reportConfigurationSize = external
		? "col-6 col-xl-4"
		: "col-6 col-xl-4";
	const reportConfiguration = [
		{
			value: valuationData?.valuationDate
				? moment(valuationData?.valuationDate).format(dateFormat)
				: null,
			label: "Data",
			size: reportConfigurationSize,
		},
		{
			value: valuationData?.valuationAuthor,
			label: "Allenatore",
			size: reportConfigurationSize,
		},
	];

	const currentPlayerData = external
		? valuationData?.prospectPlayer
		: valuationData?.player;
	if (external) {
		reportConfiguration.push(
			{
				value:
					valuationData.valuationType === 2 || valuationData.valuationType === 4
						? "Partita"
						: "Allenamento",
				label: "Tipologia",
				size: reportConfigurationSize,
			},
			{
				value: valuationData?.prospectTeam?.name,
				label: "Squadra",
				size: reportConfigurationSize,
			},
		);

		reportConfiguration.push({
			value: valuationData?.playerCategory?.name,
			label: "Categoria",
			size: reportConfigurationSize,
		});
	} else {
		reportConfiguration.push({
			value: valuationData?.momentOfGrowth,
			label: "Momento di crescita",
			size: reportConfigurationSize,
		});
	}

	const playerReportConfigurationSize = "col-6 col-xl-3";
	const playerReportConfiguration = [
		{
			value: currentPlayerData
				? currentPlayerData?.firstName +
					" " +
					currentPlayerData?.lastName +
					(!external
						? " (" + currentPlayerData?.category + ")"
						: currentPlayerData?.dateOfBirth
							? " (" +
								moment(currentPlayerData?.dateOfBirth).format(dateFormat) +
								")"
							: "")
				: "---",
			label: "Portiere",
			size: playerReportConfigurationSize,
		},
		{
			value: valuationData?.height ? valuationData?.height + " cm" : "",
			label: "Altezza",
			size: playerReportConfigurationSize,
		},
		{
			value: valuationData?.weight ? valuationData?.weight + " kg" : "",
			label: "Peso",
			size: playerReportConfigurationSize,
		},
	];

	if (external) {
		playerReportConfiguration.push({
			value: valuationData?.trialPeriod,
			label: "Periodo di prova",
			size: playerReportConfigurationSize,
		});
	} else {
		playerReportConfiguration.push({
			value: valuationData?.timeInsideClub,
			label: "Nel club dal",
			size: playerReportConfigurationSize,
		});
	}

	const getCategoryNumber = (category) => {
		return parseInt(category?.split(" ")?.[1]);
	};

	let parameters = [];
	if (external) {
		if (valuationData?.valuationType && valuationData?.playerCategory) {
			if (getCategoryNumber(valuationData.playerCategory.name) > 12) {
				if (valuationData.valuationType === 2) {
					parameters = proExternMatchParameters;
				}
				if (valuationData.valuationType === 3) {
					parameters = proExternTrainingParameters;
				}
			} else {
				if (valuationData.valuationType === 4) {
					parameters = baseExternMatchParameters;
				}
				if (valuationData.valuationType === 5) {
					parameters = baseExternTrainingParameters;
				}
			}
		}
	} else {
		if (valuationData?.player?.category) {
			parameters =
				getCategoryNumber(valuationData?.player?.category) > 12
					? initialValuationParameters
					: initialBaseValuationParameters;
		}
	}

	const reportDetailsConfiguration = parameters.map((p) => {
		return {
			value: (
				<div className="area-rating-container">
					{valuationData[p.fieldKeyRating] && (
						<div className="stat-circle-container">
							<div className="stat-container">
								<div className="value">
									{+parseFloat(valuationData[p.fieldKeyRating]).toFixed(2)}
								</div>
							</div>
						</div>
					)}
					{valuationData?.[p.fieldKey] && (
						<div className="rating-notes">{valuationData?.[p.fieldKey]}</div>
					)}
					{!valuationData?.[p.fieldKeyRating] &&
						!valuationData?.[p.fieldKey] && <EmptyData />}
				</div>
			),
			label: p.label,
			size: "col-12 col-sm-6",
		};
	});
	if (!external) {
		reportDetailsConfiguration.push({
			value: (
				<div className="area-rating-container only-text">
					{valuationData?.strengthAreas ? (
						<div className="rating-notes">{valuationData?.strengthAreas}</div>
					) : (
						<EmptyData />
					)}
				</div>
			),
			label: "Punti di forza, aree migliorabili",
			size: "col-12 col-sm-6",
		});
	}
	reportDetailsConfiguration.push({
		value: (
			<div className="area-rating-container only-text">
				{valuationData?.finalOvarallValuation ? (
					<div className="rating-notes">
						{valuationData?.finalOvarallValuation}
					</div>
				) : (
					<EmptyData />
				)}
			</div>
		),
		label: "Valutazione complessiva finale",
		size: "col-12 col-sm-6",
	});
	reportDetailsConfiguration.push({
		value: (
			<div className="area-rating-container only-text">
				{valuationData?.valuationText ? (
					<div className="rating-notes big">{valuationData?.valuationText}</div>
				) : (
					<EmptyData />
				)}
			</div>
		),
		label: external ? "Relazione generale" : "Valutazione iniziale",
		size: "col-12",
	});

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate(-1),
			color: "info",
		},
		{
			icon: <EditIcon />,
			title: "Modifica",
			onClick: () =>
				navigate(
					external
						? "/scout/valuations/" + valuationData?.id + "/edit"
						: "/players-valuations/" +
								valuationData?.id +
								"/edit?playerId=" +
								playerId,
				),
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => setOpenHandleConfirm(true),
			color: "error",
		},
	];

	const breadcrumbsElements = [
		{
			label: "Valutazioni",
			path: external ? "/scout/valuations" : "/players/" + playerId,
		},
		{label: "/"},
	];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={
					valuationData
						? moment(valuationData?.valuationDate).format(dateFormat) +
							" " +
							(currentPlayerData?.firstName || "") +
							" " +
							(currentPlayerData?.lastName || "")
						: ""
				}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin col-container">
					<div className="col-sm-12 col-lg-12 padding-small">
						<VLFCard
							title="Informazioni valutazione"
							content={
								<div className="row">
									<div className="col-sm-12 col-lg-10">
										<ViewField configuration={reportConfiguration} />
									</div>
									<div className="col-sm-12 col-lg-2">
										<div
											className="d-flex flex-column align-items-center gap-2 h-100"
											style={{borderLeft: "1px solid #ebf1fd"}}
										>
											<div className="view-field-container avg h-100 justify-content-center">
												<div className="field-label-container justify-content-center">
													<div className="field-label">Media finale</div>
												</div>
												<div className="stat-circle-container">
													<div className="stat-container">
														<div className="value">
															{
																+parseFloat(
																	valuationData?.finalAverage || 0,
																).toFixed(2)
															}
														</div>
													</div>
												</div>
											</div>
											{external ? (
												<div className="view-field-container avg h-100 justify-content-center">
													<div className="field-label-container justify-content-center">
														<div className="field-label">Esito</div>
													</div>
													<div className="d-flex justify-content-center">
														{valuationData?.rating || "---"}
													</div>
												</div>
											) : null}
										</div>
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni portiere"
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={playerReportConfiguration} />
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Dettagli"
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={reportDetailsConfiguration} />
									</div>
								</div>
							}
						/>
					</div>
				</div>
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => deleteValuation()}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default Valuation;
