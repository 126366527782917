import api from "./configuration";
import apiUrl from "./url";

const USE_AUTH_INTERCEPTOR = true;

export const ProspectAPI = {
	playersList: async () => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Prospects/Players",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	playerById: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Prospects/Players/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createPlayer: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Prospects/Players",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updatePlayer: async (id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Prospects/Players/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deletePlayer: async (id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Prospects/Players/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getPlayerCareer: async (playerId) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Prospects/Players/Career",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params: {playerId},
		});

		return response.data;
	},
	createPlayerCareer: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Prospects/Players/Career",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updatePlayerCareer: async (id, careerId, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Prospects/Players/" + id + "/Career/" + careerId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deletePlayerCareer: async (id, careerId) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Prospects/Players/" + id + "/Career/" + careerId,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getPlayerNotes: async (playerId) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Prospects/Players/" + playerId + "/MatchesNotes",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	teamsList: async () => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Prospects/Teams",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getByIdTeam: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Prospects/Teams/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createTeam: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Prospects/Teams",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updateTeam: async (id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Prospects/Teams/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteTeam: async (id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Prospects/Teams/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},

	listCoach: async (filtersData) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Prospects/Coaches",
			params: filtersData,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	getByIdCoach: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/Prospects/Coaches/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createCoach: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/Prospects/Coaches",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updateCoach: async (id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/Prospects/Coaches/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteCoach: async (id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/Prospects/Coaches/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
};
