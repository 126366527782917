import React, {useEffect, useState} from "react";

import ChartContainer from "@/components/ChartContainer";
import Filters from "@/components/Filters";

import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import SwitchRightIcon from "@mui/icons-material/SwitchRight";

import "./index.scss";

export default function CardsTable(props) {
	const {
		title,
		actions,
		configurationColumns,
		data,
		fullWidth = false,
		fullHeight = true,
		middleHeight = false,
		showModes = false,
		rowClassName,
		handleClick,
		handleEdit,
		handleDelete,
		defaultSortedField,
		defaultSortedOrder = "ascend",
		filtersData,
		breadcrumbs = false,
		forceOpened = false,
		chartData,
	} = props;
	const [opened, setOpened] = useState(false);

	useEffect(() => {
		if (forceOpened) {
			setOpened(true);
		}
	}, [forceOpened]);

	const [showMode, setShowMode] = useState("table");
	// const [openChartInfo, setOpenChartInfo] = useState(false);
	// const [chartModalContent, setChartModalContent] = useState(<></>);

	const deleteColumn = {
		label: "",
		key: "actions",
		customRender: (item) => {
			return !item.emptyRow && !item.readable ? (
				<div className="actions-row-container">
					<div
						className="action clickable"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();

							handleDelete(item);
						}}
					>
						<DeleteOutlineIcon />
					</div>
				</div>
			) : (
				<></>
			);
		},
		small: true,
		align: "end",
	};

	const editColumn = {
		label: "",
		key: "actions",
		customRender: (item) => {
			return !item.emptyRow && !item.readable ? (
				<div className="actions-row-container">
					<div
						className="action clickable"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();

							handleEdit(item);
						}}
					>
						<EditIcon />
					</div>
				</div>
			) : (
				<></>
			);
		},
		small: true,
		align: "end",
	};

	const columns = configurationColumns ? Array.from(configurationColumns) : [];

	if (handleEdit) {
		columns.push(editColumn);
	}
	if (handleDelete) {
		columns.push(deleteColumn);
	}

	// const originalData = Array.from(data);
	const sortedData = defaultSortedField
		? data
				?.slice()
				.sort((a, b) =>
					defaultSortedOrder === "descend"
						? b[defaultSortedField] > a[defaultSortedField]
							? 1
							: a[defaultSortedField] > b[defaultSortedField]
								? -1
								: 0
						: a[defaultSortedField] > b[defaultSortedField]
							? 1
							: b[defaultSortedField] > a[defaultSortedField]
								? -1
								: 0,
				)
		: data;

	return (
		<>
			{filtersData && (
				<Filters
					data={filtersData}
					opened={opened}
					setOpened={setOpened}
					forceOpened={forceOpened}
				/>
			)}
			<div
				className={
					"table-container" +
					(fullWidth ? " full-width" : "") +
					(breadcrumbs ? " breadcrumbs" : "") +
					(filtersData ? (opened ? " fixed-filters" : " filtered") : "")
				}
			>
				<div className="title-container d-flex align-items-center justify-content-between">
					<div className="title-label">{title || " "}</div>
					{actions?.length ? (
						<div className="title-actions-container">
							{showModes && (
								<div
									className="d-flex align-items-center clickable"
									onClick={() => {
										setShowMode(showMode === "table" ? "chart" : "table");
									}}
								>
									{showMode === "table" ? (
										<SwitchLeftIcon />
									) : (
										<SwitchRightIcon />
									)}
								</div>
							)}
							{showMode === "table" &&
								actions.map((action, key) => {
									return (
										<div
											key={key}
											className="d-flex align-items-center clickable"
											onClick={() => {
												if (action.type !== "chart") {
													action.onClick();
												}
											}}
										>
											{action.icon}
										</div>
									);
								})}
						</div>
					) : (
						""
					)}
				</div>
				{showMode === "table" ? (
					<>
						<div className="table-header-container">
							{columns.map((column, key) => {
								return (
									<div
										key={key}
										className={
											"header-field" +
											(column.small ? " sm" : "") +
											(column.medium ? " md" : "") +
											(column.align
												? " justify-content-" + column.align
												: " justify-content-center")
										}
									>
										{column.label}
									</div>
								);
							})}
						</div>
						<div
							className={
								"elements-container" +
								(fullHeight ? " full-height" : "") +
								(middleHeight ? " middle-height" : "")
							}
						>
							{sortedData?.map((singleData, key) => {
								return (
									<div
										key={key}
										className={
											"element-container" +
											(handleClick ? " clickable" : "") +
											(rowClassName ? " " + rowClassName(singleData) : "")
										}
										onClick={() => {
											if (handleClick) {
												handleClick(singleData);
											}
										}}
									>
										{columns.map((column, columnKey) => {
											const currentKey = column.key;
											const nestedKeys = currentKey.split(".");

											const getValueFromNestedKey = (obj, keys) => {
												for (const key of keys) {
													if (obj.hasOwnProperty(key)) {
														obj = obj[key];
													} else {
														return null;
													}
												}
												return obj || "---";
											};

											return (
												<div
													key={key + "_" + columnKey}
													className={
														"element-field" +
														(column.small ? " sm" : "") +
														(column.medium ? " md" : "") +
														(column.align
															? " justify-content-" + column.align
															: " justify-content-center")
													}
												>
													{column?.customRender
														? column.customRender(singleData)
														: getValueFromNestedKey(singleData, nestedKeys)}
												</div>
											);
										})}
									</div>
								);
							})}
							{!data?.length ? (
								<div className="d-flex align-items-center justify-content-center fst-italic py-2">
									Nessun dato presente
								</div>
							) : null}
						</div>
					</>
				) : (
					<div className="charts-container d-flex align-items-center gap-2">
						<ChartContainer
							type={chartData?.type}
							title={chartData?.title}
							chartItems={[
								{
									value: `${chartData?.value}`,
									label: `${chartData?.label}`,
									color: "#002160",
								},
							]}
							data={sortedData
								.reverse()
								?.filter((d) => d[`${chartData?.value}`])}
							dataKey={defaultSortedField}
						/>
					</div>
				)}
			</div>
		</>
	);
}
