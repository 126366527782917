import * as React from "react";
import moment from "moment";

import Button from "@mui/material/Button";

import {dateFormat} from "@/utils/constants";

import "./index.scss";

export default function PlayerMovementCard(props) {
	const {element, actions} = props;

	const currentYear = moment(element.movementDate).year();
	const currentMonth = moment(element.movementDate).month() + 1;
	const currentSeason =
		currentMonth <= 6
			? "" + (currentYear - 1).toString() + "/" + currentYear
			: "" + currentYear + "/" + (currentYear + 1);

	return (
		<div className="movement-card-container">
			<div className="d-flex flex-column">
				<div className="movement-card-header">
					<div className="top-left">
						{moment(element.movementDate).format(dateFormat)}
					</div>
					<div className="top-right"></div>
				</div>
			</div>
			<div className="movement-card-info">
				<div className="info-title">{element.movementType?.description}</div>
			</div>
			<div className="movement-card-subcontainer">
				<div className="movement-card-subinfo">
					<div className="type-info">{"Stagione: " + currentSeason}</div>
					<div className="type-info" style={{textAlign: "right"}}>
						{"Società: " + (element.prospectTeam?.name || "---")}
					</div>
				</div>
				<div className="movement-card-subinfo">
					<div className="type-info">
						{"Tesseramento: " +
							moment(element.membershipDate).format(dateFormat)}
					</div>
					<div className="type-info" style={{textAlign: "right"}}>
						{"Status: " + (element.movementType?.status || "---")}
					</div>
				</div>
			</div>
			<>
				<div className="separator"></div>
				<div className="actions justify-content-center my-1">
					{actions.map((action, key) => {
						return (
							<div key={key} className="action">
								<Button
									variant={action.variant || "contained"}
									onClick={() => action.onClick(element)}
									color={action.color || "blue"}
									size="small"
								>
									<div className="action-content d-flex align-items-center">
										<div>{action.icon && action.icon}</div>
									</div>
								</Button>
							</div>
						);
					})}
				</div>
			</>
		</div>
	);
}
