import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import MainActions from "@/components/MainActions";
import Breadcrumbs from "@/components/Breadcrumbs";
import VLFCard from "@/components/Card";
import VLFSelectNew from "@/components/Input/SelectNew";
import VLFDatePicker from "@/components/Input/DatePicker";

import {PlayerAPI} from "@/api/player";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
	tqrOptions,
} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";

function HandleTQRPage(props) {
	const {handleNotification} = props;

	const {id} = useParams();
	const navigate = useNavigate();
	const playerId = useSelector((state) => state.user?.playerId);

	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState({});

	const getRequiredFields = () => {
		return ["tqrDate", "tqrValue"];
	};

	const saveTQR = async () => {
		setLoading(true);
		try {
			await PlayerAPI.createTQR({
				id,
				playerId,
				tqrDate: moment(fields.tqrDate).format("YYYY-MM-DD"),
				tqrValue: fields.tqrValue?.value,
			});
			navigate("/measurations/tqr");
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate("/measurations/tqr"),
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: saveTQR,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [{label: "TQR", path: "/measurations/tqr"}];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="row no-margin">
				<div className="col-sm-12 padding-small">
					<VLFCard
						title="Informazioni principali"
						content={
							<div className="row">
								<div className="col-sm-12 col-md-6">
									<VLFDatePicker
										label="Data"
										value={fields.tqrDate}
										onChange={(value) => {
											setFields({...fields, tqrDate: value});
										}}
										required
										size="small"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<VLFSelectNew
										options={tqrOptions}
										value={fields.tqrValue}
										onChange={(value) => {
											setFields({...fields, tqrValue: value});
										}}
										required
										label="TQR"
										sorted={false}
									/>
								</div>
							</div>
						}
					/>
				</div>
			</div>
		</Page>
	);
}

export default HandleTQRPage;
