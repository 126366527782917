import React from "react";

import "./index.scss";

function EmptyData() {
	return (
		<div className="empty-data-container padding-small">
			Nessun dato presente
		</div>
	);
}

export default EmptyData;
