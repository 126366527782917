import React from "react";

import "./index.scss";

const options = [
	{value: "new", label: "Inserisci nuovo elemento"},
	{value: "select", label: "Seleziona esistente"},
];

function CreateSelectEntity(props) {
	const {currentOption, setCurrentOption} = props;

	return (
		<div className="choose-saved-container">
			{options.map((o, k) => {
				return (
					<div
						key={k}
						onClick={() => setCurrentOption(o.value)}
						className={
							"save-option" + (o.value === currentOption ? " selected" : "")
						}
					>
						{o.label}
					</div>
				);
			})}
		</div>
	);
}

export default CreateSelectEntity;
