import axios from "axios";
import store from "@/store";

const createAxiosInstanceWithAuthInterceptor = () => {
	const instance = axios.create({
		headers: {
			"Content-Type": "application/json",
		},
	});

	instance.interceptors.request.use(
		(config) => {
			const token = store.getState().token;
			if (token && config.useAuthInterceptor) {
				config.headers.Authorization = `Bearer ${token}`;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		},
	);

	return instance;
};

const api = createAxiosInstanceWithAuthInterceptor();

export default api;
