import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import VLFModal from "@/components/Modal";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {UserRoleAPI} from "@/api/user_role";

import {ERROR_OPERATION, SUCCESSFUL_OPERATION} from "@/utils/constants";

function UserRolePage(props) {
	const {handleNotification} = props;
	const {id} = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [userRoleData, setUserRoleData] = useState({});

	const getUserRole = async () => {
		setLoading(true);
		try {
			const response = await UserRoleAPI.getById(id);
			setUserRoleData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getUserRole();
	}, []);

	const deleteUserRole = async () => {
		setLoading(true);
		try {
			await UserRoleAPI.delete(userRoleData?.name);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/settings/user-roles");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const mainConfiguration = [
		{
			value: userRoleData?.name,
			label: "Nome",
			size: "col-12 col-sm-6 col-lg-4",
		},
	];

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate("/settings/user-roles"),
			color: "info",
		},
		// {
		// 	icon: <EditIcon />,
		// 	title: "Modifica",
		// 	onClick: () =>
		// 		navigate("/settings/user-roles/" + userRoleData?.name + "/edit"),
		// },
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => setOpenHandleConfirm(true),
			color: "error",
		},
	];

	const breadcrumbsElements = [
		{label: "Ruoli utente", path: "/settings/user-roles"},
		{label: "/"},
	];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={userRoleData ? userRoleData.name : ""}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={mainConfiguration} />
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => deleteUserRole()}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default UserRolePage;
