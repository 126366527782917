import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

import VLFTextField from "@/components/Input/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

import {AuthorizationAPI} from "@/api/authorization";

import {loginSuccess} from "@/actions";
import Logo from "@/assets/images/logo.png";

function LoginPage(props) {
	const {handleNotification} = props;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isLoggedIn = useSelector((state) => state.isLoggedIn);

	useEffect(() => {
		if (isLoggedIn) {
			navigate("/");
		}
	}, [isLoggedIn, navigate]);

	const [formData, setFormData] = useState({username: "", password: ""});
	const [loading, setLoading] = useState(false);
	// const [showPassword, setShowPassword] = useState(false);
	const [error, setError] = useState(false);

	// const handleClickShowPassword = () => setShowPassword((show) => !show);
	// const handleMouseDownPassword = (event) => {
	// 	event.preventDefault();
	// };

	const handleLogin = async () => {
		setLoading(true);
		try {
			const response = await AuthorizationAPI.login(formData);
			dispatch(loginSuccess(response.token));
			localStorage.setItem("token", response.token);
			navigate("/");
		} catch (error) {
			setError(true);
			handleNotification("Username e/o password non corretti", "error");
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (event) => {
		const {name, value} = event.target;
		setError(false);
		setFormData({...formData, [name]: value});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		handleLogin();
	};

	return (
		<div className="login-page-container">
			<div className="background"></div>
			<div className="login-container">
				<div className="form-container">
					<img className="logo" src={Logo} alt="logo" />
					<form className="w-100" onSubmit={handleSubmit}>
						<VLFTextField
							name="username"
							value={formData.username}
							onChange={handleChange}
							label="Username"
							size="small"
							required
							onlyLabel
							error={error}
						/>
						<VLFTextField
							name="password"
							value={formData.password}
							onChange={handleChange}
							type={"password"}
							label="Password"
							size="small"
							required
							onlyLabel
							error={error}
						/>
						<div className="action mt-4">
							<LoadingButton
								type="submit"
								variant="contained"
								size="large"
								loading={loading}
							>
								Login
							</LoadingButton>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default LoginPage;
