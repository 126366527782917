import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import {MatchAPI} from "@/api/match";

import AddIcon from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";

import {
	ERROR_OPERATION,
	activityTypesOptions,
	dateFormat,
	matchTypesOptions,
} from "@/utils/constants";
import {fetchData, isCompetitiveActivity} from "@/utils/functions";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

const dateTimeFormat = "YYYY-MM-DDTHH:mm:ss";

const defaultFiltersData = {
	fromDate: moment().startOf("isoWeek").format(dateTimeFormat),
	toDate: moment().endOf("isoWeek").format(dateTimeFormat),
	matchType: matchTypesOptions?.filter((mo) => mo.value === "Campionato")?.[0],
};

function MatchesPage(props) {
	const {handleNotification} = props;

	const navigate = useNavigate();

	const user = useSelector((state) => state?.user);
	const userRole = useSelector((state) => state?.user?.roleName);

	const [loading, setLoading] = useState(false);
	const [matches, setMatches] = useState([]);
	const [filtersData, setFiltersData] = useState(
		JSON.parse(sessionStorage.getItem("matches_filters")) || defaultFiltersData,
	);

	useEffect(() => {
		if (filtersData) {
			sessionStorage.setItem("matches_filters", JSON.stringify(filtersData));
		}
	}, [filtersData]);

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
	});

	const filters = [
		{
			key: "fromDate",
			type: "date",
			label: "Da",
			customClassName: "col-6 col-sm-3",
			clearable: false,
			value: moment(filtersData?.fromDate).format("YYYY-MM-DD"),
			setValue: (e) => {
				if (e) {
					setFiltersData({
						...filtersData,
						fromDate: moment(e)
							.set({hour: 0, minute: 0, second: 0})
							.format(dateTimeFormat),
					});
				}
			},
		},
		{
			key: "toDate",
			type: "date",
			label: "A",
			customClassName: "col-6 col-sm-3",
			clearable: false,
			value: moment(filtersData?.toDate).format("YYYY-MM-DD"),
			setValue: (e) => {
				if (e) {
					setFiltersData({
						...filtersData,
						toDate: moment(e)
							.set({hour: 23, minute: 59, second: 59})
							.format(dateTimeFormat),
					});
				}
			},
		},
		{
			key: "categoryId",
			type: "select",
			options: categories.map((c) => {
				return {
					value: c.id,
					label: c.name,
				};
			}),
			sortOrder: "descend",
			label: "Categoria",
			customClassName: "col-12 col-sm-3",
			clearable: true,
			value: filtersData?.categoryId,
			setValue: (value) => {
				setFiltersData({...filtersData, categoryId: value});
			},
		},
		{
			key: "activityTypeId",
			type: "select",
			options: activityTypesOptions,
			sortOrder: "descend",
			label: "Tipo attività",
			customClassName: "col-12 col-sm-3",
			clearable: true,
			value: filtersData?.activityTypeId,
			setValue: (value) => {
				setFiltersData({...filtersData, activityTypeId: value});
			},
		},
	];

	const getMatches = () => {
		fetchData(
			MatchAPI.list,
			{
				...filtersData,
				categoryId: filtersData?.categoryId?.value,
			},
			() => setLoading(true),
			setMatches,
			() => handleNotification(ERROR_OPERATION, "error"),
			() => setLoading(false),
		);
	};

	useEffect(() => {
		if (categories?.length) {
			getMatches();
		}
	}, [filtersData, categories]);

	let fullFilteredMatches = Array.from(matches);
	if (filtersData?.activityTypeId) {
		if (filtersData.activityTypeId.value === "base") {
			fullFilteredMatches = fullFilteredMatches.filter(
				(m) => !isCompetitiveActivity(categories, m.playersCategory),
			);
		}
		if (filtersData.activityTypeId.value === "competitive") {
			fullFilteredMatches = fullFilteredMatches.filter((m) =>
				isCompetitiveActivity(categories, m.playersCategory),
			);
		}
	}

	const groupedMatches = {};
	categories
		.sort((a, b) => a.name?.localeCompare(b.name))
		.forEach((c) => {
			const currentCategory = c.name;
			const matchesMembers = fullFilteredMatches?.filter(
				(t) => t.playersCategory === currentCategory,
			);
			groupedMatches[currentCategory] = matchesMembers;
		});

	const listConfiguration = {
		data: groupedMatches,
		onClick: (match) => navigate("/matches/" + match.id),
	};

	const [tableData, setTableData] = useState([]);
	const columns = [
		{
			label: "Data",
			key: "match.date",
			customRender: (item) => {
				return moment(item.startDate).format(dateFormat + " HH:mm");
			},
			medium: true,
		},
		{
			label: "Categoria",
			key: "match.membershipCategory",
			customRender: (item) => {
				return item.playersCategory;
			},
			small: true,
			align: "start",
		},
		{
			label: "Partita",
			key: "match.homeTeam",
			customRender: (item) => {
				return item.homeTeam + " - " + item.awayTeam;
			},
			align: "start",
		},
		{
			label: "Tipologia",
			key: "matchType",
			small: true,
			align: "start",
		},
		{label: "Risultato", key: "results", small: true},
	];

	if (user?.userName !== "burlando") {
		columns.push({
			label: "RPE",
			key: "matchRPE",
			customRender: (item) => {
				const hasRPE = parseInt(item?.playersCategory?.split(" ")?.[1]) > 13;

				return hasRPE ? (
					userRole !== "Player" ? (
						item.matchRpe.length
					) : (
						<div
							className={"cell" + (item.matchRpe.length ? " on" : " off")}
						></div>
					)
				) : (
					<></>
				);
			},
			small: true,
			align: userRole !== "Player" ? "end" : "center",
		});
	}

	const mainActions = [
		{
			icon: <ArrowBack color="primary" />,
			variant: "outlined",
			onClick: () => {
				setFiltersData({
					...filtersData,
					fromDate: moment(filtersData?.fromDate)
						.subtract(1, "isoWeeks")
						.format(dateTimeFormat),
					toDate: moment(filtersData?.toDate)
						.subtract(1, "isoWeeks")
						.format(dateTimeFormat),
				});
			},
		},
		{
			icon: <ArrowForward color="primary" />,
			variant: "outlined",
			onClick: () => {
				setFiltersData({
					...filtersData,
					fromDate: moment(filtersData?.fromDate)
						.add(1, "isoWeeks")
						.format(dateTimeFormat),
					toDate: moment(filtersData?.toDate)
						.add(1, "isoWeeks")
						.format(dateTimeFormat),
				});
			},
		},
	];

	if (userRole !== "Player" && userRole !== "Analyst") {
		mainActions.push({
			icon: <AddIcon />,
			onClick: () => navigate("/matches/new"),
		});
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={mainActions} />} />
			<CardsContainer
				tableComponent={
					<CardsTable
						filtersData={filters}
						configurationColumns={columns}
						data={fullFilteredMatches}
						fullWidth
						handleClick={(item) => {
							navigate("/matches/" + item.id);
						}}
						defaultSortedField="startDate"
						defaultSortedOrder="descend"
					/>
				}
				listComponent={
					<CardsList
						type="match"
						breadcrumbs
						grouped
						filtersData={filters}
						listConfiguration={listConfiguration}
						defaultSortedField="startDate"
						defaultSortedOrder="descend"
					/>
				}
			/>
		</Page>
	);
}

export default MatchesPage;
