import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import CardsList from "@/components/CardsList";

import {MatchAPI} from "@/api/match";

import {ERROR_OPERATION, matchTypesOptions} from "@/utils/constants";
import {fetchData} from "@/utils/functions";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

const defaultFiltersData = {
	matchType: matchTypesOptions?.filter((mo) => mo.value === "Campionato")?.[0],
};

function MatchesCalendarPage(props) {
	const {handleNotification} = props;

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [matches, setMatches] = useState([]);
	const [filtersData, setFiltersData] = useState(
		JSON.parse(sessionStorage.getItem("matches_calendar_filters")) ||
			defaultFiltersData,
	);

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		formatted: true,
	});

	useEffect(() => {
		if (filtersData) {
			sessionStorage.setItem(
				"matches_calendar_filters",
				JSON.stringify(filtersData),
			);
		}
	}, [filtersData]);

	const filters = [
		{
			key: "categoryId",
			type: "select",
			options: categories,
			sorted: false,
			label: "Categoria",
			customClassName: "col-12 col-sm-6 col-lg-4",
			clearable: false,
			value: filtersData?.categoryId,
			setValue: (value) => {
				setFiltersData({...filtersData, categoryId: value});
			},
		},
		{
			key: "matchType",
			type: "select",
			options: matchTypesOptions,
			label: "Tipologia",
			customClassName: "col-12 col-sm-6 col-lg-4",
			clearable: true,
			value: filtersData?.matchType,
			setValue: (value) => {
				setFiltersData({...filtersData, matchType: value});
			},
		},
	];

	const getMatches = () => {
		fetchData(
			MatchAPI.list,
			{
				categoryId: filtersData?.categoryId?.value,
			},
			() => setLoading(true),
			setMatches,
			() => handleNotification(ERROR_OPERATION, "error"),
			() => setLoading(false),
		);
	};

	useEffect(() => {
		if (categories?.length) {
			getMatches();
		}
	}, [filtersData, categories]);

	const listConfiguration = {
		data: matches
			?.filter((m) => {
				return (
					!filtersData?.matchType ||
					filtersData?.matchType?.value === m.matchType
				);
			})
			?.filter((t) => t.playersCategoryId === filtersData?.categoryId?.value),
		onClick: (match) => navigate("/matches/" + match.id),
	};

	return (
		<Page>
			<Loading visible={loading} />
			<CardsList
				type="match_calendar"
				forceOpened
				filtersData={filters}
				listConfiguration={listConfiguration}
				defaultSortedField="startDate"
				defaultSortedOrder="ascend"
			/>
		</Page>
	);
}

export default MatchesCalendarPage;
