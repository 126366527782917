import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import AddIcon from "@mui/icons-material/Add";

import {ProspectAPI} from "@/api/prospect";

import {ERROR_OPERATION} from "@/utils/constants";

function ScoutCoachesPage(props) {
	const {handleNotification} = props;

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [coaches, setCoaches] = useState([]);

	const getCoaches = async () => {
		setLoading && setLoading(true);
		try {
			const response = await ProspectAPI.listCoach();
			setCoaches(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading && setLoading(false);
		}
	};

	useEffect(() => {
		getCoaches();
	}, []);

	const listConfiguration = {
		data: coaches,
		onClick: (coach) => navigate("/scout-coaches/" + coach.id),
	};

	const columns = [
		{label: "Nome", key: "firstName", align: "start"},
		{label: "Cognome", key: "lastName", align: "start"},
		{
			label: "Categoria",
			key: "playersCategory.name",
			align: "start",
			medium: true,
		},
		{label: "Squadra", key: "prospectTeam.name", align: "start"},
	];

	const headerActions = [
		{
			icon: <AddIcon />,
			title: "Aggiungi",
			onClick: () => navigate("/scout-coaches/new"),
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={headerActions} />} />
			<CardsContainer
				tableComponent={
					<CardsTable
						breadcrumbs
						fullWidth
						configurationColumns={columns}
						data={coaches}
						handleClick={(coach) => navigate("/scout-coaches/" + coach.id)}
						defaultSortedField="lastName"
						defaultSortedOrder="ascend"
					/>
				}
				listComponent={
					<CardsList
						type="scout_coach"
						breadcrumbs
						listConfiguration={listConfiguration}
						defaultSortedField="lastName"
						defaultSortedOrder="ascend"
					/>
				}
			/>
		</Page>
	);
}

export default ScoutCoachesPage;
