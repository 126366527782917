import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import VLFModal from "@/components/Modal";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import PasswordIcon from "@mui/icons-material/Password";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import {UserAPI} from "@/api/user";

import {ERROR_OPERATION, SUCCESSFUL_OPERATION} from "@/utils/constants";
import {usePlayers, useStaffs} from "@/utils/hooks";

function UserPage(props) {
	const {handleNotification} = props;
	const {id} = useParams();
	const navigate = useNavigate();

	const {players} = usePlayers({setLoading: false, handleNotification});
	const {staffs} = useStaffs({setLoading: false, handleNotification});

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [openResetPassword, setOpenResetPassword] = useState(false);
	const [openHandleUser, setOpenHandleUser] = useState(false);
	const [userData, setUserData] = useState({});

	const getUser = async () => {
		setLoading(true);
		try {
			const response = await UserAPI.getById(id);
			setUserData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getUser();
	}, []);

	const deleteUser = async () => {
		setLoading(true);
		try {
			await UserAPI.delete(userData?.userName);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/settings/users");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const resetPassword = async () => {
		setLoading(true);
		try {
			await UserAPI.resetPassword({userName: userData?.userName});
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setOpenResetPassword(false);
			setLoading(false);
		}
	};

	const handleUser = async () => {
		setLoading(true);
		try {
			await UserAPI.setLock({
				userName: userData?.userName,
				setLock: !userData?.isLockedOut,
			});
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getUser();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleUser(false);
		}
	};

	const mainConfiguration = [
		{
			value: userData?.userName,
			label: "Username",
			size: "col-12 col-sm-6 col-lg-4",
		},
		{
			value: userData?.email,
			label: "Email",
			size: "col-12 col-sm-6 col-lg-4",
		},
		{
			value: userData?.telephone,
			label: "Telefono",
			size: "col-12 col-sm-6 col-lg-4",
		},
		{
			value: userData?.role?.name,
			label: "Ruolo",
			size: "col-12 col-sm-6 col-lg-4",
		},
	];

	if (userData?.playerId || userData?.staffId) {
		let currentItem = {};

		if (userData?.playerId) {
			currentItem = players?.filter((p) => p.id === userData.playerId)?.[0];
		} else {
			currentItem = staffs?.filter((s) => s.id === userData.staffId)?.[0];
		}

		mainConfiguration.push({
			value: currentItem?.firstName + " " + currentItem?.lastName,
			label: userData?.playerId ? "Portiere associato" : "Staff associato",
			size: "col-12 col-sm-6 col-lg-4",
		});
	} else {
		mainConfiguration.push({
			value: "---",
			label: "Staff/portiere associato",
			size: "col-12 col-sm-6 col-lg-4",
		});
	}

	mainConfiguration.push(
		{
			value:
				moment(userData?.lastLoginDate).year() > 2000 &&
				moment(userData?.lastLoginDate).format("DD/MM/YYYY HH:mm"),
			label: "Ultimo login",
			size: "col-12 col-sm-6 col-lg-4",
		},
		{
			value:
				moment(userData?.lastPasswordChangedDate).year() > 2000 &&
				moment(userData?.lastPasswordChangedDate).format("DD/MM/YYYY HH:mm"),
			label: "Ultimo cambio password",
			size: "col-12 col-sm-6 col-lg-4",
		},
		{
			value:
				moment(userData?.lastLockoutDate).year() > 2000 &&
				moment(userData?.lastLockoutDate).format("DD/MM/YYYY HH:mm"),
			label: "Ultimo blocco",
			size: "col-12 col-sm-6 col-lg-4",
		},
		{
			value: (
				<div className={"cell" + (userData?.isApproved ? " on" : " off")}>
					{userData?.isApproved}
				</div>
			),
			label: "Approvato",
			size: "col-12 col-sm-6 col-lg-4",
		},
		{
			value: userData?.isLockedOut && <div className={"cell off"} />,
			label: "Bloccato",
			size: "col-12 col-sm-6 col-lg-4",
		},
	);

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate("/settings/users"),
			color: "info",
		},
		// {
		// 	icon: <EditIcon />,
		// 	title: "Modifica",
		// 	onClick: () =>
		// 		navigate("/settings/users/" + userData?.id + "/edit"),
		// },
		{
			icon: <PasswordIcon />,
			onClick: () => setOpenResetPassword(true),
		},
		{
			icon: userData.isLockedOut ? <LockOpenIcon /> : <LockIcon />,
			onClick: () => setOpenHandleUser(true),
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => setOpenHandleConfirm(true),
			color: "error",
		},
	];

	const breadcrumbsElements = [
		{label: "Utenti", path: "/settings/users"},
		{label: "/"},
	];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={userData ? userData.userName : ""}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={mainConfiguration} />
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => deleteUser()}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
			<VLFModal
				open={openResetPassword}
				title="Reset password"
				content={
					<div className="mt-2">
						Sei sicuro di voler effettuare il reset della password?
					</div>
				}
				confirm={() => resetPassword()}
				close={() => setOpenResetPassword(false)}
				confirmLabel="Conferma"
			/>
			<VLFModal
				open={openHandleUser}
				title={userData?.isLockedOut ? "Sblocca utente" : "Blocca utente"}
				content={
					<div className="mt-2">
						{userData?.isLockedOut
							? "Sei sicuro di voler sbloccare l'utente?"
							: "Sei sicuro di voler bloccare l'utente?"}
					</div>
				}
				confirm={() => handleUser()}
				close={() => setOpenHandleUser(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default UserPage;
