import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFSelectNew from "@/components/Input/SelectNew";

import {ProspectAPI} from "@/api/prospect";

import ArrowBack from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {useCategories} from "@/utils/hooks";
import {
	checkRequiredFields,
	getTextField,
	getTextAreaField,
} from "@/utils/functions";
import {
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
	REQUIRED_FIELD,
} from "@/utils/constants";

import "./index.scss";

const HandleScoutCoachPage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({});

	const [clubs, setClubs] = useState([]);

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		formatted: true,
	});

	const getCoach = async () => {
		setLoading(true);
		try {
			const response = await ProspectAPI.getByIdCoach(id);
			response.prospectTeam = {
				value: response.prospectTeam.id,
				label: response.prospectTeam.name,
			};
			response.playersCategory = {
				value: response.playersCategory.id,
				label: response.playersCategory.name,
			};
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const getClubs = async () => {
		try {
			const response = await ProspectAPI.teamsList();
			setClubs(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	useEffect(() => {
		if (edit && clubs?.length && categories?.length) {
			getCoach();
		}
		getClubs();
	}, [clubs?.length, categories?.length, edit]);

	const getRequiredFields = () => {
		return ["lastName", "playersCategory", "prospectTeam"];
	};

	const createClub = async (name) => {
		try {
			const response = await ProspectAPI.createTeam({name});
			setFields({
				...fields,
				prospectTeam: {value: response.id, label: response.name},
			});
			getClubs();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const saveCoach = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);
		setErrors(errors);

		if (!errors.length) {
			let body = Object.assign({}, fields);
			body = {
				...fields,
				firstName: fields.firstName ? fields.firstName : "",
				playersCategoryId: fields.playersCategory?.value,
				prospectTeamId: fields.prospectTeam?.value,
			};

			delete body.prospectTeam;
			delete body.playersCategory;

			setLoading(true);

			try {
				edit
					? await ProspectAPI.updateCoach(id, body)
					: await ProspectAPI.createCoach(body);
				navigate("/scout-coaches");
				handleNotification(SUCCESSFUL_OPERATION, "success");
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveCoach();
	};

	const goBack = () => {
		navigate("/scout-coaches");
	};

	const mainActions = [
		{icon: <ArrowBack />, title: "Indietro", onClick: goBack, color: "info"},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const defaultTextField = {
		values: fields,
		setValues: setFields,
		errors,
		setErrors,
	};

	const coachLabel =
		fields?.firstName || fields?.lastName
			? (fields.firstName || "") + " " + (fields.lastName || "")
			: "";

	const breadcrumbsElements = [{label: "Allenatori", path: "/scout-coaches"}];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: coachLabel,
				path: "/scout-coaches/" + id,
			},
		);
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<VLFCard
					title="Informazioni principali"
					content={
						<div className="row no-margin">
							<div className="col-12 col-md-6 padding-small">
								{getTextField({
									...defaultTextField,
									fieldKey: "firstName",
									label: "Nome",
								})}
							</div>
							<div className="col-12 col-md-6 padding-small">
								{getTextField({
									...defaultTextField,
									fieldKey: "lastName",
									label: "Cognome",
									required: true,
								})}
							</div>
							<div className="col-12 col-md-6 padding-small">
								<VLFSelectNew
									options={categories}
									sortOrder="descend"
									value={fields.playersCategory}
									onChange={(newValue) => {
										setErrors([]);
										setFields({...fields, playersCategory: newValue});
									}}
									label="Categoria"
									required
									helperText={
										errors.indexOf("playersCategory") !== -1
											? REQUIRED_FIELD
											: ""
									}
									error={errors.indexOf("playersCategory") !== -1}
								/>
							</div>
							<div className="col-12 col-md-6 padding-small">
								<VLFSelectNew
									options={clubs.map((c) => {
										return {
											value: c.id,
											label: c.name,
										};
									})}
									value={fields.prospectTeam}
									onChange={(newValue) => {
										setErrors([]);
										setFields({...fields, prospectTeam: newValue});
									}}
									onCreateOption={createClub}
									label="Squadra"
									required
									helperText={
										errors.indexOf("prospectTeam") !== -1 ? REQUIRED_FIELD : ""
									}
									error={errors.indexOf("prospectTeam") !== -1}
								/>
							</div>
							<div className="col-12 col-md-12 padding-small">
								{getTextAreaField({
									...defaultTextField,
									fieldKey: "notes",
									label: "Note",
									minRows: 3,
									maxRows: 3,
								})}
							</div>
						</div>
					}
				/>
				{/* <VLFCard
					className="mt-2"
					title="Informazioni squadra"
					content={
						<div className="row no-margin">
							<div className="col-12 padding-small">
								<div className="choose-saved-container">
									{entitySaveOptions.map((es, k) => {
										return (
											<div
												key={k}
												onClick={() => setSaveOption(es.value)}
												className={
													"save-option" +
													(es.value === saveOption ? " selected" : "")
												}
											>
												{es.label}
											</div>
										);
									})}
								</div>
							</div>
							{saveOption === "new" ? (
								<>
									<div className="col-12 col-md-6 padding-small">
										{getTextField({
											...defaultTextField,
											fieldKey: "clubName",
											label: "Nome",
											required: true,
										})}
									</div>
									<div className="col-12 col-md-6 padding-small">
										<VLFSelectNew
											options={categories.map((c) => {
												return {
													value: c.id,
													label: c.name,
												};
											})}
											value={fields.clubCategory}
											onChange={(newValue) => {
												setErrors([]);
												setFields({...fields, clubCategory: newValue});
											}}
											label="Categoria"
											required
										/>
									</div>
									<div className="col-12 col-md-6 padding-small">
										<VLFSelectNew
											options={seasons}
											value={fields.clubSeason}
											onChange={(newValue) => {
												setErrors([]);
												setFields({...fields, clubSeason: newValue});
											}}
											label="Stagione"
											required
										/>
									</div>
								</>
							) : (
								<div className="col-12 col-md-6 padding-small">
									<VLFSelectNew
										options={clubs.map((c) => {
											return {
												value: c.id,
												label:
													c.name +
													" - " +
													c.playersCategory?.name +
													" (" +
													c.season?.name +
													")",
											};
										})}
										value={fields.prospectTeam}
										onChange={(newValue) => {
											setErrors([]);
											setFields({...fields, prospectTeam: newValue});
										}}
										label="Squadra"
										required
									/>
								</div>
							)}
						</div>
					}
				/> */}
			</div>
		</Page>
	);
};

export default HandleScoutCoachPage;
