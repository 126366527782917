import api from "./configuration";
import apiUrl from "./url";

const USE_AUTH_INTERCEPTOR = true;

export const MedicalAPI = {
	staffsList: async (params) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/MedicalStaff",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params,
		});

		return response.data;
	},
	staffCreate: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/MedicalStaff",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	staffGetById: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/MedicalStaff/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	staffDelete: async (id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/MedicalStaff/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	staffUpdate: async (id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/MedicalStaff/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	recordsList: async (params) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/MedicalRecords",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params,
		});

		return response.data;
	},
	recordCreate: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/MedicalRecords",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	recordGetById: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/MedicalRecords/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	recordDelete: async (id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/MedicalRecords/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	injuryCreate: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/MedicalRecords/injuries",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	injuryUpdate: async (recordId, id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/MedicalRecords/" + recordId + "/injuries/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	injuryDelete: async (recordId, id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/MedicalRecords/" + recordId + "/injuries/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
};
