import React, {useEffect, useState} from "react";
import moment from "moment";

import VLFModal from "@/components/Modal";
import VLFSelectNew from "@/components/Input/SelectNew";

import {REQUIRED_FIELD, dateFormat} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";
import {useTrainings} from "@/utils/hooks";

const requiredFields = ["training"];

function HandleCopyExercisesModal(props) {
	const {open, confirm, close, handleNotification} = props;

	const [formData, setFormData] = useState({
		training: null,
	});
	const [errors, setErrors] = useState([]);

	const {trainings} = useTrainings({
		setLoading: false,
		handleNotification,
		filters: {startDate: moment().subtract(1, "months").format("YYYY-MM-DD")},
	});

	useEffect(() => {
		if (!open) {
			setFormData({});
		}
	}, [open]);

	return (
		<VLFModal
			maxWidth="sm"
			open={open}
			title={"Seleziona allenamento"}
			close={close}
			confirm={() => {
				const errors = checkRequiredFields(requiredFields, formData);
				setErrors(errors);

				if (!errors.length) {
					confirm(formData);
				}
			}}
			content={
				<div className="row">
					<div className="col-12">
						<VLFSelectNew
							options={trainings
								.map((t) => {
									return {
										value: t.id,
										label:
											moment(t.startDate).format(dateFormat + " HH:mm") +
											" - " +
											t.playersCategory,
									};
								})
								.reverse()}
							value={formData.training || ""}
							onChange={(value) => {
								setErrors([]);
								setFormData({...formData, training: value});
							}}
							sorted={false}
							required
							helperText={
								errors.indexOf("training") !== -1 ? REQUIRED_FIELD : ""
							}
							error={errors.indexOf("training") !== -1}
							label={"Allenamento"}
						/>
					</div>
				</div>
			}
		/>
	);
}

export default HandleCopyExercisesModal;
