import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";

import {ExerciseAPI} from "@/api/exercise";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {SUCCESSFUL_OPERATION, ERROR_OPERATION} from "@/utils/constants";
import {
	checkRequiredFields,
	getTextField,
	getTextAreaField,
} from "@/utils/functions";

const HandleExercisePage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({});

	const getExercise = async () => {
		setLoading(true);
		try {
			const response = await ExerciseAPI.getById(id);
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit) {
			getExercise();
		}
	}, [edit]);

	const getRequiredFields = () => {
		return ["name"];
	};

	const saveExercise = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);
		setErrors(errors);

		if (!errors.length) {
			setLoading(true);

			let body = Object.assign({}, fields);
			body.exerciseType = body.exerciseType || "";
			body.duration = body.duration || 0;
			body.description = body.description || "";

			try {
				const response = edit
					? await ExerciseAPI.update(id, body)
					: await ExerciseAPI.create(body);
				setTimeout(() => {
					navigate(`/exercises/${response?.id || id}`);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveExercise();
	};

	const goBack = () => {
		if (edit) {
			navigate("/exercises/" + id);
		} else {
			navigate("/exercises");
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [{label: "Esercizi", path: "/exercises"}];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: fields ? fields.name : "",
				path: "/exercises/" + id,
			},
		);
	}

	const defaultTextField = {
		values: fields,
		setValues: setFields,
		errors,
		setErrors,
	};

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="row no-margin">
				<div className="col-sm-12 padding-small">
					<VLFCard
						title="Informazioni principali"
						content={
							<div className="row">
								<div className="col-sm-12 col-md-6">
									{getTextField({
										...defaultTextField,
										fieldKey: "name",
										label: "Nome",
										required: true,
									})}
								</div>
								<div className="col-sm-12 col-md-6">
									{getTextField({
										...defaultTextField,
										fieldKey: "exerciseType",
										label: "Tipologia",
									})}
								</div>
								<div className="col-sm-12 col-md-6">
									{getTextField({
										...defaultTextField,
										fieldKey: "duration",
										label: "Durata (minuti)",
										type: "number",
									})}
								</div>
								<div className="col-sm-12 col-md-6">
									{getTextAreaField({
										...defaultTextField,
										fieldKey: "description",
										label: "Descrizione",
										minRows: 5,
										maxRows: 10,
									})}
								</div>
							</div>
						}
						openable={false}
					/>
				</div>
			</div>
		</Page>
	);
};

export default HandleExercisePage;
