import React, {useEffect, useState} from "react";

import VLFModal from "@/components/Modal";
import VLFSelectNew from "@/components/Input/SelectNew";
import VLFTextField from "@/components/Input/TextField";

import {REQUIRED_FIELD} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";
import {usePlayers} from "@/utils/hooks";

const requiredFields = ["player", "minutes"];

function HandleStatModal(props) {
	const {open, initialData, confirm, close, handleNotification} = props;

	const [formData, setFormData] = useState({
		player: null,
	});
	const [errors, setErrors] = useState([]);

	const {players} = usePlayers({setLoading: false, handleNotification});

	useEffect(() => {
		if (initialData && players?.length) {
			const currentPlayer = players?.filter(
				(p) => p.id === initialData.playerId,
			)?.[0];
			initialData.player = currentPlayer
				? {
						value: currentPlayer?.id,
						label:
							currentPlayer?.firstName +
							" " +
							currentPlayer?.lastName +
							" (" +
							currentPlayer?.category +
							")",
					}
				: null;
			setFormData(initialData);
		}
	}, [initialData, players]);

	useEffect(() => {
		if (!open) {
			setFormData({});
		}
	}, [open]);

	return (
		<VLFModal
			maxWidth="lg"
			open={open}
			title={initialData ? "Modifica informazione" : "Aggiungi informazione"}
			close={close}
			confirm={() => {
				const errors = checkRequiredFields(requiredFields, formData);
				setErrors(errors);

				if (!errors.length) {
					confirm(formData, initialData?.id);
				}
			}}
			content={
				<div className="row">
					<div className="col-12 col-md-6">
						<VLFSelectNew
							options={players
								.filter(
									(p) =>
										(initialData.attendances?.length &&
											initialData.attendances.filter((a) => a.playerId === p.id)
												?.length) ||
										!initialData.attendances?.length,
								)
								.map((p) => {
									return {
										value: p.id,
										label:
											p.firstName + " " + p.lastName + " (" + p.category + ")",
									};
								})}
							value={formData.player || ""}
							onChange={(value) => {
								setErrors([]);
								setFormData({...formData, player: value});
							}}
							required
							helperText={errors.indexOf("player") !== -1 ? REQUIRED_FIELD : ""}
							error={errors.indexOf("player") !== -1}
							label={"Portiere"}
						/>
					</div>
					<div className="col-12 col-md-3">
						<VLFTextField
							name="minutes"
							value={formData.minutes || ""}
							onChange={(event) => {
								setErrors([]);
								setFormData({...formData, minutes: event.target.value});
							}}
							label="Minuti"
							size="small"
							type="number"
							required
							helperText={
								errors.indexOf("minutes") !== -1 ? REQUIRED_FIELD : ""
							}
							error={errors.indexOf("minutes") !== -1}
						/>
					</div>
					<div className="col-12 col-md-3">
						<VLFTextField
							name="goalsConceded"
							value={
								formData.goalsConceded !== null &&
								formData.goalsConceded !== undefined
									? formData.goalsConceded
									: ""
							}
							onChange={(event) => {
								setFormData({...formData, goalsConceded: event.target.value});
							}}
							label="Gol subiti"
							size="small"
							type="number"
						/>
					</div>
				</div>
			}
		/>
	);
}

export default HandleStatModal;
