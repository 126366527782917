import React from "react";

import Button from "@mui/material/Button";

function PageHeader(props) {
	const {title, actions} = props;

	return (
		<div className="row no-margin">
			<div className="col-sm-12 padding-small">
				<div className="page-header d-flex align-items-center justify-content-between">
					<div className="title">{title}</div>
					{actions?.length ? (
						<div className="actions">
							{actions.map((action, key) => {
								return (
									<div key={key} className="action">
										<Button
											variant="contained"
											color={action.color || "blue"}
											size="small"
											onClick={action.onClick}
											disabled={action.disabled}
										>
											<div className="action-content d-flex align-items-center">
												<div>{action.icon && action.icon}</div>
												<div>{action.title && action.title}</div>
											</div>
										</Button>
									</div>
								);
							})}
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}

function Page(props) {
	const {children, className, header} = props;

	return (
		<div className="page-content">
			<div className={"page-body" + (className ? " " + className : "")}>
				{header?.visible ? (
					<PageHeader title={header?.title} actions={header?.actions} />
				) : null}
				<div className="page-children">{children}</div>
			</div>
		</div>
	);
}

export default Page;
