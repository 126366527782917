import React from "react";
import moment from "moment";

import {
	Bar,
	BarChart,
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

import EmptyData from "@/components/EmptyData";

import "./index.scss";

function ChartContainer(props) {
	const {type, title, data, dataKey, chartItems} = props;

	const formatDate = (date) => moment(date).format("DD/MM/YYYY");

	let maximumValue = 0;
	const readyData = [];
	data.forEach((d) => {
		const element = Object.assign({}, d);
		chartItems?.forEach((i) => {
			if (element[i.value] > maximumValue) {
				maximumValue = element[i.value];
			}

			if (element[i.value] === 0) {
				element[i.value] = null;
			}
		});
		readyData.push(element);
	});

	const yTicks = [];
	for (let i = 0; i < maximumValue + 5; i++) {
		yTicks.push(i);
	}

	const getChart = () => {
		switch (type) {
			case "line":
				return (
					<ResponsiveContainer>
						<LineChart data={readyData}>
							{chartItems.map((ck) => {
								return (
									<Line
										key={ck.value}
										type="monotone"
										dataKey={ck.value}
										stroke={ck.color}
										name={ck.label}
										dot={{r: 3}}
									/>
								);
							})}
							<CartesianGrid stroke="#ccc" />
							<XAxis
								dataKey={dataKey}
								tickFormatter={dataKey === "date" ? formatDate : undefined}
							/>
							<YAxis domain={[0, maximumValue + 5]} ticks={yTicks} />
							<Tooltip
								labelFormatter={dataKey === "date" ? formatDate : undefined}
							/>
						</LineChart>
					</ResponsiveContainer>
				);
			case "bar":
				return (
					<ResponsiveContainer>
						<LineChart data={readyData}>
							{chartItems.map((ck) => {
								return (
									<Line
										key={ck.value}
										type="monotone"
										dataKey={ck.value}
										stroke={ck.color}
										name={ck.label}
										dot={{r: 3}}
									/>
								);
							})}
							<CartesianGrid stroke="#ccc" />
							<XAxis
								dataKey={dataKey}
								tickFormatter={dataKey === "date" ? formatDate : undefined}
							/>
							<YAxis domain={[0, maximumValue + 5]} ticks={yTicks} />
							<Tooltip
								labelFormatter={dataKey === "date" ? formatDate : undefined}
							/>
							{chartItems.map((ck) => {
								return (
									<Bar
										key={ck.value}
										dataKey={ck.value}
										fill={ck.color}
										name={ck.label}
										barSize={20}
									/>
								);
							})}
						</LineChart>
					</ResponsiveContainer>
				);
			default:
				return <></>;
		}
	};

	return (
		<div className="chart-container">
			{title && <div className="chart-title">{title}</div>}
			{data?.length ? getChart() : <EmptyData />}
		</div>
	);
}

export default ChartContainer;
