import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";

import VLFModal from "@/components/Modal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import VLFSelectNew from "@/components/Input/SelectNew";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";

import {Collapse} from "@mui/material";
import {ExpandLess, ExpandMore, Inbox, StarBorder} from "@mui/icons-material";

import {logout, setPlayerTab, setSelectedMenuItem} from "@/actions";

import {headerMenuItems} from "@/utils/constants";

function SideMenu(props) {
	const dispatch = useDispatch();
	const location = useLocation();

	const selectedMenuItem = useSelector((state) => state?.selectedMenuItem);
	const user = useSelector((state) => state?.user);
	const userRole = useSelector((state) => state?.user?.roleName);
	const playerId = useSelector((state) => state?.user?.playerId);

	const [openMobileMenu, setOpenMobileMenu] = useState(false);
	const [openHandleLogout, setOpenHandleLogout] = useState(false);
	const [openSubMenu, setOpenSubMenu] = useState(false);

	const visibleHeaderMenuItems = headerMenuItems.filter(
		(item) => item.roles.indexOf(userRole?.toLowerCase()) !== -1,
	);

	const getItemClassName = (path) => {
		return location.pathname.indexOf(path) !== -1 ? "selected" : "";
	};

	const getUserInfo = () => {
		return (
			<div className="user-info-container">
				<div className="d-flex align-items-center">
					<div className="user-icon-container">
						<img
							className="user-icon"
							src={require("@/assets/images/user.png")}
							alt=""
						/>
					</div>
					<div className="user-container d-flex flex-column">
						<div className="user-data">{user?.userName}</div>
						<div className="user-role">{user?.roleName}</div>
					</div>
				</div>
				<Button
					variant="outlined"
					color="primary"
					size="small"
					onClick={() => setOpenHandleLogout(true)}
				>
					<LogoutIcon />
				</Button>
			</div>
		);
	};

	const getListItem = (listItem) => {
		const handleItemClick = () => {
			if (listItem.subMenu) {
				setOpenSubMenu(!openSubMenu);
			} else {
				setOpenMobileMenu(false);
			}
		};
		return (
			<>
				<ListItem
					className={getItemClassName(listItem.path)}
					button
					component={listItem.subMenu ? "div" : Link}
					to={!listItem.subMenu ? listItem.path : undefined}
					onClick={handleItemClick}
				>
					<ListItemIcon>
						<img className="item-icon" src={listItem.imagePath} alt="" />
					</ListItemIcon>
					<ListItemText primary={listItem.label} />
					{listItem.subMenu && (openSubMenu ? "▲" : "▼")}
				</ListItem>

				{listItem.subMenu && openSubMenu && (
					<List component="div" disablePadding>
						{listItem.subMenu.map((subItem, index) => (
							<ListItem
								key={index}
								className={getItemClassName(subItem.path)}
								button
								component={Link}
								to={subItem.path}
								onClick={() => setOpenMobileMenu(false)}
							>
								<ListItemIcon>
									<img className="item-icon" src={subItem.imagePath} alt="" />
								</ListItemIcon>
								<ListItemText primary={subItem.label} />
							</ListItem>
						))}
					</List>
				)}
			</>
		);
	};

	const [methodologyOpen, setMethodologyOpen] = useState(false);
	const [performanceOpen, setPerformanceOpen] = useState(false);
	const [eventsOpen, setEventsOpen] = useState(false);
	const [scoutingOpen, setScoutingOpen] = useState(false);

	const handleClick = (setFunction, element) => {
		setFunction(!element);
	};

	const getList = (mobile = false) => {
		let list = <></>;

		switch (selectedMenuItem) {
			case "manager":
				list = (
					<List className={"side-container"}>
						{getListItem({
							path: "/players",
							imagePath: require("@/assets/images/manager/player.png"),
							label: "Portieri",
						})}
						<ListItem
							button
							onClick={() => handleClick(setMethodologyOpen, methodologyOpen)}
						>
							<ListItemIcon>
								<ListItemIcon>
									<img
										className="item-icon"
										src={require("@/assets/images/manager/report.png")}
										alt=""
									/>
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText primary="Metodologia" />
							{methodologyOpen ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={methodologyOpen} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{getListItem({
									path: "/exercises",
									imagePath: require("@/assets/images/manager/exercise.png"),
									label: "Esercizi",
								})}
							</List>
						</Collapse>
						<ListItem
							button
							onClick={() => handleClick(setPerformanceOpen, performanceOpen)}
						>
							<ListItemIcon>
								<ListItemIcon>
									<img
										className="item-icon"
										src={require("@/assets/images/manager/performance.png")}
										alt=""
									/>
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText primary="Performance" />
							{performanceOpen ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={performanceOpen} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{getListItem({
									path: "/measurations/tqr",
									imagePath: require("@/assets/images/coach/tqr.png"),
									label: "Recuperi",
								})}
								{getListItem({
									path: "/measurations/rpe",
									imagePath: require("@/assets/images/coach/rpe.png"),
									label: "RPE",
								})}
								{getListItem({
									path: "/athletic-tests",
									imagePath: require("@/assets/images/coach/test.png"),
									label: "Test atletici",
								})}
								{getListItem({
									path: "/measurations/vas",
									imagePath: require("@/assets/images/coach/muscle-pain.png"),
									label: "VAS",
								})}
							</List>
						</Collapse>
						{getListItem({
							path: "/medical/records",
							imagePath: require("@/assets/images/medical/record.png"),
							label: "Passaporti medici",
						})}

						<ListItem
							button
							onClick={() => handleClick(setEventsOpen, eventsOpen)}
						>
							<ListItemIcon>
								<ListItemIcon>
									<img
										className="item-icon"
										src={require("@/assets/images/manager/football.png")}
										alt=""
									/>
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText primary="Eventi" />
							{eventsOpen ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={eventsOpen} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{getListItem({
									path: "/trainings",
									imagePath: require("@/assets/images/coach/training.png"),
									label: "Allenamenti",
								})}
								{getListItem({
									path: "/match-calendar",
									imagePath: require("@/assets/images/manager/calendar.png"),
									label: "Calendario",
								})}
								{getListItem({
									path: "/matches",
									imagePath: require("@/assets/images/coach/match.png"),
									label: "Partite",
								})}
							</List>
						</Collapse>
						<ListItem
							button
							onClick={() => handleClick(setScoutingOpen, scoutingOpen)}
						>
							<ListItemIcon>
								<ListItemIcon>
									<img
										className="item-icon"
										src={require("@/assets/images/scout/search.png")}
										alt=""
									/>
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText primary="Scouting" />
							{scoutingOpen ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse
							in={scoutingOpen}
							timeout="auto"
							unmountOnExit
							className="collapsed"
						>
							<List component="div" disablePadding>
								{getListItem({
									path: "/scout/reports",
									imagePath: require("@/assets/images/scout/alert.png"),
									label: "Segnalazioni",
								})}
								{getListItem({
									path: "/scout/valuations",
									imagePath: require("@/assets/images/manager/evaluation.png"),
									label: "Valutazioni",
								})}
								{getListItem({
									path: "/scout-players",
									imagePath: require("@/assets/images/scout/archive.png"),
									label: "Database",
								})}
								{/* {getListItem({
									path: "/scout-clubs",
									imagePath: require("@/assets/images/manager/club.png"),
									label: "Club",
								})} */}
								{getListItem({
									path: "/scout-coaches",
									imagePath: require("@/assets/images/manager/staff.png"),
									label: "Allenatori",
								})}
								{getListItem({
									path: "/scout-matches",
									imagePath: require("@/assets/images/coach/match.png"),
									label: "Planning",
								})}
							</List>
						</Collapse>
						{getListItem({
							path: "/video",
							imagePath: require("@/assets/images/manager/video.png"),
							label: "Video",
						})}
						{getListItem({
							path: "/manager/targets",
							imagePath: require("@/assets/images/manager/target.png"),
							label: "Obiettivi",
						})}
					</List>
				);
				break;
			case "coach":
				list = (
					<List className={"side-container"}>
						{getListItem({
							path: "/players",
							imagePath: require("@/assets/images/manager/player.png"),
							label: "Portieri",
						})}
						<ListItem
							button
							onClick={() => handleClick(setPerformanceOpen, performanceOpen)}
						>
							<ListItemIcon>
								<ListItemIcon>
									<img
										className="item-icon"
										src={require("@/assets/images/manager/performance.png")}
										alt=""
									/>
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText primary="Performance" />
							{performanceOpen ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={performanceOpen} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{getListItem({
									path: "/exercises",
									imagePath: require("@/assets/images/manager/exercise.png"),
									label: "Esercizi",
								})}
								{user?.userName !== "burlando" &&
									getListItem({
										path: "/measurations/tqr",
										imagePath: require("@/assets/images/coach/tqr.png"),
										label: "Recuperi",
									})}
								{user?.userName !== "burlando" &&
									getListItem({
										path: "/measurations/rpe",
										imagePath: require("@/assets/images/coach/rpe.png"),
										label: "RPE",
									})}
								{getListItem({
									path: "/athletic-tests",
									imagePath: require("@/assets/images/coach/test.png"),
									label: "Test atletici",
								})}
								{user?.userName !== "burlando" &&
									getListItem({
										path: "/measurations/vas",
										imagePath: require("@/assets/images/coach/muscle-pain.png"),
										label: "VAS",
									})}
							</List>
						</Collapse>
						<ListItem
							button
							onClick={() => handleClick(setEventsOpen, eventsOpen)}
						>
							<ListItemIcon>
								<ListItemIcon>
									<img
										className="item-icon"
										src={require("@/assets/images/manager/football.png")}
										alt=""
									/>
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText primary="Eventi" />
							{eventsOpen ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={eventsOpen} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{getListItem({
									path: "/trainings",
									imagePath: require("@/assets/images/coach/training.png"),
									label: "Allenamenti",
								})}
								{getListItem({
									path: "/match-calendar",
									imagePath: require("@/assets/images/manager/calendar.png"),
									label: "Calendario",
								})}
								{getListItem({
									path: "/matches",
									imagePath: require("@/assets/images/coach/match.png"),
									label: "Partite",
								})}
							</List>
						</Collapse>
						{getListItem({
							path: "/video",
							imagePath: require("@/assets/images/manager/video.png"),
							label: "Video",
						})}
					</List>
				);
				break;
			case "player":
				list = (
					<List className={"side-container"}>
						<ListItem
							button
							onClick={() => handleClick(setPerformanceOpen, performanceOpen)}
						>
							<ListItemIcon>
								<ListItemIcon>
									<img
										className="item-icon"
										src={require("@/assets/images/manager/performance.png")}
										alt=""
									/>
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText primary="Performance" />
							{performanceOpen ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={performanceOpen} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{getListItem({
									path: "/measurations/tqr",
									imagePath: require("@/assets/images/coach/tqr.png"),
									label: "Recuperi",
								})}
								{getListItem({
									path: "/measurations/rpe",
									imagePath: require("@/assets/images/coach/rpe.png"),
									label: "RPE",
								})}
								{getListItem({
									path: "/measurations/vas",
									imagePath: require("@/assets/images/coach/muscle-pain.png"),
									label: "VAS",
								})}
							</List>
						</Collapse>
						{getListItem({
							path: "/trainings",
							imagePath: require("@/assets/images/coach/training.png"),
							label: "Allenamenti",
						})}
						{getListItem({
							path: "/matches",
							imagePath: require("@/assets/images/coach/match.png"),
							label: "Partite",
						})}
						{getListItem({
							path: "/video",
							imagePath: require("@/assets/images/manager/video.png"),
							label: "Video",
						})}
						{getListItem({
							path: "/profile/" + playerId,
							imagePath: require("@/assets/images/manager/player.png"),
							label: "Profilo",
						})}
					</List>
				);
				break;
			case "analyst":
				list = (
					<List className={"side-container"}>
						{getListItem({
							path: "/match-calendar",
							imagePath: require("@/assets/images/manager/calendar.png"),
							label: "Calendario",
						})}
						{getListItem({
							path: "/matches",
							imagePath: require("@/assets/images/coach/match.png"),
							label: "Partite",
						})}
						{getListItem({
							path: "/video",
							imagePath: require("@/assets/images/manager/video.png"),
							label: "Video",
						})}
					</List>
				);
				break;
			case "scout":
				list = (
					<List className="side-container">
						{user?.userName === "CaputoD" &&
							getListItem({
								path: "/players",
								imagePath: require("@/assets/images/manager/player.png"),
								label: "Portieri",
							})}
						{getListItem({
							path: "/scout/reports",
							imagePath: require("@/assets/images/scout/alert.png"),
							label: "Segnalazioni",
						})}
						{getListItem({
							path: "/scout/valuations",
							imagePath: require("@/assets/images/manager/evaluation.png"),
							label: "Valutazioni",
						})}
						{getListItem({
							path: "/scout-players",
							imagePath: require("@/assets/images/scout/archive.png"),
							label: "Database",
						})}
						{getListItem({
							path: "/scout-matches",
							imagePath: require("@/assets/images/coach/match.png"),
							label: "Planning",
						})}
					</List>
				);
				break;
			case "settings":
				list = (
					<List className="side-container">
						{getListItem({
							path: "/settings/categories",
							imagePath: require("@/assets/images/manager/category.png"),
							label: "Categorie",
						})}
						{getListItem({
							path: "/settings/clubs",
							imagePath: require("@/assets/images/manager/club.png"),
							label: "Club",
						})}
						{getListItem({
							path: "/settings/staff-roles",
							imagePath: require("@/assets/images/manager/staff.png"),
							label: "Ruoli staff",
						})}
						{getListItem({
							path: "/settings/user-roles",
							imagePath: require("@/assets/images/manager/staff.png"),
							label: "Ruoli utente",
						})}
						{getListItem({
							path: "/settings/staffs",
							imagePath: require("@/assets/images/manager/staff.png"),
							label: "Staff",
						})}
						{getListItem({
							path: "/settings/users",
							imagePath: require("@/assets/images/user.png"),
							label: "Utenti",
						})}
					</List>
				);
				break;
			default:
				break;
		}

		if (mobile) {
			return (
				<div className="d-flex flex-column">
					<VLFSelectNew
						controlStyles={{margin: "10px 5px"}}
						options={visibleHeaderMenuItems}
						isClearable={false}
						sorted={false}
						value={visibleHeaderMenuItems.filter(
							(hm) => hm.value === selectedMenuItem,
						)}
						onChange={(item) => {
							dispatch(setSelectedMenuItem(item.value));
						}}
					/>
					{list}
					{getUserInfo()}
				</div>
			);
		}

		return list;
	};

	return (
		<>
			<div className="side-menu-container">
				<div className="hide-mobile">{getUserInfo()}</div>
				<div
					className="sandwich"
					onClick={() => setOpenMobileMenu(!openMobileMenu)}
				>
					<MenuIcon />
				</div>
				<Drawer
					variant="temporary"
					anchor="left"
					open={openMobileMenu}
					onClose={() => setOpenMobileMenu(!setOpenMobileMenu)}
				>
					{getList(true)}
				</Drawer>
				{getList()}
			</div>
			{openHandleLogout && (
				<VLFModal
					open={openHandleLogout}
					title="Logout"
					content={
						<div className="mt-2">
							Sei sicuro di voler effettuare il logout?
						</div>
					}
					confirm={() => {
						localStorage.setItem("token", "");
						setOpenHandleLogout(false);
						dispatch(logout());
					}}
					close={() => setOpenHandleLogout(false)}
					confirmLabel="Conferma"
				/>
			)}
		</>
	);
}

export default SideMenu;
