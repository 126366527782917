import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";

import {ClubAPI} from "@/api/club";

import ArrowBack from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {checkRequiredFields, getTextField} from "@/utils/functions";
import {SUCCESSFUL_OPERATION, ERROR_OPERATION} from "@/utils/constants";

import "./index.scss";

const HandleClubPage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({});

	const getClub = async () => {
		setLoading(true);
		try {
			const response = await ClubAPI.getById(id);
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit) {
			getClub();
		}
	}, [edit]);

	const saveClub = async () => {
		const requiredFields = ["name"];
		const errors = checkRequiredFields(requiredFields, fields);
		setErrors(errors);

		if (!errors.length) {
			let body = Object.assign({}, fields);
			setLoading(true);
			try {
				edit ? await ClubAPI.update(id, body) : await ClubAPI.create(body);
				navigate("/settings/clubs");
				handleNotification(SUCCESSFUL_OPERATION, "success");
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveClub();
	};

	const goBack = () => {
		navigate("/settings/clubs");
	};

	const mainActions = [
		{icon: <ArrowBack />, title: "Indietro", onClick: goBack, color: "info"},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
		},
	];

	const defaultTextField = {
		values: fields,
		setValues: setFields,
		errors,
		setErrors,
	};

	const breadcrumbsElements = [{label: "Club", path: "/settings/clubs"}];

	return (
		<Page>
			{loading ? (
				<Loading />
			) : (
				<>
					<Breadcrumbs
						elements={breadcrumbsElements}
						actions={<MainActions actions={mainActions} />}
					/>
					<VLFCard
						title="Informazioni principali"
						content={
							<div className="row no-margin">
								<div className="col-sm-12 col-md-6 padding-small">
									{getTextField({
										...defaultTextField,
										fieldKey: "name",
										label: "Nome",
										required: true,
									})}
								</div>
								{/* <div className="col-sm-12 col-md-6 padding-small">
									{getTextField({
										...defaultTextField,
										fieldKey: "address",
										label: "Città",
									})}
								</div>
								<div className="col-sm-12 col-md-6 padding-small">
									{getTextField({
										...defaultTextField,
										fieldKey: "email",
										label: "Email",
									})}
								</div>
								<div className="col-sm-12 col-md-6 padding-small">
									{getTextField({
										...defaultTextField,
										fieldKey: "telephone",
										label: "Telefono",
									})}
								</div> */}
							</div>
						}
					/>
				</>
			)}
		</Page>
	);
};

export default HandleClubPage;
