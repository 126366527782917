import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFTextField from "@/components/Input/TextField";

import {StaffRoleAPI} from "@/api/staff_role";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {
	REQUIRED_FIELD,
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";

const HandleStaffRolePage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({});

	const getStaffRole = async () => {
		setLoading(true);
		try {
			const response = await StaffRoleAPI.getById(id);
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit) {
			getStaffRole();
		}
	}, [edit]);

	const getRequiredFields = () => {
		return ["name"];
	};

	const saveRole = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);
		setErrors(errors);

		if (!errors.length) {
			setLoading(true);

			let body = Object.assign({}, fields);

			try {
				const response = edit
					? await StaffRoleAPI.update(id, body)
					: await StaffRoleAPI.create(body);
				setTimeout(() => {
					navigate(`/settings/staff-roles/${response?.id || id}`);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveRole();
	};

	const getTextField = (
		fieldKey,
		label,
		required,
		values = fields,
		setValues = setFields,
	) => {
		return (
			<VLFTextField
				value={values[fieldKey] || ""}
				onChange={(e) => {
					required && setErrors([]);
					setValues({...values, [fieldKey]: e.target.value});
				}}
				label={label}
				placeholder={label}
				required={required}
				size="small"
				helperText={errors.indexOf(fieldKey) !== -1 ? REQUIRED_FIELD : ""}
				error={errors.indexOf(fieldKey) !== -1}
			/>
		);
	};

	const goBack = () => {
		if (edit) {
			navigate("/settings/staff-roles/" + id);
		} else {
			navigate("/settings/staff-roles");
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [
		{label: "Ruoli staff", path: "/settings/staff-roles"},
	];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: fields ? fields.name : "",
				path: "/settings/staff-roles/" + id,
			},
		);
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="row no-margin">
				<div className="col-sm-12 padding-small">
					<VLFCard
						title="Informazioni principali"
						content={
							<div className="row">
								<div className="col-sm-12 col-md-6">
									{getTextField("name", "Nome", true)}
								</div>
							</div>
						}
						openable={false}
					/>
				</div>
			</div>
		</Page>
	);
};

export default HandleStaffRolePage;
