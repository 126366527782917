import DatePicker, {registerLocale} from "react-datepicker";
import it from "date-fns/locale/it";

import "moment/locale/it";

import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

registerLocale("it", it);

const VLFDatePicker = (props) => {
	const {
		type = "date",
		value,
		onChange,
		label,
		placeholder,
		required = false,
		disabled = false,
		editable = true,
		selectsStart = false,
		selectsEnd = false,
		startDate = undefined,
		endDate = undefined,
		minDate = undefined,
		customDateFormat = undefined,
		helperText,
		error,
		clearable = false,
	} = props;

	return (
		<>
			{label && (
				<div className="field-label-container">
					<div className="field-label">{label}</div>
					{required && editable && <div className="field-required">*</div>}
				</div>
			)}
			{editable ? (
				<>
					<DatePicker
						placeholderText={placeholder || label}
						disabled={disabled}
						isClearable={clearable}
						selectsStart={selectsStart}
						selectsEnd={selectsEnd}
						startDate={startDate}
						endDate={endDate}
						minDate={minDate}
						selected={value}
						onChange={onChange}
						locale="it"
						// shouldCloseOnSelect={false}
						showTimeSelect={type === "datetime"}
						showWeekNumbers={type === "week"}
						showWeekPicker={type === "week"}
						showMonthYearPicker={type === "month"}
						timeIntervals={15}
						calendarStartDay={1}
						dateFormat={
							customDateFormat ||
							(type === "datetime" ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy")
						}
					/>
					{error && (
						<p
							className="MuiFormHelperText-root Mui-error"
							style={{color: "#d32f2f"}}
						>
							{helperText}
						</p>
					)}
				</>
			) : (
				<div className="field-value">{value}</div>
			)}
		</>
	);
};

export default VLFDatePicker;
