import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFDatePicker from "@/components/Input/DatePicker";
import VLFSelectNew from "@/components/Input/SelectNew";
import Button from "@mui/material/Button";

import {TrainingAPI} from "@/api/training";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import {
	REQUIRED_FIELD,
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
	fieldsOptions,
	rpeOptions,
} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";
import {useCategories, usePlayers} from "@/utils/hooks";

const HandleTrainingPage = (props) => {
	const {edit, handleNotification, admin} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const userId = useSelector((state) => state?.user?.userId);
	const clubId = useSelector((state) => state?.user?.clubId);
	const playerId = useSelector((state) => state?.user?.playerId);

	const userRole = useSelector((state) => state?.user?.roleName);
	const userName = useSelector((state) => state?.user?.userName);

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		formatted: true,
	});
	// const {players} = usePlayers({
	// 	setLoading: false,
	// 	handleNotification,
	// });

	const [blocks, setBlocks] = useState([]);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({
		category: null,
		fieldName: null,
	});

	const getTraining = async () => {
		setLoading(true);
		try {
			const response = await TrainingAPI.getById(id);
			response.startDate = response.startDate
				? moment(response.startDate)
				: null;
			response.endDate = response.endDate ? moment(response.endDate) : null;
			response.category = {
				value: response.playersCategoryId,
				label: response.playersCategory,
			};
			response.fieldName = {
				value: response.fieldName,
				label: response.fieldName,
			};
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit) {
			getTraining();
		}
	}, [edit]);

	const getRequiredFields = () => {
		const rqFields = [];
		if (admin) {
			rqFields.push("category");
			blocks.forEach((b, k) => {
				rqFields.push("startDate_" + k, "endDate_" + k, "fieldName_" + k);
			});
		} else {
			rqFields.push("startDate", "endDate", "category");
		}

		return rqFields;
	};

	const createRPE = async (trainingId) => {
		try {
			const body = {
				trainingId,
				playerId,
				rpe: fields?.rpe?.value,
			};
			await TrainingAPI.createMeasurement(body);
		} catch (error) {
			setLoading(false);
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const saveTraining = async () => {
		try {
			const errors = checkRequiredFields(getRequiredFields(), fields);
			setErrors(errors);

			if (!errors.length) {
				setLoading(true);

				if (admin) {
					const promises = [];
					blocks.forEach((b, k) => {
						let body = {
							fieldName: fields?.["fieldName_" + k].value,
							startDate: fields?.["startDate_" + k],
							endDate: fields?.["endDate_" + k],
							playersCategoryId: fields?.category.value,
							trainingConvocations: [],
							clubId,
							userId,
							user: {userId, userName},
						};

						promises.push(TrainingAPI.create(body));

						Promise.all(promises)
							.then((responses) => {
								navigate("/trainings");
								handleNotification(SUCCESSFUL_OPERATION, "success");
							})
							.catch((error) => console.log(error))
							.finally(() => {
								setLoading(false);
							});
					});
				} else {
					let body = {
						...fields,
						playersCategoryId: fields?.category.value,
						trainingConvocations: [],
						clubId,
						userId,
						user: {userId, userName},
						fieldName: fields?.fieldName?.value,
					};

					const response = edit
						? await TrainingAPI.update(id, body)
						: await TrainingAPI.create(body);

					if (userRole === "Player" && fields?.rpe) {
						await createRPE(response.id);
					}

					setTimeout(() => {
						navigate(`/trainings/${response?.id || id}`);
						setLoading(false);
						handleNotification(SUCCESSFUL_OPERATION, "success");
					}, 1000);
				}
			}
		} catch (error) {
			setLoading(false);
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveTraining();
	};

	const goBack = () => {
		if (edit) {
			navigate("/trainings/" + id);
		} else {
			navigate("/trainings");
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: admin
					? blocks.length <= 0
					: false,
		},
	];

	const breadcrumbsElements = [{label: "Allenamenti", path: "/trainings"}];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				{admin && (
					<>
						<div className="row no-margin">
							<div className="col-sm-12 padding-small">
								<VLFCard
									title="Informazioni generali"
									content={
										<div className="row">
											<div className="col-sm-12 col-md-6 col-xl-4">
												<VLFSelectNew
													options={categories}
													sorted={false}
													value={fields.category}
													onChange={(newValue) => {
														setErrors([]);
														setFields({...fields, category: newValue});
													}}
													label="Categoria"
													required
													helperText={
														errors.indexOf("category") !== -1
															? REQUIRED_FIELD
															: ""
													}
													error={errors.indexOf("category") !== -1}
												/>
											</div>
										</div>
									}
								/>
							</div>
						</div>
						<div className="mt-2 mx-2 d-flex align-items-center justify-content-end gap-1">
							<Button
								variant="contained"
								color="error"
								onClick={() => {
									setBlocks(blocks.slice(0, blocks.length - 1));
								}}
								size="small"
								disabled={blocks.length <= 0}
							>
								<div className="d-flex align-items-center">
									<DeleteIcon />
								</div>
							</Button>
							<Button
								variant="contained"
								onClick={() => {
									setBlocks([...blocks, {counter: blocks.length}]);
								}}
								size="small"
							>
								<div className="d-flex align-items-center">
									<AddIcon />
								</div>
							</Button>
						</div>
						{blocks.length ? (
							<div className="row no-margin mt-2">
								<div className="col-sm-12 padding-small">
									<VLFCard
										title="Dettagli"
										content={blocks.map((b, k) => {
											return (
												<div key={k} className="row">
													<div className="col-sm-12 col-md-6 col-xl-4">
														<VLFDatePicker
															type="datetime"
															label="Inizio"
															value={fields["startDate_" + b.counter]}
															onChange={(value) => {
																setErrors([]);
																setFields({
																	...fields,
																	["startDate_" + b.counter]: value,
																});
															}}
															required
															size="small"
														/>
													</div>
													<div className="col-sm-12 col-md-6 col-xl-4">
														<VLFDatePicker
															type="datetime"
															label="Fine"
															value={fields["endDate_" + b.counter]}
															onChange={(value) => {
																setErrors([]);
																setFields({
																	...fields,
																	["endDate_" + b.counter]: value,
																});
															}}
															required
															size="small"
														/>
													</div>
													<div className="col-sm-12 col-md-6 col-xl-4">
														<VLFSelectNew
															options={fieldsOptions}
															value={fields["fieldName_" + b.counter]}
															onChange={(newValue) => {
																setFields({
																	...fields,
																	["fieldName_" + b.counter]: newValue,
																});
															}}
															label="Campo"
															required
														/>
													</div>
												</div>
											);
										})}
										openable={false}
									/>
								</div>
							</div>
						) : null}
					</>
				)}
				{!admin && (
					<div className="row no-margin">
						<div className="col-sm-12 padding-small">
							<VLFCard
								title="Informazioni principali"
								content={
									<div className="row">
										<div className="col-sm-12 col-md-6 col-xl-3">
											<VLFDatePicker
												type="datetime"
												label="Inizio"
												value={fields.startDate}
												onChange={(value) => {
													setErrors([]);
													setFields({...fields, startDate: value});
												}}
												required
												size="small"
											/>
										</div>
										<div className="col-sm-12 col-md-6 col-xl-3">
											<VLFDatePicker
												type="datetime"
												label="Fine"
												value={fields.endDate}
												onChange={(value) => {
													setErrors([]);
													setFields({...fields, endDate: value});
												}}
												required
												size="small"
											/>
										</div>
										<div className="col-sm-12 col-md-6 col-xl-3">
											<VLFSelectNew
												options={categories}
												sorted={false}
												value={fields.category}
												onChange={(newValue) => {
													setErrors([]);
													setFields({...fields, category: newValue});
												}}
												label="Categoria"
												required
												helperText={
													errors.indexOf("category") !== -1
														? REQUIRED_FIELD
														: ""
												}
												error={errors.indexOf("category") !== -1}
											/>
										</div>
										<div className="col-sm-12 col-md-6 col-xl-3">
											<VLFSelectNew
												options={fieldsOptions}
												value={fields.fieldName}
												onChange={(newValue) => {
													setFields({...fields, fieldName: newValue});
												}}
												label="Campo"
												required
											/>
										</div>
										{userRole === "Player" && (
											<div className="col-sm-12 col-md-6">
												<VLFSelectNew
													options={rpeOptions}
													value={fields.rpe}
													onChange={(value) => {
														setFields({...fields, rpe: value});
													}}
													label="RPE"
													sorted={false}
												/>
											</div>
										)}
									</div>
								}
								openable={false}
							/>
						</div>
					</div>
				)}
			</div>
		</Page>
	);
};

export default HandleTrainingPage;
