import * as React from "react";
import {useSelector} from "react-redux";
import moment from "moment";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {dateFormat, vasOptions} from "@/utils/constants";
import {getCurrentCategory, getCurrentPlayer} from "@/utils/functions";
import {useCategories, usePlayers} from "@/utils/hooks";

import "./index.scss";

export default function VASCard(props) {
	const {element, handleEdit, handleDelete} = props;

	const userRole = useSelector((state) => state?.user?.roleName);
	const isPlayer = userRole === "Player";

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
	});
	const {players} = usePlayers({
		setLoading: false,
		handleNotification: undefined,
	});

	return (
		<div className="tqr-card-container">
			<div className="tqr-card-header">
				<div className="top-left">
					{moment(element.vasDate).format(dateFormat)}
				</div>
				<div className="top-right">
					{!isPlayer
						? element.emptyRow
							? element.playerCategoryName
							: getCurrentCategory(categories, element)
						: undefined}
				</div>
			</div>
			<div className="tqr-card-info">
				<div className="info-title">
					<div className="tqr-info">{getCurrentPlayer(players, element)}</div>
				</div>
				<div className="info-subtitle">
					{vasOptions.filter((o) => o.value === element.vasValue)?.[0]?.label ||
						"Nessun dato inserito"}
				</div>
			</div>
			{!element.emptyRow && <div className="separator"></div>}
			{!element.emptyRow && (
				<div className="tqr-card-preview">
					<div className="tqr-actions">
						<div className="tqr-action" onClick={() => handleEdit(element)}>
							<EditIcon />
						</div>
						<div
							className="tqr-action error"
							onClick={() => handleDelete(element)}
						>
							<DeleteIcon />
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
