import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import VLFModal from "@/components/Modal";

import {ProspectAPI} from "@/api/prospect";

import ArrowBack from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {SUCCESSFUL_OPERATION, ERROR_OPERATION} from "@/utils/constants";

const ScoutCoachPage = (props) => {
	const {handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const [loading, setLoading] = useState(false);
	const [coach, setCoach] = useState({});
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);

	const getCoach = async () => {
		setLoading(true);
		try {
			const response = await ProspectAPI.getByIdCoach(id);
			setCoach(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	const deleteCoach = async () => {
		setLoading(true);
		try {
			await ProspectAPI.deleteCoach(id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/scout-coaches");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	useEffect(() => {
		getCoach();
	}, []);

	const goBack = () => {
		navigate("/scout-coaches");
	};

	const mainActions = [
		{icon: <ArrowBack />, title: "Indietro", onClick: goBack, color: "info"},
		{
			icon: <EditIcon />,
			title: "Modifica",
			onClick: () => navigate("/scout-coaches/" + coach?.id + "/edit"),
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => setOpenHandleConfirm(true),
			color: "error",
		},
	];
	const mainConfiguration = [
		{
			value: coach?.firstName,
			label: "Nome",
			size: "col-6 col-lg-4",
		},
		{
			value: coach?.lastName,
			label: "Cognome",
			size: "col-6 col-lg-4",
		},
		{
			value: coach?.playersCategory?.name,
			label: "Categoria",
			size: "col-6 col-lg-4",
		},
		{
			value: coach?.prospectTeam?.name,
			label: "Squadra",
			size: "col-6 col-lg-4",
		},
		{
			value: coach?.notes,
			label: "Note",
			size: "col-6 col-lg-4",
		},
	];

	const breadcrumbsElements = [
		{label: "Allenatori", path: "/scout-coaches"},
		{label: "/"},
	];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={
					coach ? (coach.firstName || "") + " " + (coach.lastName || "") : ""
				}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-2">
										<div className="image-container generic">
											<img
												src={require("@/assets/images/manager/staff.png")}
												alt=""
											/>
										</div>
									</div>
									<div className="col-sm-12 col-md-10">
										<ViewField configuration={mainConfiguration} />
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
				<VLFModal
					open={openHandleConfirm}
					title="Eliminazione"
					content={
						<div className="mt-2">
							Sei sicuro di voler eliminare questo elemento?
						</div>
					}
					confirm={() => {
						deleteCoach();
					}}
					close={() => setOpenHandleConfirm(false)}
					confirmLabel="Conferma"
				/>
			</div>
		</Page>
	);
};

export default ScoutCoachPage;
