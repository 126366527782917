import React from "react";

import "./index.scss";

function ViewField(props) {
	const {configuration} = props;

	return (
		<div className="row align-items-center h-100">
			{configuration.map((config, key) => {
				return (
					<div key={key} className={config.size || "col-sm-6"}>
						<div className="view-field-container">
							<div className="label">{config.label}</div>
							<div className="value">{config.value || "---"}</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default ViewField;
