import React from "react";
import moment from "moment";

import VLFSelectNew from "@/components/Input/SelectNew";
import VLFTextField from "@/components/Input/TextField";
import VLFDatePicker from "@/components/Input/DatePicker";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

import "./index.scss";

export default function Filters(props) {
	const {data, opened, setOpened, forceOpened} = props;

	const getFilterContent = (f) => {
		switch (f.type) {
			case "select":
				return (
					<VLFSelectNew
						options={f.options}
						value={f.value}
						isClearable={f.clearable}
						onChange={f.setValue}
						label={f.label}
						sorted={f.sorted}
						sortOrder={f.sortOrder}
					/>
				);
			case "date":
				return (
					<VLFDatePicker
						label={f.label}
						value={f.value}
						clearable={f.clearable}
						onChange={f.setValue}
						size="small"
					/>
				);
			default:
				return (
					<VLFTextField
						name={f.key}
						value={f.value}
						onChange={(event) => {
							f.setValue(event.target.value);
						}}
						label={f.label}
						size="small"
					/>
				);
		}
	};

	const getValue = (d) => {
		switch (d.type) {
			case "select":
				return d.value.label;
			case "date":
				return moment(d.value).format("DD/MM/YYYY");
			default:
				return d.value;
		}
	};

	let filtersApplied = false;
	data?.forEach((d) => {
		if (d.value) {
			filtersApplied = true;
		}
	});

	return (
		<>
			<div className={"filters-row-container row no-margin align-items-center"}>
				{!opened && (
					<div className="col-8 col-md-9 col-xl-11 padding-small">
						<div className={"d-flex align-items-center justify-content-end"}>
							<div className="d-flex align-items-center gap-2">
								{data
									.filter((d) => d.value && d.type !== "main")
									.map((d, k) => {
										return (
											<div key={k} className="applied-filter">
												<span className="filter-label">{d.label + ": "}</span>
												<span>{getValue(d)}</span>
											</div>
										);
									})}
							</div>
						</div>
					</div>
				)}
				<div
					className={
						opened ? "col-12 no-padding" : "col-4 col-md-3 col-xl-1 no-padding"
					}
				>
					<div className={"filters-container" + (opened ? " opened" : "")}>
						{!opened && (
							<div
								className="closed d-flex align-items-center justify-content-between w-100 h-100 px-2 py-1 clickable"
								onClick={() => setOpened(!opened)}
							>
								<div className="filters-title-container">
									<span className="filters-title">Filtri</span>
									{filtersApplied && <div className="round" />}
								</div>
								<div className="filters-icon">
									<FilterAltIcon />
								</div>
							</div>
						)}
						{opened && (
							<>
								<div className="row w-100 py-2">
									{data
										?.filter((f) => !f.hidden)
										.map((f, k) => {
											return (
												<div
													className={
														f.customClassName || "col-sm-12 col-md-6 col-lg-3"
													}
													key={k}
												>
													{getFilterContent(f)}
												</div>
											);
										})}
								</div>
								{!forceOpened && (
									<div className="filters-close">
										<div
											className={"filters-icon clickable"}
											onClick={() => setOpened(!opened)}
										>
											<CloseFullscreenIcon />
										</div>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
