import React, {useState} from "react";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

function VLFTextField(props) {
	const {
		className,
		name,
		value,
		onChange,
		type = "text",
		size = "normal",
		margin = "normal",
		label,
		placeholder,
		variant,
		required = false,
		editable = true,
		onlyLabel = false,
		disabled = false,
		readOnly = false,
		helperText,
		error = false,
		fullWidth = true,
		multiline = false,
		start = undefined,
		end = undefined,
		minRows = 1,
		maxRows = 1,
	} = props;

	const [showPassword, setShowPassword] = useState(false);

	let inputType = type || undefined;
	if (type === "phone") {
		inputType = "text";
	}

	const [managedInputType, setManagedInputType] = useState(inputType);

	const handleInput = (event) => {
		let value = event.target.value;

		if (type === "phone") {
			value = value.replace(/[^0-9+]/g, "");

			if (value.startsWith("+")) {
				value = "+" + value.slice(1).replace(/\+/g, "");
			} else {
				value = value.replace(/\+/g, "");
			}
		}

		event.target.value = value;
		onChange(event);
	};

	return (
		<>
			<div className="field-label-container">
				<div className="field-label">{label}</div>
				{required && !onlyLabel && <div className="field-required">*</div>}
			</div>
			{editable ? (
				<TextField
					className={className}
					name={name}
					value={value}
					onChange={handleInput}
					type={managedInputType}
					size={size}
					margin={margin}
					InputLabelProps={{shrink: true}}
					placeholder={placeholder || label}
					variant={variant}
					required={required}
					disabled={disabled}
					helperText={helperText}
					error={error}
					fullWidth={fullWidth}
					sx={{
						"&:has([readonly]) ": {
							"& .MuiInputLabel-outlined": {
								color: "#cecece",
							},
							"& .MuiOutlinedInput-notchedOutline": {
								borderColor: "#cecece",
								borderWidth: 1,
							},
						},
					}}
					InputProps={{
						readOnly,
						startAdornment: start ? (
							<InputAdornment position="start">{start}</InputAdornment>
						) : undefined,
						endAdornment: end ? (
							<InputAdornment position="end">{end}</InputAdornment>
						) : type === "password" ? (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => {
										setShowPassword(!showPassword);
										setManagedInputType(showPassword ? "password" : "text");
									}}
									edge="end"
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						) : undefined,
					}}
					multiline={multiline}
					minRows={multiline ? minRows : undefined}
					maxRows={multiline ? maxRows : undefined}
				/>
			) : (
				<div className={"field-value"}>{value}</div>
			)}
		</>
	);
}

export default VLFTextField;
