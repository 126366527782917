export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const USER_SUCCESS = "USER_SUCCESS";

export const SET_SELECTED_MENU_ITEM = "SET_SELECTED_MENU_ITEM";
export const SET_PLAYER_TAB = "SET_PLAYER_TAB";

export const loginSuccess = (token) => ({
	type: LOGIN_SUCCESS,
	payload: token,
});

export const logout = () => ({
	type: LOGOUT,
});

export const userSuccess = (payload) => ({
	type: USER_SUCCESS,
	payload,
});

export const setSelectedMenuItem = (selectedMenuItem) => ({
	type: SET_SELECTED_MENU_ITEM,
	payload: selectedMenuItem,
});

export const setPlayerTab = (playerTab) => ({
	type: SET_PLAYER_TAB,
	payload: playerTab,
});
