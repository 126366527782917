import * as React from "react";

import "./index.scss";

export default function ScoutClubCard(props) {
	const {element} = props;

	return (
		<div className="scout-club-card-container">
			<div className="scout-club-card-header">
				<div className="top-left" />
				<div className="top-right">{element.region}</div>
			</div>
			<div className="scout-club-card-info">
				<div className="d-flex align-items-center">
					<div className="info-image">
						<img src={require("@/assets/images/manager/club.png")} alt="" />
					</div>
					<div className="info-title">{element.name}</div>
				</div>
			</div>
		</div>
	);
}
