import * as React from "react";
import moment from "moment";

import {dateFormat} from "@/utils/constants";

import "./index.scss";

export default function TQRStatCard(props) {
	const {element} = props;

	const tqr =
		element.tqrValue?.indexOf("-") !== -1
			? element.tqrValue.split(" - ")?.[0]
			: element.tqrValue;
	const currentValue = +parseFloat((tqr * 100) / 20).toFixed(0);

	let tlStatusClassName = "";
	if (currentValue <= 64) {
		tlStatusClassName = "strong";
	} else if (currentValue > 64 && currentValue <= 80) {
		tlStatusClassName = "medium";
	} else if (currentValue > 80) {
		tlStatusClassName = "light";
	}

	return (
		<div className="tqr-stat-card-container">
			<div className="tqr-stat-card-header">
				<div className="top-left">
					{moment(element.tqrDate).format(dateFormat)}
				</div>
				<div className="top-right">{"TQR: " + element.tqrValue}</div>
			</div>
			<div className="separator"></div>
			<div className="tqr-stat-card-info">
				<div className="info-title">
					<div className={"tqr-stat-info " + tlStatusClassName}>
						{currentValue + "%"}
					</div>
					<div className="tqr-stat-sub-info"></div>
				</div>
			</div>
		</div>
	);
}
