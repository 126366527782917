import React, {useEffect} from "react";
import "./index.scss";

const Rating = (props) => {
	const {
		selectedRating,
		setSelectedRating,
		label,
		required,
		min = 1,
		max = 10,
		onMaxChange,
	} = props;

	useEffect(() => {
		if (onMaxChange) {
			onMaxChange(max);
		}
	}, [max, onMaxChange]);

	const getColor = (rating) => {
		const hue = (rating - min) * (120 / (max - min));
		return `hsl(${hue}, 100%, 50%)`;
	};

	const handleRatingClick = (ratingValue) => {
		if (selectedRating === ratingValue) {
			setSelectedRating(null);
		} else {
			setSelectedRating(ratingValue);
		}
	};

	return (
		<div className="rating-container">
			{label && (
				<div className="field-label-container">
					<div className="field-label">{label}</div>
					{required && <div className="field-required">*</div>}
				</div>
			)}
			<div className="rating-options-container my-1">
				{[...Array(max - min + 1)].map((_, index) => {
					const ratingValue = min + index;
					return (
						<div
							key={ratingValue}
							className={`rating-box ${selectedRating >= ratingValue ? "selected" : ""}`}
							style={{
								backgroundColor:
									selectedRating >= ratingValue
										? getColor(ratingValue)
										: "#ccc",
							}}
							onClick={() => handleRatingClick(ratingValue)}
						>
							{ratingValue}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Rating;
