import * as React from "react";
import moment from "moment";

import Button from "@mui/material/Button";

import {dateFormat} from "@/utils/constants";

import "./index.scss";

export default function MedicalCard(props) {
	const {element, actions} = props;

	return (
		<div className="medical-card-container">
			<div className="d-flex flex-column">
				<div className="medical-card-header">
					<div className="top-left">
						{moment(element.startDate).format(dateFormat)}
					</div>
					<div className="top-right">
						{element.end ? moment(element.end).format(dateFormat) : "---"}
					</div>
				</div>
			</div>
			<div className="medical-card-info">
				<div className="info-title">{element.diagnosis}</div>
			</div>
			<div className="medical-card-subinfo">
				<div className="type-info">{"Esame: " + (element.exam || "---")}</div>
				<div className="type-info">
					{"Terapia: " + (element.therapy || "---")}
				</div>
			</div>
			<>
				<div className="separator"></div>
				{/* <div className="medical-card-preview">
					<div className="match-type">
						<div className="type-info">
							{"Esame: " + (element.exam || "---")}
						</div>
					</div>
					<div className="match-stats">
						<div className="type-info">
							{"Terapia: " + (element.therapy || "---")}
						</div>
					</div>
				</div> */}
				<div className="actions justify-content-center my-1">
					{actions.map((action, key) => {
						return (
							<div key={key} className="action">
								<Button
									variant={action.variant || "contained"}
									onClick={() => action.onClick(element)}
									color={action.color || "blue"}
									size="small"
								>
									<div className="action-content d-flex align-items-center">
										<div>{action.icon && action.icon}</div>
									</div>
								</Button>
							</div>
						);
					})}
				</div>
			</>
		</div>
	);
}
