import {useEffect, useState} from "react";

import {CategoryAPI} from "@/api/category";
import {ClubAPI} from "@/api/club";
import {EvaluationAPI} from "@/api/evaluation";
import {MatchAPI} from "@/api/match";
import {PlayerAPI} from "@/api/player";
import {ScoutReportAPI} from "@/api/scout_report";
import {StaffAPI} from "@/api/staff";
import {StaffRoleAPI} from "@/api/staff_role";
import {TrainingAPI} from "@/api/training";
import {UserAPI} from "@/api/user";
import {UserRoleAPI} from "@/api/user_role";

import {ERROR_OPERATION} from "@/utils/constants";

export const useCategories = ({
	setLoading,
	handleNotification,
	formatted = false,
	filtered = false,
	onlyYouth = false,
	ordered = "descend",
}) => {
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		getCategories();
	}, []);

	const getCategories = async () => {
		setLoading && setLoading(true);
		try {
			let response = await CategoryAPI.list();

			if (ordered === "descend") {
				response = response.sort((a, b) => {
					if (a.name < b.name) {
						return 1;
					} else if (a.name > b.name) {
						return -1;
					} else {
						return 0;
					}
				});
			}

			if (filtered) {
				response = response.filter(
					(c) => parseInt(c.name.split(" ")?.[1]) > 13,
				);
			}

			if (onlyYouth) {
				response = response.filter((c) => parseInt(c.name.split(" ")?.[1]) > 0);
			}

			if (formatted) {
				response = response.map((c) => {
					return {
						value: c.id,
						label: c.name,
					};
				});
			}

			setCategories(response);
		} catch (error) {
			handleNotification && handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading && setLoading(false);
		}
	};

	return {categories};
};

export const useClubs = ({setLoading, handleNotification}) => {
	const [clubs, setClubs] = useState([]);

	useEffect(() => {
		getClubs();
	}, []);

	const getClubs = async () => {
		setLoading && setLoading(true);
		try {
			const response = await ClubAPI.list();
			setClubs(response);
		} catch (error) {
			handleNotification && handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading && setLoading(false);
		}
	};

	return {clubs};
};

export const useEvaluations = ({setLoading, handleNotification}) => {
	const [evaluations, setEvaluations] = useState([]);

	useEffect(() => {
		getEvaluations();
	}, []);

	const getEvaluations = async () => {
		setLoading(true);
		try {
			const response = await EvaluationAPI.list();
			setEvaluations(response);
		} catch (error) {
			handleNotification && handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	return {evaluations};
};

export const useMatches = ({
	setLoading,
	handleNotification,
	prospect = false,
}) => {
	const [matches, setMatches] = useState([]);

	useEffect(() => {
		if (!prospect) {
			getMatches();
		}
	}, [prospect]);

	const getMatches = async () => {
		setLoading && setLoading(true);
		try {
			const response = await MatchAPI.list();
			setMatches(response);
		} catch (error) {
			handleNotification && handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading && setLoading(false);
		}
	};

	return {matches};
};

export const usePlayers = ({
	setLoading,
	handleNotification,
	filtered = true,
	onlyInternal = false,
	prospect = false,
}) => {
	const [players, setPlayers] = useState([]);

	useEffect(() => {
		if (!prospect) {
			getPlayers();
		}
	}, [prospect]);

	const getPlayers = async () => {
		setLoading && setLoading(true);
		try {
			let response = await PlayerAPI.list();

			if (filtered) {
				response = response.filter((p) => p.status !== "Esterno");
			}

			if (onlyInternal) {
				response = response.filter((p) => p.status === "Interno");
			}
			setPlayers(response);
		} catch (error) {
			handleNotification && handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading && setLoading(false);
		}
	};

	return {players};
};

export const useTrainings = ({
	setLoading,
	handleNotification,
	filters = {},
}) => {
	const [trainings, setTrainings] = useState([]);

	useEffect(() => {
		getTrainings();
	}, []);

	const getTrainings = async () => {
		setLoading && setLoading(true);
		try {
			const response = await TrainingAPI.list(filters);
			setTrainings(response);
		} catch (error) {
			handleNotification && handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading && setLoading(false);
		}
	};

	return {trainings};
};

export const useStaffs = ({setLoading, handleNotification}) => {
	const [staffs, setStaffs] = useState([]);

	useEffect(() => {
		getStaffs();
	}, []);

	const getStaffs = async () => {
		setLoading && setLoading(true);
		try {
			const response = await StaffAPI.list();
			setStaffs(response);
		} catch (error) {
			handleNotification && handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading && setLoading(false);
		}
	};

	return {staffs};
};

export const useReports = ({setLoading, handleNotification}) => {
	const [reports, setReports] = useState([]);

	useEffect(() => {
		getReports();
	}, []);

	const getReports = async () => {
		setLoading && setLoading(true);
		try {
			const response = await ScoutReportAPI.list();
			setReports(response);
		} catch (error) {
			handleNotification && handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading && setLoading(false);
		}
	};

	return {reports};
};

export const useStaffRoles = ({setLoading, handleNotification}) => {
	const [staffRoles, setStaffRoles] = useState([]);

	useEffect(() => {
		getRoles();
	}, []);

	const getRoles = async () => {
		setLoading && setLoading(true);
		try {
			const response = await StaffRoleAPI.list();
			setStaffRoles(response);
		} catch (error) {
			handleNotification && handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading && setLoading(false);
		}
	};

	return {staffRoles};
};

export const useUserRoles = ({setLoading, handleNotification}) => {
	const [userRoles, setUserRoles] = useState([]);

	useEffect(() => {
		getRoles();
	}, []);

	const getRoles = async () => {
		setLoading && setLoading(true);
		try {
			const response = await UserRoleAPI.list();
			setUserRoles(response);
		} catch (error) {
			handleNotification && handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading && setLoading(false);
		}
	};

	return {userRoles};
};

export const useUsers = ({setLoading, handleNotification}) => {
	const [users, setUsers] = useState([]);

	useEffect(() => {
		getUsers();
	}, []);

	const getUsers = async () => {
		setLoading && setLoading(true);
		try {
			const response = await UserAPI.list();
			setUsers(response);
		} catch (error) {
			handleNotification && handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading && setLoading(false);
		}
	};

	return {users};
};
