import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import VLFCard from "@/components/Card";
import Button from "@mui/material/Button";
import HandleNoteModal from "@/pages/Player/Modals/handleNote";

import {ProspectAPI} from "@/api/prospect";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";

import {dateFormat} from "@/utils/constants";
import {usePlayers} from "@/utils/hooks";

import "./index.scss";

export default function NotesSection(props) {
	const {
		type,
		external = false,
		fullHeight = false,
		prospect = false,
		notes,
		handleConfirm,
		handleDelete,
	} = props;

	const user = useSelector((state) => state?.user);
	const navigate = useNavigate();

	const {players} = usePlayers({
		setLoading: false,
		handleNotification: undefined,
		prospect,
	});

	const [prospectPlayers, setProspectPlayers] = useState([]);
	const getProspectPlayers = async () => {
		try {
			const response = await ProspectAPI.playersList();
			setProspectPlayers(response);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		if (type !== "player") {
			getProspectPlayers();
		}
	}, [type]);

	const [openNote, setOpenNote] = useState(false);
	const [initialData, setInitialData] = useState(false);

	const handleClose = () => {
		setOpenNote(false);
		setInitialData();
	};

	let mainTitle = "Note";
	if (type === "player_match") {
		mainTitle = "Valutazioni partite";
	}

	return (
		<div className="row no-margin mt-3">
			<div className="col-sm-12 no-padding">
				<div className="notes-section-container">
					{user?.roleName === "Admin" ? (
						<>
							<div className="d-flex align-items-center justify-content-between mt-2">
								<h6 className="notes-title">{mainTitle}</h6>
								{handleConfirm && (
									<div
										className="d-flex align-items-center clickable"
										onClick={() => setOpenNote(true)}
									>
										<ControlPointIcon style={{width: "18px", height: "18px"}} />
									</div>
								)}
							</div>
							<div
								className={
									"row no-margin notes-section" +
									(fullHeight ? " full-page" : "")
								}
							>
								{notes?.map((n, k) => {
									const isExternal = n.prospectPlayerId;

									const currentPlayer = !isExternal
										? players?.filter((p) => p.id === n.playerId)?.[0]
										: prospectPlayers?.filter(
												(p) => p.id === n.prospectPlayerId,
											)?.[0];
									const currentMatch = n.matchData;

									let noteTitle = "";
									let noteStyle = "mt-1 col-12 col-lg-6 col-xl-4 padding-small";
									switch (type) {
										case "player":
											noteTitle = n.title;
											break;
										case "player_match":
											noteStyle = "mt-1 col-12 padding-small";
											noteTitle = currentMatch ? (
												<div className="d-flex align-items-center justify-content-between">
													<div>
														{moment(n.matchData?.startDate).format(dateFormat) +
															" " +
															currentMatch?.homeTeam +
															" - " +
															currentMatch?.awayTeam}
													</div>
													<Button
														variant="outlined"
														onClick={() =>
															navigate("/matches/" + currentMatch.id)
														}
														size="small"
													>
														<div className="d-flex align-items-center">
															<SearchIcon />
														</div>
													</Button>
												</div>
											) : (
												"---"
											);
											break;
										case "match":
											noteStyle = "mt-1 col-12 col-lg-6 padding-small";
											noteTitle = currentPlayer
												? currentPlayer?.firstName +
													" " +
													currentPlayer?.lastName +
													(isExternal && currentPlayer?.dateOfBirth
														? " - " +
															moment(currentPlayer.dateOfBirth).format(
																dateFormat,
															)
														: "")
												: "---";
											break;
										default:
											break;
									}

									return (
										<div className={noteStyle} key={k}>
											<VLFCard
												className="note-card-box"
												title={noteTitle}
												content={
													<div className="note-content-container">
														{(handleConfirm || handleDelete) && (
															<div className="d-flex align-items-center justify-content-end gap-1 notes-actions">
																<div
																	className="d-flex align-items-center clickable"
																	onClick={() => {
																		setInitialData(Object.assign({}, n));
																		setOpenNote(true);
																	}}
																>
																	<EditIcon
																		style={{width: "18px", height: "18px"}}
																	/>
																</div>
																<div
																	className="d-flex align-items-center clickable"
																	onClick={() => handleDelete(n.id)}
																>
																	<DeleteIcon
																		style={{width: "18px", height: "18px"}}
																	/>
																</div>
															</div>
														)}
														<div className="note-box-content">{n.note}</div>
														{(type === "player_match" || type === "match") && (
															<div className="note-box-author">
																Relatore: {n.title || "---"}
															</div>
														)}
													</div>
												}
												openable={false}
											/>
										</div>
									);
								})}
								{!notes?.length && (
									<div className="d-flex align-items-center justify-content-center w-100">
										<div className="notes-no-data">Nessuna nota inserita</div>
									</div>
								)}
							</div>
						</>
					) : (
						""
					)}
					{openNote ? (
						<HandleNoteModal
							open={openNote}
							confirm={async (data, id) => {
								await handleConfirm(data, id);
								handleClose();
							}}
							close={handleClose}
							initialData={initialData}
							type={type}
							external={external}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
}
