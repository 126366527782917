import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import VLFModal from "@/components/Modal";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {TargetAPI} from "@/api/target";

import {
	dateFormat,
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
} from "@/utils/constants";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

function TargetPage(props) {
	const {handleNotification} = props;

	const {id} = useParams();
	const navigate = useNavigate();

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: null,
	});

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [targetData, setTargetData] = useState({});

	const getTarget = async () => {
		setLoading(true);
		try {
			const response = await TargetAPI.getById(id);
			setTargetData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (categories?.length) {
			getTarget();
		}
	}, [categories]);

	const deleteTarget = async () => {
		setLoading(true);
		try {
			await TargetAPI.delete(targetData?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/manager/targets");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const mainConfiguration = [
		{
			value: targetData?.targetDate
				? moment(targetData?.targetDate).format(dateFormat)
				: null,
			label: "Data",
			size: "col-6 col-lg-4",
		},
		{
			value: targetData?.playersCategoryId
				? categories?.filter((c) => c.id === targetData?.playersCategoryId)?.[0]
						?.name
				: "---",
			label: "Categoria",
			size: "col-6 col-lg-4",
		},
		{
			value:
				targetData?.targetDeadline &&
				moment(targetData?.targetDeadline).year() > 2000
					? moment(targetData?.targetDeadline).format(dateFormat)
					: null,
			label: "Scadenza",
			size: "col-6 col-lg-4",
		},
		{
			value: targetData?.description,
			label: "Descrizione",
			size: "col-6 col-lg-4",
		},
		{
			value: targetData?.dateReached
				? moment(targetData?.dateReached).format(dateFormat)
				: null,
			label: "Data raggiungimento",
			size: "col-6 col-lg-4",
		},
		{
			value: (
				<div className={"cell" + (targetData?.reached ? " on" : " off")}></div>
			),
			label: "Raggiunto",
			size: "col-6 col-lg-4",
		},
	];

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate("/manager/targets"),
			color: "info",
		},
		{
			icon: <EditIcon />,
			title: "Modifica",
			onClick: () => navigate("/manager/targets/" + targetData?.id + "/edit"),
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => setOpenHandleConfirm(true),
			color: "error",
		},
	];

	const breadcrumbsElements = [
		{label: "Obiettivi", path: "/manager/targets"},
		{label: "/"},
	];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={
					categories?.filter((c) => c.id === targetData?.playersCategoryId)?.[0]
						?.name
				}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-2">
										<div className="image-container generic">
											<img
												src={require("@/assets/images/manager/target.png")}
												alt=""
											/>
										</div>
									</div>
									<div className="col-sm-12 col-md-10">
										<ViewField configuration={mainConfiguration} />
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => deleteTarget()}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default TargetPage;
