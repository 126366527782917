import React, {useEffect, useState} from "react";

import VLFModal from "@/components/Modal";
import VLFTextField from "@/components/Input/TextField";
import VLFDatePicker from "@/components/Input/DatePicker";

import {REQUIRED_FIELD, dateFormat} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";

const requiredFields = ["date"];

function HandlePhysicInfoModal(props) {
	const {open, initialData, confirm, close} = props;

	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (initialData) {
			// initialData.date = moment(initialData.date);
			setFormData(initialData);
		}
	}, [initialData]);

	useEffect(() => {
		if (!open) {
			setFormData({});
		}
	}, [open]);

	return (
		<VLFModal
			maxWidth="md"
			open={open}
			title={initialData ? "Modifica informazione" : "Aggiungi informazione"}
			close={close}
			confirm={() => {
				const errors = checkRequiredFields(requiredFields, formData);
				setErrors(errors);

				if (!errors.length) {
					confirm(formData, initialData?.id);
				}
			}}
			content={
				<div className="row">
					<div className="col-xs-12 col-sm-6">
						<VLFDatePicker
							label="Data"
							size="small"
							value={formData.date}
							onChange={(value) => {
								setErrors([]);
								setFormData({...formData, date: value});
							}}
							format={dateFormat}
							required
							helperText={errors.indexOf("date") !== -1 ? REQUIRED_FIELD : ""}
							error={errors.indexOf("date") !== -1}
						/>
					</div>
					<div className="col-xs-12 col-sm-6">
						<VLFTextField
							name="weight"
							value={formData.weight || ""}
							onChange={(event) => {
								setErrors([]);
								setFormData({...formData, weight: event.target.value});
							}}
							label="Peso"
							placeholder="Peso"
							size="small"
							type="number"
						/>
					</div>
				</div>
			}
		/>
	);
}

export default HandlePhysicInfoModal;
